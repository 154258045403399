import { Injectable, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { formatCurrency } from '@angular/common';

import { AppService } from '../../app.service';
import { DialogsService } from '../../_core/dialogs.service';
import { TODataService } from './to.data.service';
import { TOThrottlingService } from './to.throttling.service';
import { AddressesService, Address } from '../../_core/addresses.service';
import { LocationService } from '../../_core/location.service';
import { SpecialMessagesService } from '../../_core/special-messages.service';
import { TabitpayService } from '../../order/tabit-pay/tabit-pay.service';

import { ToGroupSelectDialogComponent } from '../dialogs/to-group-select-dialog/to-group-select-dialog.component';
import { ToImageDialogComponent } from '../dialogs/to-image-dialog/to-image-dialog.component';
import { ToTableDialogComponent } from '../dialogs/to-table-dialog/to-table-dialog.component';
import { ToRoomDialogComponent } from '../dialogs/to-room-dialog/to-room-dialog.component';

import moment from 'moment';
import { get, cloneDeep, assignIn, extend, find, round, each, map, reverse, merge, filter, isEmpty } from 'lodash-es';

declare const $: any;

@Injectable({
	providedIn: 'root',
})
export class TOUtilsService {

    public tableNumber: any;
    public loadedResources: any = {}
    public linkOrigin: string;
    public availableManualBenefits: number = 0;
    public activeManualBenefits: number = 0;
    public benefitEdited: boolean;

    constructor(
        public dialog: MatDialog,
        public appService: AppService,
        public sharedDialogsService: DialogsService,
        public dataService: TODataService,
        public throttlingService: TOThrottlingService,
        private addressesService: AddressesService,
        private locationService: LocationService,
        private specialMessagesService: SpecialMessagesService,
        public tabitPayService: TabitpayService,
        private ngZone: NgZone,
    ) {}

    // --------------------------------------------------------------------------------------------------------------->
    // PREPARE SITE
    // --------------------------------------------------------------------------------------------------------------->

    getParsedMoney(val, digitsInfo?) {
        return !isNaN(val) ? formatCurrency(val, 'he', this.appService.currency, digitsInfo) : '';
    }

    generateCaptions(siteConfig) {
        let that = this;
        let $storage = this.dataService.$storage;
        let base = siteConfig.settings || {};
        let arr = [
            ['eatinCaption', 'eatin'],
            ['takeawayCaption', 'takeaway'],
            ['deliveryCaption', 'delivery'],

            ['selectTableCaption', 'SELECT_TABLE'],
            ['tableNumberCaption', 'TABLE_NO'],
            ['orderDelayCaption', '_DELAYED.service_caption'],
            ['extraOffersCaption', 'ADDITIONAL_OFFERS'],
            ['extraOffersDesc', 'SELECT_ADDITIONAL_OFFERS'],
            
            ['cartCaption', 'MY_ORDER'],
            ['proceedToCheckout', 'TO_PAY'],

            ['loyaltyHeaderCaption', null],
            ['loyaltyHeaderSubCaption', null],
            ['loyaltyFieldCaption', null],
            ['benefitsCommentCaption', null],
            ['loyaltyClubsCaption', null]
        ]

        let captions = {}

        each(arr, o => {
            var prop = o[0];
            let val = base[prop];
            if (val && val.length) val = that.getSiteTranslation(siteConfig, prop, val);
            else val = o[1] ? this.translate(o[1]) : null;
            captions[prop] = val;
        });
        $storage.$captions = captions;
    }

    translateServices() {
        let $storage = this.dataService.$storage;
        if ($storage?.organization?._services) {
            $storage.organization._services.forEach(_service => {
                _service.text = this.getServiceCaption(_service.id);
            });
        }
    }

    getServiceCaption(serviceId) {
        let $storage = this.dataService.$storage;
        switch (serviceId) {

            case "eatin":
                return this.translate($storage.$captions.eatinCaption);
            case "takeaway":
                return this.translate($storage.$captions.takeawayCaption);
            case "delivery":
                return this.translate($storage.$captions.deliveryCaption);
            case "delay":
                return this.translate($storage.$captions.orderDelayCaption);
            default:
                return this.translate(serviceId);
        }
    }

    setSitesTitle() {
        let $storage = this.dataService.$storage;
        let title = $storage?.orderMode == 'VIEW_MENU' ? 'SITES_DESKTOP_MENU_HEADER' : 'SITES_DESKTOP_HEADER';
        return title;
    }

    prepareDelivery(): Observable<Address[]> {
        let addressesSearch = this.addressesService.newAddressesSearch(['street_address']);
        let addressesResult = [];
        return new Observable(observer => {

            // Currently getting addresses one time only (Didn't want to mess with too much code here) - Nati
            // This is a SYNCHRONIC operation, despite the callback style look
            this.addressesService.addresses.subscribe(addresses => {
                if (addresses?.length) addressesResult = addressesResult.concat(addresses);
            }).unsubscribe();

            let locationLabeled = this.locationService.getChosenLocation();

            if (!locationLabeled.actual) {
                this.dataService.$storage.userAddresses = addressesResult;
                // reverse(this.dataService.$storage.userAddresses);
                observer.next();
                return observer.complete();
            }

            addressesSearch.getAddressForLocation(locationLabeled.location).subscribe(addresses => {
                if (addresses?.length) addressesResult.push(addresses[0]);
                this.dataService.$storage.userAddresses = addressesResult;
                reverse(this.dataService.$storage.userAddresses);
                observer.next();
                observer.complete();
            }, err => {
                this.dataService.$storage.userAddresses = addressesResult;
                reverse(this.dataService.$storage.userAddresses);
                console.error('Error in prepareDelivery:', err);
                observer.next();
                observer.complete();
            });

        });
    }

    setOrderMethod(mode, delay) {
        let $storage = this.dataService.$storage;
        if (!this.selectOrderMethod_check(mode, delay)) return false;

        const sites = [];
        each($storage.organization.branches, (site) => {
            if (find(site._services, { id: mode })) sites.push(site);
        });

        $storage.relevantSites = sites;
        $storage.orderMode = mode;
        $storage.forceDelay = delay;

        return true;
    }

    async selectBranch(branch, serverTime) {
        const $storage = this.dataService.$storage;
        // We must do it to preserve the full flow of the order
        await this.setRosConfigForChainSelectedSite(branch);

        if ($storage.orderMode == 'VIEW_MENU') {
            $storage.order = {
                branch: cloneDeep(branch),
                workSlot: null,
                readOnly: true
            }
            return Promise.resolve();
        }

        // After being prompted from Tabit Pay we set initial values
        if ($storage.tpOrder) {
            const site = $storage.organization.branches[0];
            const config = get(site, 'tabitpay', {});

            $storage.order = {
                relevantSites: site,
                branch: cloneDeep(branch),
                workSlot: null,
                forceDelay: false,
                orderMode: config.addItems_addItemMenu || 'takeaway'
            };
            return Promise.resolve();
        }

        if (!this.selectOrderMethod_check($storage.orderMode, $storage.forceDelay, branch)) return Promise.reject();
        return this.checkBranchHours(branch, Date.now(), serverTime).then((res: any) => {
            if (res.readOnly) {
                //start view only mode ----------------------------->
                $storage.order = {
                    branch: cloneDeep(branch),
                    readOnly: true
                }
            } else {
                return this.getTableNumber($storage.orderMode, branch).then(tableNumber => {
                    this.tableNumber = null;
                    $storage.order = {
                        branch: cloneDeep(branch),
                        workSlot: res.slot,
                        readOnly: false,
                        isPreOrder: res.isPreOrder,
                        tableNumber
                    }
                })

            }
        });
    }

    getTableNumber(mode, site) {
        if (mode !== 'eatin') return Promise.resolve(null);
        if (this.tableNumber) return Promise.resolve(this.tableNumber);
        if (!get(site, 'settings.requiereTableNumberEatin')) return Promise.resolve(null);
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(ToTableDialogComponent, {
                    width: "360px",
                    disableClose: true,
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result) resolve(result);
                    else reject();
                });
            });
        });
    }

    selectOrderMethod_check(mode, delay, _org?): boolean {
        const $storage = this.dataService.$storage;

        if (mode == 'VIEW_MENU' || $storage.tpOrder) return true;

        let _branch;
		if (!_org) {
			_org = $storage.organization;
			_branch = get(_org, 'branches.0');
		} else {
			_branch = _org;
		}

        let enabled, service = find(_org._services, {id: mode});
		if (delay) {
			enabled = service?.canDelay;
		} else {
			enabled = service?.enabled;
		}

        if (!enabled) {
            let phone = '911';
            if (_branch) {
                phone = _branch.phone;
            }
            this.appService.stopBlock({ class: 'dark-block' });
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                this.appService.mainMessage({
                    dialogType: 'error',
                    dialogText: this.translate('MESSAGES.SERVICE_UNAVAILABLE', { st: this.getServiceCaption (mode), phone }),
                    hideSecondaryButton: true,
                }).finally(() => {
                    if (window['cordova']) {
                        if (this.appService.previousUrl.includes('/order')) this.appService.redirect(['/order']);
                    };
                });
            });
            return false;
        };
        return true;
    }

    getCustomer(args: any, includeOrderInstructions: any) {
        let contact = get(this.appService, 'user.loyaltyCustomer', get(this.appService, 'user', {}));
        let customer: any;
        let customerLocalObj = this.dataService.$storage?.order.customer;
        const forceDinerNum = this.dataService.$storage?.rosConfig?.config?.settings?.forceDinersNum;

        if (contact?.Mobile) { // Not anonymous
            let phoneNumber = contact?.Mobile?.trim();
            // Fit phone for international US/IL use - replace an actual international phone solution on the UI
            if (phoneNumber && (phoneNumber.length > 10) && (phoneNumber[0] !== '+')) {
                if ((phoneNumber[0] === '1') || (phoneNumber.substr(0, 3) === '972'))
                    phoneNumber = '+' + phoneNumber;
            }
            customer = {
                name: contact.FirstName + ' ' + contact?.LastName,
                firstName: contact?.FirstName,
                lastName: contact?.LastName,
                phone: phoneNumber,
                email: contact?.Email,
                approvedTerms: true, // TAB-13848 - as asked, the approvedTerms would not appear if signed in
            }
        } else {
            //fix Opening the Login to Tabit dialog removes the filled text (when closing the dialog without logging in) in the contact step
            // let customerLocalObj = this.dataService?.$storage?.order?.customer;
            customer = {
                isAnonimus: true,
                name: customerLocalObj?.name ? customerLocalObj?.name : '',
                phone: customerLocalObj?.phone ? customerLocalObj?.phone : '',
                email: customerLocalObj?.email ? customerLocalObj?.email : '',
                orderNotes: customerLocalObj?.orderNotes ? customerLocalObj?.orderNotes : '',
                approvedTerms: false,
                includeCutlery: false
            }
            if (customerLocalObj?.firstName) customer.firstName = customerLocalObj?.firstName;
            if (customerLocalObj?.lastName) customer.lastName = customerLocalObj?.lastName;
        }

        if (customerLocalObj?.orderNotes) customer.orderNotes = customerLocalObj.orderNotes;
        if (customerLocalObj?.includeCutlery) customer.includeCutlery = true;
        if (customerLocalObj?.alcoholApproved) customer.alcoholApproved = true;
        if (customerLocalObj?.dinerCount) {
            customer.dinerCount = customerLocalObj.dinerCount;
        } else {
            customer.dinerCount = forceDinerNum ? '' : 1;
        }

        if (includeOrderInstructions) {
            customer.dinerCount = contact?.dinerCount || customer.dinerCount;
            customer.includeCutlery = contact?.includeCutlery || customer.includeCutlery || false;
            customer.alcoholApproved =  contact?.alcoholApproved || customer.alcoholApproved || false;
        }

        const address = args.address;
        if (address) {
            customer.deliveryAddress = {
                addressType: address.addressType,
                city: address.city,
                street: address.street,
                house: address.house,
                floor: address.floor && address.floor.toString(),
                apartment: address?.apartment?.toString(),
                entrance: address.entrance,
                location: address.location,
                notes: address.notes,
                formatted_address: address.formatted_address,
                postalCode: address.postalCode,
                state: address.state,
                isMapAddress: address.isMapAddress
            }
            // Marking if the address came from a map location
            if (address.isMapAddress) customer.deliveryAddress.isMapAddress = address.isMapAddress;

            const region = args.region;
            if (region) {
                customer.deliveryAddress.regionId = region.id;
                customer.deliveryAddress.regionName = region.name;
            }
        }
        return customer;
    }

    createUserWalletPms(externalWallet?: any) {
        const $storage = this.dataService.$storage;

        // don't create wallet details if there's no wallet or the user is unauthenticated
        if (!externalWallet && !this.appService.isAuthUser()) {
            this.deleteLocalWallet();
            return;
        }

        const userWallet = externalWallet || get(this.appService, 'user.wallet');
        const userPms = get(userWallet, 'payments', []);
        const walletPms = [];
        let defPM;

        userPms.forEach(pm => {
            if ($storage.paymentAccounts) {
                $storage.paymentAccounts.find(spm => {
                    if (this.isPushPaymentToWallet(spm, pm)) {
                        let newPM = cloneDeep(pm);
                        newPM.walletPayment = newPM._id;
                        newPM.account = spm._id;
                        newPM.merchantNumber = spm.merchantNumber;
                        walletPms.push(newPM);

                        return true; //select the first match from the account
                    }
                });
            }
        });

        if (walletPms.length) {
            $storage.walletPms = walletPms;
            defPM = find(walletPms, o => o.isDefault);
            if (!defPM) defPM = walletPms[0];
            if (defPM) {
                $storage.wallet = {
                    pms: walletPms,
                    isSinglePM: walletPms.length === 1,
                    ccinfo: defPM
                };
            }
        // User deleted all wallets
        } else if ($storage.walletPms?.length) this.deleteLocalWallet(); 
    }

    deleteLocalWallet() {
        const $storage = this.dataService.$storage;

        delete $storage.walletPms;
        delete $storage.wallet;
    }

    public initOrderExternalResources() {
        const $storage = this.dataService.$storage;
		const types = get($storage, 'pmAccountTypes', {});
        // if (types.AuthorizeNet) {
		// 	$.when(
		// 		$.getScript('https://jstest.authorize.net/v1/Accept.js'),//need to change test
		// 	).done(() => {
		// 		return true;
		// 	});
		// }
		if (types.HeartLand) {
			$.when(
                $.getScript('https://api2.heartlandportico.com/SecureSubmit.v1/token/2.1/securesubmit.js'),
			).done(() => {
				return true;
			});
		}
		if (types.Braintree) {
			$.when(
				$.getScript('https://js.braintreegateway.com/web/3.63.0/js/client.min.js'),
				$.getScript('https://js.braintreegateway.com/web/3.63.0/js/data-collector.min.js'),
				$.getScript('https://js.braintreegateway.com/web/dropin/1.23.0/js/dropin.min.js'),
			).done(() => {
				return true;
			});
		}
		if (types.Stripe) {
			$.when(
				$.getScript('https://js.stripe.com/v3/'),
			).done(() => {
				return true;
			});
		}
	}

    // --------------------------------------------------------------------------------------------------------------->
    // ORDER SERVICE UTILS
    // --------------------------------------------------------------------------------------------------------------->

    getGroupOptionDialog(group) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(ToGroupSelectDialogComponent, {
                    width: "300px",
                    disableClose: true,
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    data: group,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    resolve(result);
                });
            });
        });
    }

    getOrderMethodOption(group) {
        return this.getGroupOptionDialog(group).then(member => {
            return member;
        })
    }

    // --------------------------------------------------------------------------------------------------------------->
    // Extra Fees UTILS
    // --------------------------------------------------------------------------------------------------------------->

    setExtraFees() {
        const $storage = this.dataService?.$storage;
        if ($storage?.isExtraFees) $storage.isExtraFees = false;

        const catalogExtraFees = $storage?.catalog?.extraFees ? cloneDeep($storage?.catalog?.extraFees) : [];
        if (catalogExtraFees.length === 0) {
            return;
        }
        const activeTimeslots = $storage?.activeTimeslots || {};
        const extraFees = catalogExtraFees.filter(extraFee => {
            if (extraFee.active === false) return false;
            if (!extraFee.services || extraFee.services.length === 0) {
                return true; // Apply to all services
            }
            return extraFee.services.includes($storage?.orderMode);
        }).filter(extraFee => {
            if (extraFee.schedule && !activeTimeslots[extraFee.schedule]) {
                return false; // Don't apply if the schedule is not active
            }
            extraFee.price = extraFee.price ? this.appService.getDecimalFromInteger(extraFee.price / 100) : 0;
            return true;
        });

        $storage.extraFees = extraFees;
        $storage.isExtraFees = true;
        if ($storage?.extraFees?.length) this.calculateBasketTotal();
    }

    // --------------------------------------------------------------------------------------------------------------->
    // BASKET UTILS
    // --------------------------------------------------------------------------------------------------------------->

    calculateBasketTotal() {
        let $storage = this.dataService.$storage;
        $storage.alcoholBasketItems = [];
        let order = $storage.order;
        let total = 0;
        let realTotal = 0;
        let q = 0;

        let counters = {};
        let mainCounters = {};
        let loyaltyFee = 0;
        let loyaltyItemName = '';

        each($storage.basket, (offer) => {
            this.addItemToAlcoholBasketArray(offer);
            if (offer.loyaltyItem) {
                loyaltyFee += offer.total;
                loyaltyItemName = offer.name;
            } 
            if (!offer.quantity) offer.quantity = 1;
            q += offer.quantity;
            if (offer.promotion && offer.promotion.calc) {
                total += (offer.promotion.calc.newTotal * offer.quantity);
            } else {
                total += (offer.total * offer.quantity);
            }
            realTotal += (offer.total * offer.quantity);
            if (!counters[offer._id]) {
                counters[offer._id] = offer.quantity;
            } else {
                counters[offer._id] += offer.quantity;
            }

            const mainId = offer?._id?.includes("_fav_") ? offer?._id.split("_fav_")[0] : offer._id;

            if (!mainCounters[mainId]) {
                mainCounters[mainId] = offer.quantity;
            } else {
                mainCounters[mainId] += offer.quantity;
            }
        });

        order.offerCounters = merge(order.offerCounters, counters);
        order.offerMainCounters = mainCounters;

        order.itemsTotal = order.total = total ? round(total, 2) : total;
        order.realTotal = realTotal;//prep for item promotions currently not in use
        order.totalWithoutDelivery = total;
        order.loyaltyItem = { fee: loyaltyFee, name: loyaltyItemName };

        if (order.promotion) {
            const calc = this.calculatePromotion((total - loyaltyFee - get($storage.loyaltyData, 'totalBenefits', 0)), order.promotion);
            if (calc && (calc?.amount >= 0)) total -= calc.amount;
            order.promotion.calc = calc;
        }

        order.totalWithoutDelivery = total;
        let deliveryPrice = 0;
        if (!order.cancelDeliveryPrice) {
            let region = order.region;
            if (region) {
                deliveryPrice = (region.deliveryPrice) || 0;
                if (deliveryPrice > 0) {
                    let freeDeliveryFrom = region.freeDeliveryFrom;
                    if (freeDeliveryFrom && order.itemsTotal - loyaltyFee >= freeDeliveryFrom) {
                        deliveryPrice = 0;
                    };
                };
            };
        }

        const tax = order.tax || 0;
        const fees = order.fees || 0;

        order.benefitsTotal = 0;
        let loyaltyData = $storage.loyaltyData;
        if (loyaltyData) {
            order.benefitsTotal = get(loyaltyData, 'pointsUsed', 0) + get(loyaltyData, 'totalBenefits', 0);
        }

        //calculate Extra Fees
        order.extraFeesTotalAmount = 0;
        if ($storage?.extraFees?.length) {
            const extraFeesTotalAmount = $storage.extraFees.reduce((total, extraFee) => {
                if (!extraFee?.active) return total;
                const amount = extraFee.amount ?? 0;
                return total + (amount / 100);
            }, 0);
            order.extraFeesTotalAmount = extraFeesTotalAmount;
            total += order.extraFeesTotalAmount;
        }

        order.freeDelivery = deliveryPrice === 0;
        order.deliveryPrice = deliveryPrice;
        order.quantity = q;

        order.grandTotalClean = total + deliveryPrice + tax + fees;
        let grandTotal = order.grandTotal = order.grandTotalClean - order.benefitsTotal;

        let grandTotalPlusTip = grandTotal;
        if (order.gratuity && order.gratuity.amount) {
            grandTotalPlusTip += order.gratuity.amount;
        }
        order.grandTotalPlusTip = round(grandTotalPlusTip, 2);
        this.calculateAvailableManualBenefitsCounter();
    }

    addItemToAlcoholBasketArray(offer) {
        let alcoholBasketItems = this.dataService.$storage.alcoholBasketItems;
        if (offer._item?.alcoholQuantity > 0) {
            alcoholBasketItems.push({
                _id: offer._item._id,
                alcoholQuantity: offer._item.alcoholQuantity
            });
        }
        for (const selectionItem of offer.selectionSummary ?? []) {
            for (const item of selectionItem.items ?? []) {
                if (item?._item?.alcoholQuantity > 0) {
                    alcoholBasketItems.push({
                        _id: item._item._id,
                        alcoholQuantity: item._item.alcoholQuantity
                    });
                }
            }
        }
    }

    calculatePromotion(total, promotion) {
        // If has minOrderPrice return
        let newTotal, amount, percent;

        if (promotion.minOrderPrice && total < promotion.minOrderPrice) {
            newTotal = total;
            amount = 0;
            percent = 0;

            return setCalc(total, 0, 0);
        } else {
            const valueType = promotion.valueType;
            const value = promotion.value;

            if (valueType && value) {
                if (valueType == 'percent') {
                    percent = value;
                    amount = this.roundPromotion(value / 100 * total);
                    newTotal = total - amount;
                } else {
                    amount = Math.min(value, total);
                    newTotal = Math.max(total - amount, 0);
                    percent = round((total - newTotal) / total * 100, 2);
                }

                return setCalc(newTotal, amount, percent);
            }
        };

        function setCalc(newTotal, amount, percent) {
            return {
                newTotal,
                amount,
                percent
            }
        }
    }

    roundPromotion(unroundedDiscount) {
        let $storage = this.dataService.$storage;
        var minDenom = ($storage.rosConfig.currencySettings && $storage.rosConfig.currencySettings.minimalDiscountDenomination) || 100;
        minDenom = minDenom / 100;
        var roundedDiscount = round(unroundedDiscount / minDenom) / (1 / minDenom);
        return round(roundedDiscount, 2);
    }

    // --------------------------------------------------------------------------------------------------------------->
    // ADDRESS UTILS
    // --------------------------------------------------------------------------------------------------------------->

    addressQuery(term: string) {
        let that = this;
        if (term === '') return Promise.resolve([]);
        let houseNumberReg = term.match(/\d/g);
        if (!houseNumberReg) return Promise.resolve([]);
        let houseNumber: string;
        let addressWithoutHouseNumber = term;
        houseNumber = houseNumberReg.join('');
        addressWithoutHouseNumber = term.replace(/\d+/g, '');
        return this.addressQueryGoogle(addressWithoutHouseNumber).then((results: any) => {
            let addresses = [];
            let addressValid = false;
            each(results, function (result) {
                let valid = false, partial = false;

                let fixSpecialCharsStr =that.fixSpecialChars(result.formatted_address);
                fixSpecialCharsStr = [fixSpecialCharsStr.slice(0, fixSpecialCharsStr.indexOf(',')), ' ' + houseNumber, fixSpecialCharsStr.slice(fixSpecialCharsStr.indexOf(','))].join('');

                let address: any = { formatted_address: fixSpecialCharsStr ,place_id:result.place_id };

                if (result.is_partial_address) {
                    address.city = result.locality;
                    address.isPartialAddress = true;
                    valid = true;
                } else {
                    each(result.address_components, function (comp) {
                        let val = that.fixSpecialChars(comp.long_name);
                        switch (comp.types[0]) {
                            case "street_number":
                                address.house = val;
                                valid = true;
                                break;
                            case "route":
                                address.street = val;
                                partial = true;
                                break;
                            case "locality":
                                address.city = val;
                                partial = true;
                                break;
                        }
                    });
                }
                if (valid) {
                    address.location = result.geometry.location;
                    addresses.push(address);
                    addressValid = true;
                } else if (partial) {
                    if (!find(results, { locality: address.city })) {
                        address.partial = true;
                        addresses.push(address);
                    }
                }
            });
            return addresses;
        });
    }

    addressQueryGoogle(term: string) {
        let $storage = this.dataService.$storage;
        let params = {
            address: term,
            sensor: false,
            components: $storage.local.mapSearchLocalComponents,
            language: $storage.local.mapLanguage,
            key: this.appService.appConfig.googleKey
        }
        return this.dataService.exHTTPGet('https://maps.googleapis.com/maps/api/geocode/json', params).then((results: any) => results.results);
    }

    async validateSiteAddress(address) { //selectAddress
        const $storage = this.dataService.$storage;
        const isODelay = $storage.forceDelay;

        return new Promise(async (resolve, reject) => {
            let validateSites = [], disabledRegion, relevantSitesRegionSettings;
            const point = new google.maps.LatLng(address.location.lat, address.location.lng);
            const relevantSites = this.filterUnavailableSite($storage.relevantSites);

            let siteIds = relevantSites.map(site => (site._id || site.id));
            // Need to get delivery regions here
            if ($storage.isChain) {
                relevantSitesRegionSettings = await this.dataService.postBridge('/configuration/chains/get-delivery-regions', { siteIds }, false);
            }

            this.getValidateSites(relevantSites,relevantSitesRegionSettings, point, validateSites, disabledRegion, isODelay);

            if (validateSites?.length) {
                resolve(validateSites);
            } else {
                this.appService.stopBlock({ class: 'dark-block' });

                let existsInReverseMode = false;
                let message = "MESSAGES.NO_BRANCH_SERVING_ADDRESS";
                let sTitle = "error_title";

                if ($storage.isChain) {
                    message = "MESSAGES.NO_BRANCH_SERVING_SPECIFIC_ADDRESS";
                    const relevantSitesForceDelay = this.filterUnavailableSite($storage.relevantSites, !$storage.forceDelay);
                    const siteIds = relevantSitesForceDelay.map(site => (site._id || site.id));
                    relevantSitesRegionSettings = await this.dataService.postBridge('/configuration/chains/get-delivery-regions', { siteIds }, false);

                    this.getValidateSites(relevantSitesForceDelay, relevantSitesRegionSettings, point, validateSites, disabledRegion, isODelay);

                    if (validateSites?.length) {
                        existsInReverseMode = true;
                        sTitle = "were_sorry";
                        message = $storage.forceDelay ? "MESSAGES.NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN": "MESSAGES.NO_BRANCH_SERVING_SPECIFIC_ADDRESS_CHAIN_FUTURE";
                    }
                }
                // Send logger for Address unavailable
                this.dataService.logger("Address unavailable", {
					formattedAddress: address.formatted_address,
					geoLocation: `${address.location.lat}, ${address.location.lng}`,
					locationUnavailableReason: disabledRegion ? 'currently unavailable' : 'out of reach',
                    userSelectedAddress: address,
				}, `Address unavailable for formatted_address: ${address.formatted_address}`);

                if (disabledRegion) {
                    sTitle = "MESSAGES.DISABLED_REGION_TITLE";
                    message = this.getDisabledRegionMessage(disabledRegion);
                }

                const sMessage = this.translate(message, {
                    address: address.formatted_address,
                    t: disabledRegion?.enabledOn?.time,
                    d: disabledRegion?.enabledOn?.day
                });

                this.appService.stopBlock({ class: 'dark-block' });

                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'info',
                        dialogTitle: sTitle,
                        dialogText: sMessage,
                        hideSecondaryButton: true
                    }).then(() => {
                        reject();
                    });
                });
            }
        });
    }

    getValidateSites(relevantSites,relevantSitesRegionSettings, point, validateSites, disabledRegion, isODelay) {
        const $storage = this.dataService.$storage;
        each(relevantSites, relevantSite => {
            relevantSite.delivery = this.prepareDeliveryData(relevantSitesRegionSettings, relevantSite)
            // fix region groups
            if (!relevantSite.delivery.$regionsPrepared) {
                relevantSite.delivery.$regionsPrepared = true;
                each(relevantSite.delivery.regionGroups, regionGroup => {
                    const deliveryTime_add = regionGroup.deliveryTime_add;
                    const isInactive = regionGroup.active === false;
                    if (regionGroup.active === false || regionGroup.deliveryTime_add) {
                        relevantSite.delivery.regions.forEach(region => {
                            if (region.group == regionGroup.id) {
                                if (isInactive) region.active = false;
                                if (deliveryTime_add) region.groupTime_add = deliveryTime_add;
                            }
                        })
                    }
                });
            }

            each(relevantSite.delivery.regions, region => {
                let isRegionAvailable: boolean = true;

                if (region?.disabledSlot) {
                    const deliveryDisabled = this.isInTimeSlot(region.disabledSlot);
                    if (deliveryDisabled) {
                        region.active = false;
                    }
                }

                const poly = new google.maps.Polygon({ paths: region.paths });
                if (google.maps.geometry.poly.containsLocation(point, poly)) {
                    if (region.active === false) {
                        disabledRegion = { branch: relevantSite, region };
                        isRegionAvailable = false;
                    }

                    // Region is open only to either future or immediate delivery
                    if (region.orderTime) {
                        if ((region.orderTime === 'sameDay' && isODelay) || (region.orderTime === 'future' && !isODelay)) {
                            disabledRegion = { branch: relevantSite, region, enabledOn: { orderTime: region.orderTime } };
                            isRegionAvailable = false;
                        }
                    }

                    if (region.schedule) {
                        const timeSlot = find(relevantSite.timeslots, { _id: region.schedule });

                        if (!$storage.delayedOrder && timeSlot && this.isTimeSlotInDateRange(timeSlot) && !this.isTimeslotsActive(timeSlot)) {
                            disabledRegion = { branch: relevantSite, region };
                            isRegionAvailable = false;

                            const enabledOn = this.getRegionTimeString(timeSlot?.days);
                            if (enabledOn) {
                                disabledRegion.enabledOn = enabledOn;
                            } 
                        }
                    }
                    // region is available for this site
                    if (isRegionAvailable) {
                        const found = validateSites.find(validatedSite => validatedSite.site._id == relevantSite._id);
                        if (!found) validateSites.push({ site: relevantSite, region: region });
                    }
                }
            });
        });
    }

    getRegionTimeString(scheduleDays: object): { time: string, isToday: boolean, day?: string } | undefined {
        let timeFrom: string, timeTo: string, time: string;
        const todayNumber = new Date().getDay() + 1;

        // Open today on different hours
        if (scheduleDays[todayNumber]?.active === true) { 
            if (scheduleDays[todayNumber]?.tslots?.length) {
                return { time: scheduleDays[todayNumber]?.tslots[0], isToday: true };
            } else if (scheduleDays[todayNumber]?.slots?.length) {
                timeFrom = moment(scheduleDays[todayNumber].slots[0]?.from).format('HH:mm');
                timeTo = moment(scheduleDays[todayNumber].slots[0]?.to).format('HH:mm');
                if (timeFrom && timeTo) return { time: `${timeFrom}-${timeTo}`, isToday: true };
            }
        } else {
            // Open on different days
            let day: string;
            for (let i = 1; i <= 7; i++) {
                const nextDayIndex = ((todayNumber + i - 1) % 7 + 7) % 7 + 1;
                if (scheduleDays[nextDayIndex]?.active) {
                    const nextDayName = this.getNextDayString(todayNumber, nextDayIndex);
                    if (!nextDayName) return;
                    day = nextDayName;

                    if (scheduleDays[nextDayIndex]?.type === 'default' && scheduleDays[0]?.slots) {
                        timeFrom = moment(scheduleDays[0].slots[0]?.from).format('HH:mm');
                        timeTo = moment(scheduleDays[0].slots[0]?.to).format('HH:mm');
                    } else if (scheduleDays[nextDayIndex]?.type === 'custom' && scheduleDays[nextDayIndex]?.slots?.length) {
                        timeFrom = moment(scheduleDays[nextDayIndex].slots[0]?.from).format('HH:mm');
                        timeTo = moment(scheduleDays[nextDayIndex].slots[0]?.to).format('HH:mm');
                    } 
    
                    if (timeFrom && timeTo) time = `${timeFrom}-${timeTo}`;
                    break;
                }
            }
    
            if (time && day) return { time, day, isToday: false }; 
        }
    
        return;
    }
    
    getNextDayString(dayOfTheWeek: number, dayNumber: number): string {
        const locale = this.appService?.localeId.substring(0, 2);
    
        if ((dayOfTheWeek % 7) + 1 === dayNumber) {
            return locale === 'he' ? 'מחר' : 'tomorrow';
        } 
        dayNumber -= 1;
        const date = moment().day(dayNumber).locale(locale).format('dddd');
        return locale === 'he' ? 'ביום ' + date : 'on ' + date;
    }

    getDisabledRegionMessage(disabledRegion) {
        if (disabledRegion.enabledOn) {
            if (disabledRegion?.enabledOn?.orderTime === 'future') return 'MESSAGES.DISABLED_REGION_FOR_IMMEDIATE_ORDERS_MESSAGE';
            if (disabledRegion?.enabledOn?.orderTime === 'sameDay') return 'MESSAGES.DISABLED_REGION_FOR_FUTURE_ORDERS_MESSAGE';
            return disabledRegion.enabledOn?.isToday ? "MESSAGES.ENABLED_REGION_TIME_MESSAGE" : "MESSAGES.ENABLED_REGION_DAY_MESSAGE";
        }

        return "MESSAGES.DISABLED_REGION_MESSAGE";
    }
    
    checkBranchHours(site, clientDate, serverDate) {
        this.dataService.checkTimeDiffAfterInitiation(Date.now(), serverDate, site.timezone, true);
        const timeDifference = this.appService.getTimeDifference(clientDate, serverDate, site.timezone);
        const $storage = this.dataService.$storage;
        const that = this;

        return new Promise((resolve, reject) => {
            this.appService.showTimeDiffMessage(timeDifference).then(() => {
                let workHours = site.workHours, dwh;
                let mOffset = get(site, 'settings.preorderThreshhold', 120);
                switch ($storage.orderMode) {
                    case "delivery": dwh = get(site, "delivery.workhours"); break;
                    case "eatin": dwh = get(site, "eatin.workhours"); break;
                }

                if ($storage.forceDelay) {
                    let slot = get(site, 'futureOrder2.orderSlot', get(site, 'futureOrder.orderSlot'));
                    if (slot) {
                        dwh = slot;
                        mOffset = 0;
                    } else {
                        if (!this.canFutureOrderNow(site, $storage.orderMode)) {
                            that.appService.mainMessage({
                                dialogType: 'info',
                                dialogText: '_DELAYED.service_disabled_today',
                                primaryButtonText: 'VIEW_MENU'
                            }).then(() => {
                                resolve({
                                    readOnly: true,
                                    branch: site,
                                });
                            }).catch(err => {
                                reject();
                            });
                            return;
                        }
                        if (get(site.futureOrder2, 'enableOutsideofWorkhours', get(site.futureOrder, 'enableOutsideofWorkhours', true))) {
                            resolve({ slot: { isFuture: true } });
                            return;
                        }
                    }
                }

                if (dwh) {
                    const dWorkHours = find(site.timeslots, { _id: dwh })
                    if (dWorkHours) workHours = dWorkHours;
                }

                const branch = cloneDeep(site);
                const md = this.appService.getRealDateMoment();
                let d = md.day();
                const dayM = 24 * 60;
                const startThreshhold = 5 * 60;
                let cmd = getMinutes(md);
                if (cmd < startThreshhold) {
                    d -= 1;
                    if (d < 0) d = 6;
                    cmd += dayM;
                }

                let day: any = find(workHours.days, { day: d });
                if (!day?.active || !this.isTimeslotRangeActive(workHours)) {//view only menu
                    this.appService.mainMessage({
                        dialogType: 'info',
                        dialogText: 'MESSAGES.BRANCH_CLOSED_TODAY',
                        primaryButtonText: 'VIEW_MENU',
                    }).then(function () {
                        resolve({
                            readOnly: true
                        });
                    }, function () {
                        reject();
                    });
                    return;
                }

                if (day.type == "default") day = find(workHours.days, { day: -1 });

                day.tslots = [];

                let slotFound, slotFoundOffset, nextSlots;
                for (let i = 0; i < day.slots.length; i++) {
                    let slot = day.slots[i];

                    slot.from = moment(slot.from);
                    slot.to = moment(slot.to);

                    slot.tfrom = slot.from.format("HH:mm");
                    slot.tto = slot.to.format("HH:mm");

                    slot.mfrom = getMinutes(slot.from);
                    if (slot.from.date() == 2) slot.mfrom += dayM;
                    slot.mto = getMinutes(slot.to);
                    if (slot.to.date() == 2) slot.mto += dayM;

                    if (nextSlots) {
                        nextSlots.push(slot);
                        if (slotFound) continue;
                    }

                    day.tslots.push(slot.tfrom + " - " + slot.tto);

                    if (!day.firstSlotStart) day.firstSlotStart = slot.tfrom;

                    if (cmd > slot.mfrom && cmd < slot.mto) {
                        nextSlots = slot.nextSlots = [];
                        slotFound = slot;
                    } else if (!slotFoundOffset && cmd < slot.mfrom && cmd > slot.mfrom - mOffset) {
                        nextSlots = slot.nextSlots = [];
                        slotFoundOffset = slot;
                    }
                }

                let retTime: any = {
                    found: slotFound,
                    text: day.tslots.length == 1 ?
                        this.translate('BRANCH_TIME_CONFIRM_0', { t0: this.formatTimeByRegion(day.tslots[0]) }) :
                        this.translate('BRANCH_TIME_CONFIRM_1', { t0: this.formatTimeByRegion(day.tslots[0]), t1: this.formatTimeByRegion(day.tslots[1]) }),
                    startText: day.firstSlotStart
                };
                delete $storage.preOrderM;
                if (!slotFound) {
                    if (!slotFoundOffset) {
                        this.appService.mainMessage({
                            dialogTitle: 'error_title',
                            secondaryButtonText: this.translate("MESSAGES.CONDITIONS_SECONDARY_BUTTON_TEXT"),
                            dialogType: 'info',
                            dialogText: retTime.text,
                            primaryButtonText: 'VIEW_MENU'
                        }).then(() => {
                            resolve({
                                readOnly: true,
                                branch: branch,
                                slot: retTime
                            });
                        }).catch(err => {
                            reject();
                        });
                    } else {
                        retTime.found = slotFoundOffset;
                        checkBranchHours_retPrepare(retTime);
                        $storage.preOrderM = slotFoundOffset.mfrom;
                        that.appService.mainMessage({
                            dialogType: 'info',
                            dialogText: that.translate("MESSAGES.BRANCH_CLOSED_NOW", { t: this.dataService.timeFormatConverter(slotFoundOffset.tfrom) }),
                            primaryButtonText: that.translate("CONFIRM")
                        }).then(function () {
                            resolve({
                                slot: retTime,
                                isPreOrder: true
                            });
                        }, function () {
                            reject();
                        });
                    }
                } else {
                    checkBranchHours_retPrepare(retTime);
                    resolve({
                        slot: retTime,
                    });
                }
            });

            function getMinutes(m) {
                return (m.hours() * 60) + m.minutes();
            }

            function checkBranchHours_retPrepare(wh) {
                const d = that.appService.getRealDateMoment();
                const diffDays = d.diff(moment([1970, 0, 1]), 'days');
                wh.slotMin = moment(wh.found.from).add(diffDays, 'days');
                wh.slotMax = moment(wh.found.to).add(diffDays, 'days');
                wh.slotAlert = moment(wh.slotMax).add(-15, 'minutes');
            }
        });
    }

    formatTimeByRegion(timeslot) {
        if (this.appService.appConfig.locale != 'he-IL') {
            const times = timeslot.split(' - ');
            const startTime = moment(times[0], 'HH:mm').format('h:mm A');

            if (times.length === 2) {
                const endTime = moment(times[1], 'HH:mm').format('h:mm A');
                return `${startTime} - ${endTime}`;
            } else {
                return `${startTime}`;
            }
        }
        return timeslot;
    }

    checkBranchHours_file(site, fileURL) {
        let timeslots = get(site, 'timeslots', []), n = timeslots.length;
        for (let i = 0; i < n; i++) {
            let timeSolt = timeslots[i];
            if (this.isTimeslotsActive(timeSolt)) {
                if (timeSolt.file && timeSolt.file.url) {
                    fileURL = timeSolt.file.url;
                    break;
                }
            }
        }
        return fileURL;
    }

    handleAddressBranchFound(branch, region, address, time) {
        let $storage = this.dataService.$storage;
        if (!address.addressType) address.addressType = $storage.defaultAddressType;
        let _branch = cloneDeep(branch);
        _branch.wh = time;

        let deliveryOffer = branch.delivery.deliveryPriceOffer;
        let deliveryTime = _getNum(branch.delivery.deliveryTime);
        let minOrderPrice = _getNum(branch.delivery.minOrderPrice);
        let freeDeliveryFrom = _getNum(branch.delivery.freeDeliveryFrom);

        if (get(branch, "settings.tabitOrderDisableDeliveryFee")) {
            deliveryOffer = null;
            region.deliveryPriceOffer = null;
            freeDeliveryFrom = 0;
            region.freeDeliveryFrom_add = 0;
        }

        if (!region.useDefaults) {
            if (region.deliveryPriceOffer) deliveryOffer = region.deliveryPriceOffer;
            deliveryTime += _getNum(region.deliveryTime_add);
            minOrderPrice += _getNum(region.minOrderPrice_add);
            freeDeliveryFrom += _getNum(region.freeDeliveryFrom_add)
        }
        deliveryTime += get(region, 'groupTime_add', 0);
        deliveryTime += get(branch, 'timesOffsetSetup.deliveryTimeOffset', 0);
        let _region = {
            name: region.name,
            id: region._id,
            deliveryOffer: deliveryOffer,
            deliveryPrice: deliveryOffer ? deliveryOffer.price / 100 : 0,
            deliveryTime: deliveryTime,
            minOrderPrice: (minOrderPrice || 0) * 1,
            freeDeliveryFrom: !isNaN(freeDeliveryFrom) && Number(freeDeliveryFrom) > 0 ? Number(freeDeliveryFrom) : null
        }
        if (_region.minOrderPrice <= 0) {
            delete _region.minOrderPrice;
        };

        return {
            branch: _branch,
            region: _region,
            address,
            workSlot: time
        }

        function _getNum(val) {
            if (isNaN(val)) return 0;
            return Number(val);
        }

    }

    fixSpecialChars(str) {
        let regexSpecialChars = /[\uD83D\uFFFD\uFE0F\u203C\u3010\u3011\u300A\u166D\u200C\u202A\u202C\u2049\u20E3\u300B\u300C\u3030\u065F\u0099\u0F3A\u0F3B\uF610\uFFFC]/g;
        return str.replace(regexSpecialChars, "");
    }

    // --------------------------------------------------------------------------------------------------------------->
    // MESSAGES
    // --------------------------------------------------------------------------------------------------------------->

    getSiteMessages(siteConfig, timeSlots, checkOrderTiming, checkMessageRegion = false) {
        let $storage = this.dataService.$storage;
        // Left it here in case they'd change their mind
        let isFutureOrder = $storage.forceDelay;
        return filter(siteConfig.messages, (message) => {
            if (checkMessageRegion && message.deliveryRegions && message.deliveryRegions[0] && !message.deliveryRegions.includes($storage.order?.region?.id)) return false;
            if (!message.$translated) message.$translated = message.message;
            message.message = get(message, `translations.${$storage.catalogTrans}.message`, message.$translated);
            // If message is in enabled slot, activate it
            if (message.enabledSlot) { 
                if (this.isInTimeSlot(message.enabledSlot) === true) message.active = true;
            } 
            if (message.active && (!message.schedule || timeSlots[message.schedule] !== false)) {
                if (checkOrderTiming && message.orderTime) {
                    switch (message.orderTime) {
                        case "sameDay": if (isFutureOrder) return false; break;
                        case "future": if (!isFutureOrder) return false; break;
                    }
                }
                return true;
            };
            return false;
        });
    }

    popMessages(placement, scope?, session?, isFutureOrder?) {
        const $storage = this.dataService.$storage;
        let { prepedMessages, imagesMessages } = this.specialMessagesService.getPrepedMessagesForTO(placement, $storage, scope, session, isFutureOrder);
        // If we landed directly from the App
        if (!!$storage.isWeb) this.showContextMessages('home');
        if (prepedMessages?.length) {
            setTimeout(() => this.specialMessagesService.showSpecialMessages(prepedMessages), 1000);
        }
        if (imagesMessages?.length) {
            this.showImageMessage(imagesMessages, 0);
        }
    }

    showImageMessage(iMessages, index) {
        this.specialMessagesService.showImages = false;
        let message = iMessages[index];
        if (!message) return;
        let url = this.dataService.ISDESKTOP ? get(message, 'desktopImage.url') : get(message, 'mobileImage.url');
        if (!url) return this.showImageMessage(iMessages, ++index);

        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            let dialogRef = this.dialog.open(ToImageDialogComponent, {
                disableClose: false,
                panelClass: 'to-image-dialog',
                direction: this.appService.direction,
                data: { url },
                autoFocus: false
            });
            dialogRef.afterClosed().subscribe(result => {
                this.showImageMessage(iMessages, ++index);
            });
        });
    }

    getContextMessages(placement, scope?, checkOrderTiming?) {
        let $storage = this.dataService.$storage;
        let isFutureOrder = $storage.forceDelay;
        let messages = {
            messages: [],
            iMessages: []
        }
        each($storage.messages, (message) => {
            if (message.$wasDisplayed) return;
            if (scope && message.scope != scope && message.scope != 'general') return false;
            if (checkOrderTiming && message.orderTime) {
                switch (message.orderTime) {
                    case "sameDay": if (isFutureOrder) return false; break;
                    case "future": if (!isFutureOrder) return false; break;
                }
            }
            if (message.active && message.placement == placement) {
                message.$wasDisplayed = true;

                message.displayType == 'image' ? messages.iMessages.push(message) : messages.messages.push(message);
            }
        });
        return messages;
    }

    showContextMessages(placement, scope?, checkOrderTiming?) {
        let oMessages = this.getContextMessages(placement, scope, checkOrderTiming);
        if (oMessages.messages?.length) setTimeout(() => this.specialMessagesService.showSpecialMessages(oMessages.messages), 500);
        if (oMessages.iMessages?.length) this.showImageMessage(oMessages.iMessages, 0);
    }

    showMobileHeaderLinks(step): boolean {
        if (window['cordova']) return false;
        if (step !== this.dataService.orderSteps.enter) return true;
        return false;
    }

    displayValidationErrors(arr) {
        return this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            return this.appService.mainMessage({
                dialogType: 'error',
                dialogText: arr[0]?.text + "\n",
                hideSecondaryButton: true
            });
        });
    }

    // --------------------------------------------------------------------------------------------------------------->
    // TIME UTILS
    // --------------------------------------------------------------------------------------------------------------->

    calculateTimeSlots(timeslots, md, allDayReffTime) {
        let that = this;
        let activeSlots = {};
        each(timeslots, timeslot => {
            const match = that.isTimeslotsActive(timeslot, md, allDayReffTime);
            if (match) activeSlots[timeslot._id] = true;
            else activeSlots[timeslot._id] = false;
        });
        return activeSlots;
    }

    isTimeslotsActive(timeslot, md?, allDayReffTime?, retOffset?) {
        const $storage = this.dataService.$storage;
        const isOrderDelay = $storage?.order?.orderDelay?.moment;

        // moment(v).isValid() returns true for undefined
        if (!md) {
            md = isOrderDelay && moment(isOrderDelay).isValid() ? moment(isOrderDelay) : this.appService.getRealDateMoment();
        } else {
            if (!md._isValid) md = moment(md);
        }
        if (!this.isTimeslotRangeActive(timeslot, md)) return false;
        let d = md.day();

        let dayM = 24 * 60;
        let startThreshhold = 5 * 60;
        let cmd = getMinutes(md);
        if (cmd < startThreshhold) {
            d -= 1;
            if (d < 0) d = 6;
            cmd += dayM;
        }

        let day: any = find(timeslot.days, { day: d });
        if (!day.active) return null;
        if (day.type == "allday") return {};
        if (day.type == "default") day = find(timeslot.days, { day: -1 });

        day.tslots = [];
        let slotFound, offsetStart, offsetEnd;
        for (let i = 0; i < day.slots.length; i++) {
            let slot = day.slots[i];

            slot.from = moment(slot.from);
            slot.to = moment(slot.to);

            slot.tfrom = slot.from.format("HH:mm");
            slot.tto = slot.to.format("HH:mm");

            slot.mfrom = getMinutes(slot.from);
            if (slot.from.date() == 2) slot.mfrom += dayM;
            slot.mto = getMinutes(slot.to);
            if (slot.to.date() == 2) slot.mto += dayM;

            day.tslots.push(slot.tfrom + " - " + slot.tto);
            if (!isOrderDelay && $storage?.order?.isPreOrder) {
                if ($storage.order?.workSlot?.found.mfrom >= slot.mfrom && $storage?.order?.workSlot?.found?.mfrom < slot.mto) {
                    slotFound = slot;
                    break;
                }
            } else {
                if (allDayReffTime || cmd >= slot.mfrom && cmd < slot.mto) {
                    slotFound = slot;
                    break;
                } else {
                    if (cmd > slot.mto && !offsetEnd) offsetEnd = cmd - slot.mto;
                    if (cmd < slot.mfrom && !offsetStart) offsetStart = slot.mfrom - cmd;
                }
            }
        }
        if (slotFound) return slotFound;
        if (retOffset) return { offsetStart: offsetStart, offsetEnd: offsetEnd, error: true }

        function getMinutes(m) {
            return (m.hours() * 60) + m.minutes();
        }
    }

    roundToQuarterHour(date) {
        const quarterHour = 15 * 60 * 1000; // milliseconds in a quarter of an hour
        const roundedDate = new Date(Math.ceil(date.valueOf() / quarterHour) * quarterHour);
        return moment(roundedDate);
    }

    isTimeSlotInDateRange(timeSlot): boolean {
        // If the timeslot is restricted to specific dates, it only applies during that period
        if (timeSlot?.limitToDate && timeSlot?.dateRange) {
            const currentDate = this.appService.getRealDateMoment();
            const startDate = moment(timeSlot.dateRange.startDate);
            const endDate = moment(timeSlot.dateRange.endDate);
            if (!currentDate.isBetween(startDate, endDate, null, '[]')) {
                return false;
            }
        }
        return true;
    }

    getOrderDelayOptions(selected?) {
        const $storage = this.dataService.$storage;
        const config = $storage.order.branch;

        const orderSetup = config[$storage.orderMode];
        let maxOrderDelay = Number(orderSetup.maxOrderDelay) || 1440;
        let minOrderDelay = this.dataService.getPreparationTime();

        let n = minOrderDelay, members:any = [{ date: null, text: this.translate("ASAP") }];
        let date = this.appService.getRealDateMoment();
        date = this.roundToQuarterHour(date);
        let wh = $storage.order.workSlot, slotMin, slotMax, nextSlots, diffDays;

		if (wh?.found) {
			diffDays = date.diff(moment([1970, 0, 1]), 'days');
			slotMin = moment(wh.found.from).add(diffDays, 'days');
			slotMax = moment(wh.found.to).add(diffDays, 'days');
			if (date.isBefore(slotMin)) {
				date = slotMin;
			}
			nextSlots = wh.found.nextSlots;
		};

        let moffset = date.minutes() % 15, nn = 0;
        if (moffset > 0) moffset = 15 - moffset;
        n += moffset;
        maxOrderDelay += n;
        n += 15;
        n = Math.ceil(n/15) * 15;

        do {
            const _date = date.clone().add(n, 'minutes');
            if (slotMax && _date.isAfter(slotMax)) {
                // maxOrderDelay = 0;
                break;
            } else {
                const isSlotThrottled = this.throttlingService.checkIfSlotThrottled(_date.toDate());
                members.push({ id: `A_${++nn}`, date: _date.toDate(), minutes: n, text: _date.format($storage.local.timeFormat), throttled: isSlotThrottled });
                n += 15;
            }
        } while (n < maxOrderDelay);

		if (nextSlots && n < maxOrderDelay) {
			each(nextSlots, nextSlot => {
				if (n > maxOrderDelay) return false;
				slotMin = moment(nextSlot.from).add(diffDays, 'days');
				slotMax = moment(nextSlot.to).add(diffDays, 'days');
				do {
					const _d = date.clone().add(n, 'minutes');
					if (_d.isAfter(slotMax)) break;
					if (_d.isAfter(slotMin)) {
						members.push({ date: _d.toDate(), minutes: n, text: _d.format($storage.local.timeFormat) });
					}
					n += 15;
				} while (n < maxOrderDelay);
			})
		}
        // Need to check if disabling of ASAP slot needed
        const getSupplyTime = this.dataService.getSupplyTime();
        const isDisableASAP = this.throttlingService.isDisableASAP(members, getSupplyTime);
        if (isDisableASAP) members[0].throttled = true;

        let _selected = this.throttlingService.getFirstNonThrottledMember(members);
        if (selected) {
            const match = members.find(member => member.id == selected.id);
            if (match) _selected = match;
        }

        return {
            minOrderDelay,
            maxOrderDelay,
            members,
            selected: _selected
        }
    }

    setOrderDelayOption(options, selected?) {
        const $storage = this.dataService.$storage;
        if (selected?.date) {
            const mdiff = this.dataService.getPreparationTime();
            selected.moment = moment(selected.date).subtract(mdiff, 'minutes');
            selected.minOrderDelay = options.minOrderDelay;
            $storage.order.orderDelay = selected;
        } else {
            delete $storage.order.orderDelay;
        }
    }

    getOrderReferenceTime() {
        const supplyArgs: any = this.dataService.getSupplyTime();
        if (supplyArgs.toBeSuppliedOn) {
            return moment(supplyArgs.toBeSuppliedOn);
        }
        return this.appService.getRealDateMoment();
    }

    getOrderDelayToBePreparedOnInMinute() {
        const orderDelay = this.dataService?.$storage?.order?.orderDelay;
        const realDate = this.dataService.getPreparationTime();

        if (orderDelay?.date && moment(orderDelay?.date).isValid() && moment(orderDelay?.date).isAfter(realDate)) {
            return moment(orderDelay.date).add(realDate * -1, 'minutes').minute();
        }
        return
    }

    getTimeFromMins(mins) {
        const $storage = this.dataService.$storage;
        var h = mins / 60 | 0,
            m = mins % 60 | 0;
        return moment.utc().hours(h).minutes(m).format($storage.local.timeFormat);
    }

    isInTimeSlot(slot): boolean {
        if (slot?.from && slot.to) {
            const from = Date.parse(slot.from);
            const to = Date.parse(slot.to);
            const now = Date.now();

            // If now we're in the time slot, return true
            if (now < to && now > from) {
                return true;
            } 
        }

        return false;
    }

    // --------------------------------------------------------------------------------------------------------------->
    // FUTURE ORDERS
    // --------------------------------------------------------------------------------------------------------------->

    canFutureOrderNow(site, mode) {
        let days;
        if (site.futureOrder2) {
            days = find(site.futureOrder2.services, { service: mode }).days;
        } else {
            days = get(site.futureOrder, 'days');
        }
        if (!days) days = this.dataService.futureDelayBase.days;
        const day = this.appService.getRealDateMoment().toDate().getDay();
        const dataDay = find(days, { day: day });

        return dataDay && dataDay.enableOrder;
    }

    prepareFutureOrderConfig(site, mode) {
        let response;
        if (site.futureOrder2) {
            let service = find(site.futureOrder2.services, { service: mode });
            if (!service) service = find(site.futureOrder2.services, { service: 'takeaway' });
            response = cloneDeep(service);
            each(site.futureOrder2, (val, key) => {
                if (key != 'services' && key != 'minDelayDays') response[key] = val;
            });
        } else {
            if (site.futureOrder?.days) {
                response = cloneDeep(site.futureOrder);
                response.supplyTimeDisclaimer = site.settings.supplyTimeDisclaimer;
                response.translations = site.translations

                each(response.days, day => {
                    if (mode == 'delivery') {
                        day.supplyMode = day.deliverySupplyMode;
                        day.supplyRange = day.deliverySupply;
                    } else {
                        day.supplyMode = day.takeawaySupplyMode;
                        day.supplyRange = day.takeawaySupply;
                    }
                });
            }
        }
        return assignIn(cloneDeep(this.dataService.futureDelayBase), response);
    }

    getFutureOrderConfig(_branch?) {
        const that = this;

        const $storage = this.dataService.$storage;
        const order = $storage.order;
        const branch = _branch ? _branch : order.branch;
        const orderMode = $storage.orderMode;

        const delayedOrder = this.prepareFutureOrderConfig(branch, orderMode);
        const supplyModeAtt = 'supplyMode', supplyRangeAtt = 'supplyRange';

        const res: any = {
            "inactiveDates": [],
            "activeDays": [],
            "days": delayedOrder.days,
            "slots": [],
            "today": this.appService.getRealDateMoment().startOf('day'),
            "dates": [],
            "mode": 'futureOrder',
            "supplyTimeDisclaimer": delayedOrder.supplyTimeDisclaimer,
            "translations": delayedOrder.translations
        }

        const defaultDay = delayedOrder.days[0];
        delayedOrder.days.shift();
        const timeStep = delayedOrder.supplyTimeSteps || 30;
        each(res.days, day => {
            if (day.enableSupply) {
                res.activeDays.push(day.day);
                if (day.type == "default") {
                    day.enableSameDaySupply = defaultDay.enableSameDaySupply;
                    day.maxTimeForSameDay = defaultDay.maxTimeForSameDay;
                    day.supplyMode = defaultDay[supplyModeAtt];
                    day.supplyRange = defaultDay[supplyRangeAtt];
                    day.stopOrderSlots = defaultDay.stopOrderSlots;
                } else {
                    day.supplyMode = day[supplyModeAtt];
                    day.supplyRange = day[supplyRangeAtt];
                }

                if (day.supplyMode == 'range' || day.supplyMode == 'range_max') {
                    let slots = that.prepareDayTimeRange(day, timeStep);
                    if (slots) day.slots = slots;
                }
            }
        });

        res.inactiveDates = map(delayedOrder.inactiveDates, o => {
            return moment(o);
        });

        let now = this.appService.getRealDateMoment();
        let weekDay = now.day();
        let metaDay = delayedOrder.days[weekDay];
        let minDelayDays = delayedOrder.minDelayDays;

        let mmtStart = now.clone().startOf('day');
        let diffMinutes = now.diff(mmtStart, 'minutes');
        if (minDelayDays == 0 && !metaDay.enableSameDaySupply) minDelayDays += 1;
        if (diffMinutes > metaDay.maxTimeForSameDay) minDelayDays += 1;
        // fix ranges for samesay supply
        if (minDelayDays == 0 && (metaDay.supplyMode == 'range' || metaDay.supplyMode == 'range_max')) {

            let minOrderDelay = this.dataService.getPreparationTime();
            diffMinutes += minOrderDelay;

            let diffMinutesMod = diffMinutes % timeStep;
            if (diffMinutesMod) {
                diffMinutesMod = diffMinutes + (timeStep - diffMinutesMod);
            } else {
                diffMinutesMod = diffMinutes;
            }

            if (diffMinutesMod > metaDay.supplyRange.to) minDelayDays += 1;
            else {
                let slots = this.prepareDayTimeRange(metaDay, timeStep, diffMinutes);
                if (slots) metaDay.todaySlots = slots;
                else minDelayDays += 1;
            }
        }

        res.minDate = now.clone().add(minDelayDays, 'days').startOf('day');
        res.maxDate = now.clone().add(delayedOrder.maxDelayDays, 'days').endOf('day');

        let checkDay = this.appService.getRealDateMoment().startOf('day').add(minDelayDays, 'days');
        let nn = minDelayDays;
        do {
            if (res.activeDays.indexOf(checkDay.day()) != -1) {
                let _was;
                each(res.inactiveDates, mDay => {
                    if (mDay.isSame(checkDay, 'day')) _was = true;
                });
                if (!_was) {
                    let _date = checkDay.toDate();
                    if (!res.date) res.date = _date;
                    res.dates.push(_date);
                }
            }
            checkDay.add(1, 'day');
            ++nn;
        } while (nn <= delayedOrder.maxDelayDays)

        // prepare day slots ---------------------------------------------------->
        each(delayedOrder.slots, (slot, index) => {
            slot.id = `a-${index}`;
            slot.startTime = this.getTimeFromMins(slot.from);
            slot.endTime = this.getTimeFromMins(slot.to);
            slot.text = `${slot.startTime}-${slot.endTime}`;
            each(slot.days, day => {
                let _metaDay = res.days[day];
                if (_metaDay.enableSupply && _metaDay.supplyMode == 'slots') {
                    if (!_metaDay.slots) _metaDay.slots = [];
                    _metaDay.slots.push(slot);
                }
            });
        });

        return res;
    }

    prepareDayTimeRange(day, timeStep, min?) {
        let isFromTo = day.supplyMode == 'range_max';
        let range = day.supplyRange;
        let slots = [];
        let val = range.from;
        do {
            if (!min || val > min) {
                let excluded = find(day.stopOrderSlots, slot => {
                    return val >= slot.from && val <= slot.to;
                })
                if (!excluded) {
                    let timeText = this.getTimeFromMins(val);
                    let newSlot: any = {
                        id: `a-${val}`,
                        type: 'range',
                        from: val,
                        text: timeText
                    }
                    if (isFromTo) {
                        newSlot.to = val + timeStep;
                        newSlot.text = `${newSlot.text} - ${this.getTimeFromMins(newSlot.to)}`;
                    }
                    slots.push(newSlot);
                }
            }
            val += timeStep;

            if (isFromTo && val == range.to) {
                break;
            }
        } while (val <= range.to);
        return slots.length ? slots : null;
    }

    onFutureDateSelect(data, dt) {
        if (!dt) return;
        const day = dt.getDay();
        const metaDay = data.days[day];
        const isToday = moment(dt).isSame(data.today, 'day');

        const slots = isToday ? metaDay.todaySlots : metaDay.slots;
        if (slots) {
            each(slots, slot => {
                slot.throttled = this.throttlingService.checkFutureOrderThrottledSlots(slot, dt);
            })
            data.activeSlots = slots;
            if (data.slot) {
                data.slot = find(data.activeSlots, { id: data.slot.id });
            }
        } else {
            data.activeSlots = [{ id: 'ASAP', text: this.translate("ASAP") }];
            data.slot = data.activeSlots[0];
        }
        if (data.slot) this.onFutureSlotSelect(data, data.slot);
    }

    onFutureSlotSelect(data, slot) {
        if (!slot) return;
        if (slot.id == 'ASAP') {
            slot.date = moment(data.date).endOf('day');
            data.text = this.translate("_DELAYED.for_date", { val: `${slot.date.locale(this.appService.localeId).format('ddd D MMM')}` });
        } else {
            //check utc offset 
            // data.date contains the "start of the day" date, this offset is needed to calculate the correct time on the day in which we switched to “summer clock” or “winter clock”;
            const currentUtcOffset = moment().utcOffset();
            const dateUtcOffset = data.date ? moment.isMoment(data.date) ? data.date.utcOffset() : moment(data.date).utcOffset() : currentUtcOffset;
            let utcOffset = dateUtcOffset - currentUtcOffset;

            let from = moment(data.date).add(slot.from + utcOffset, 'minutes');
            slot.date = from.toDate();
            if (slot.type == 'range') {
                let between = slot.to ? this.translate("_DELAYED.between") : this.translate("_DELAYED.at_hour") ;
                data.text = this.translate("_DELAYED.for_date", { val: `${from.locale(this.appService.localeId).format('ddd D MMM')}, ${between} ${slot.text}` });
                if (slot.to) {
                    let to = moment(data.date).add(slot.to + utcOffset, 'minutes');
                    slot.dateTo = to.toDate();
                }
            } else {
                let to = moment(data.date).add(slot.to + utcOffset, 'minutes');
                slot.dateTo = to.toDate();
                data.text = this.translate("_DELAYED.for_date", { val: `${from.locale(this.appService.localeId).format('ddd D MMM')}, ${slot.text}` });
            }
        }
        data.slot = slot;
    }

    isTimeslotRangeActive(slot, md?) {
        if (!slot.limitToDate || !slot.dateRange) return true;
        if (!md) md = moment();
        if (slot.dateRange.startDate && md.isBefore(moment(slot.dateRange.startDate))) return false;
        if (slot.dateRange.endDate && md.isAfter(moment(slot.dateRange.endDate).endOf('day'))) return false;
        return true;
    }

    // --------------------------------------------------------------------------------------------------------------->
    // ORDER THROTTLING
    // --------------------------------------------------------------------------------------------------------------->

    setOrderTimeOffsets(site) {
        const $storage = this.dataService.$storage;
        if (!site) site = $storage.order.branch;
        $storage.addedTimeOffset = this.calculateOrderThrottling(site) || 0;
    }

    calculateOrderThrottling(site) {
        const $storage = this.dataService.$storage;
        if ($storage.futureOrder || $storage.order?.orderDelay?.date || !get(site, 'orderThrottling.enabled')) return 0;
        let offset = 0;

        switch ($storage.order.mode) {
            case "delivery":
                offset = get($storage, 'order.region.deliveryTime', 0);
                break;
            case "takeaway":
                offset = get($storage, 'order.branch.takeaway.preparationTime', 0);
                break;
        }
        let _now = this.appService.getRealDateMoment().add(offset, 'minutes');
        let _day = _now.toDate().getDay();
        let _mmtStart = _now.clone().startOf('day');
        let _nowM = _now.diff(_mmtStart, 'minutes');
        let slot = find(site.orderThrottling.slots, slot => {
            if (slot.days.indexOf(_day) != -1 && _nowM >= slot.fromTime && _nowM < slot.toTime) {
                return true;
            }
        });
        if (slot) {
            let _addTime = site.orderThrottling.timeStep;
            if (_addTime) {
                return _addTime;
            }
        }
        return 0;
    }

    // --------------------------------------------------------------------------------------------------------------->
    // translations
    // --------------------------------------------------------------------------------------------------------------->

    public translate(path: string = '', args: any = {}): string {
        let translation: any = get(this.dataService.translations, path, path);
        each(args, (val, key) => translation = translation.replace(`{{${key}}}`, val));
        return translation;
    }

    getSiteTranslation(source, att, _default) {
        if (!_default) _default = source[att];
        let catalogTrans = this.appService.localeId;
        return get(source, `translations.${catalogTrans}.${att}`, _default);
    }

    translateServerError(errorData, entity, defaultKey) {
        var errName = get(errorData, 'data.data.name');
        var key = defaultKey || "MESSAGES.SERVER_ERROR";
        if (errName) {
            key = ("MESSAGES." + (entity ? entity + "_" + errName : errName)).toUpperCase();
        }
        var translation = this.translate(key);
        return translation == key ? this.translate(defaultKey) : translation;
    }

    // ------------------------------------------------------------------------------------------------->
    // general utilities
    // ------------------------------------------------------------------------------------------------->

    alert(message, messageType: any = "error") {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => {
                this.appService.mainMessage({
                    dialogType: messageType,
                    dialogText: message,
                    hideSecondaryButton: true
                }).then(() => { resolve({}) });
            });
        });
    }

    showExFile(url) {
        //window.open(fileURL, '_blank');
        this.sharedDialogsService.toggleActionFrame('link', null, null, window['cordova'], url);
    }

    getObjectId() {
        let timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

    callSitePhone() {
        let $storage = this.dataService.$storage;
        if (window['cordova']) {
            window.open('tel:' + $storage.config.phone, '_system');
        } else {
            window.location.href = 'tel:' + $storage.config.phone;
        }
    }
    navigateToSite() {
        let $storage = this.dataService.$storage;
        let navigationDetails = {
            name: $storage.config?.name,
            address: $storage.order.branch.address,
            location: $storage.order.branch.location?.geometry.location
        }
        this.sharedDialogsService.toggleActionFrame('navigate', navigationDetails);
    }

    async setRosConfigForChainSelectedSite(site) {
        if (!site) return;
        const $storage = this.dataService.$storage;
        const chain = $storage.organization;
        const throttling = await this.throttlingService.getThrottlingSlots(site._id);
        if (throttling && !isEmpty(throttling)) $storage.throttling = throttling;

        if (chain?.sites?.length) {
            const selectedSite = chain.sites.find(chainSite => chainSite._id == site._id);
            if (selectedSite?.config) {
                $storage.rosConfig = selectedSite?.config;
                // We must cache the order again with the rosConfig
                this.dataService.cacheSessionOrder();
            }
        }
        if (this.dataService.taxRegions.indexOf(site?.local) != -1) {
            $storage.requireTax = true
        }
    }

    public getURLParams(_location?) {
        var urlParams: any = {};
        if (!_location) _location = location;
        var sSearch = _location.search.substr(1).split("&");
        each(sSearch, function (ss) {
            var _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

    getCustomURLParams(_location?, customLocationSearch?) {
        const urlParams: any = {};
        if (!_location && !customLocationSearch) _location = location;
        let sSearch = (customLocationSearch || _location.search).substr(1);
        sSearch = sSearch.split("&");
        each(sSearch, function (ss) {
            const _ss = ss.split("=");
            urlParams[_ss[0]] = _ss[1];
        });
        return urlParams;
    }

    getTextWidth(text, fontData) {
        // create a valid canvas font string
        function getCanvasFont() {
            const fontSize = fontData.fontSize || getCssStyle('font-size') || '16px';
            const fontWeight = fontData.fontWeight || getCssStyle('font-weight') || 'normal';
            const fontFamily = fontData.fontFamily || getCssStyle('font-family') || 'Times New Roman';
            return `${fontWeight} ${fontSize} ${fontFamily}`;
        }
        function getCssStyle(prop) {
            return window.getComputedStyle(document.body, null).getPropertyValue(prop);
        }
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        // measure the width of the text
        context.font = getCanvasFont();
        const { actualBoundingBoxLeft, actualBoundingBoxRight } = context.measureText(text);
        let textWidth = Math.ceil(Math.abs(actualBoundingBoxLeft) + Math.abs(actualBoundingBoxRight));
        if (text.substr(0, 1) == '*') textWidth++; // for some reason, the asterisk is not included in the width

        // prettify the result
        return textWidth;
    }

    extractTextFromHTML(elementRef) {
        let text = elementRef?.innerText;
        return text;
    }

    generateRandomString(length = Math.floor(Math.random() * 10)) {
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    filterUnavailableSite(sites, forceDelay?) {
        const $storage = this.dataService.$storage;
        if ($storage.tpOrder) return $storage.organization.branches;
        if ($storage.orderMode !== 'delivery') return sites;
        if (!sites?.length) return [];
        let forceDelayMode = $storage.forceDelay;
        if (forceDelay !== undefined && forceDelay !== null) forceDelayMode = forceDelay;
        const relevantSites = sites.filter(site => {
            const modeAtt = forceDelayMode ? 'futureActive' : 'active';
            const sMode = site[$storage.orderMode];
            if (!sMode || !sMode[modeAtt] || !sMode.enabled || !this.selectOrderMethod_check($storage.orderMode, forceDelayMode, site)) {
                return false;
            } else {
                return true;
            }
        });

        return relevantSites;
    }

    appendModelViewer(threeDImage, className) {
        if (!threeDImage || !className) return;

        const model = document.createElement('model-viewer');
        const path = `${threeDImage}/qlone.glb`;
        const iosPath = `${threeDImage}/qlone.usdz`;
        const posterPath = `${threeDImage}/poster.jpg`;

        model.setAttribute('poster', posterPath);
        model.setAttribute('src', path);
        model.setAttribute('ios-src', iosPath);
        model.setAttribute('alt', 'A 3D model created with Qlone');
        model.setAttribute('shadow-intensity', '1');
        model.setAttribute('camera-target', '0m 0m 0m');
        model.setAttribute('camera-orbit', '30deg 30deg 95%');
        model.setAttribute('max-camera-orbit', 'auto 45deg auto');
        model.setAttribute('camera-controls', '');
        // We are disabling the zoom only for IOS
        if (this.appService.platformService.IOS) model.setAttribute('disable-zoom', '');
        model.setAttribute('auto-rotate', '');
        model.setAttribute('ar', '');
        // Styling the element
        model.style.width = '100%';
        model.style.height = '100%';
        model.style.margin = '20px auto';

        $(className).append(model);
    }

    getRoomNumber() {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(ToRoomDialogComponent, {
                    width: "300px",
                    disableClose: true,
                    panelClass: 'rounded-dialog',
                    direction: this.appService.direction,
                    autoFocus: false, 
                    data: {
                        roomNumber: this.dataService.$storage?.config?.settings?.autoFillHotelRoomNumber ? this.dataService.$storage?.order?.tableNumber ?? '' : '',
                    },
                });
                dialogRef.afterClosed().subscribe(result => {
                    if (result && result != '') resolve(result);
                    else reject('Room not selected');
                });
            });
        });
    }

    maskCharacter(str, mask, n = 1) {
        // Slice the string and replace with
        // mask then add remaining string
        return ('' + str).slice(0, -n)
            .replace(/./g, mask)
            + ('' + str).slice(-n);
    }

    setBenefitActive(_offer) {
        const $$manualBenefits = this.dataService.$storage?.loyaltyMember?.$$manualBenefits;
        // Find the benefit associated with the externalSaleId of the offer
        const benefit = find($$manualBenefits, { externalSaleId: _offer.externalSaleId });
        if (benefit) {
            // Set the benefit as active
            benefit.active = true;
            // Cache the session order
            this.benefitEdited = true;
            this.calculateAvailableManualBenefitsCounter();
            this.dataService.cacheSessionOrder();
        }
    }

    getMaxManualBenefits() {
        const loyaltyMember = this.dataService.$storage?.loyaltyMember;
        return loyaltyMember?.$$maxManualBenefits || 0;
    }

    calculateAvailableManualBenefitsCounter() {
        const loyaltyData = this.dataService.$storage?.loyaltyData;
        const loyaltyMember = this.dataService?.$storage?.loyaltyMember;
        if (!loyaltyMember) return;

        loyaltyData.viewPointsStorePoints = loyaltyMember.pointsStorePoints;
        const manualBenefits = loyaltyMember?.$$manualBenefits || [];
        const activeBenefits = manualBenefits.filter(_benefit => _benefit.active && _benefit?.redeemInfo?.isUnderGeneralRedeemLimit != false);
        this.activeManualBenefits = Math.max(activeBenefits?.length, 0);
        let maxActive = (loyaltyMember?.$$maxManualBenefits || 0) - activeBenefits.length;

        const basket = this.dataService.$storage.basket;
        basket.forEach(basketOffer => {
            if (basketOffer.externalSaleId && basketOffer?.rewardDetails?.active && basketOffer.rewardDetails?.externalSaleId) {
                maxActive -= basketOffer.quantity || 1;
                this.activeManualBenefits += basketOffer.quantity || 1;
                loyaltyData.viewPointsStorePoints -= (basketOffer?.rewardDetails?.sellPrice || 0);
            }
        });
        this.availableManualBenefits = Math.max(maxActive, 0);
    }

    setOfferBenefitsReference(benefit, _offer, clone = false, cloneQuantity = 1) {
        const _id = _offer._id;
        let basketItem = clone ?
            filter(this.dataService.$storage.basket, item => item._id == _id && !item.externalSaleId && item.quantity == cloneQuantity) : 
            filter(this.dataService.$storage.basket, item => item._id == _id && !item.externalSaleId);
        if (!basketItem.length) return;
        // Add the offer ID to the selected offers of the benefit
        benefit.selectedOffers.push(_id);

        // Assign the externalSaleId of the benefit to the first matching basket item
        basketItem[0].externalSaleId = benefit.externalSaleId;

        // Cache the session order
        this.dataService.cacheSessionOrder();
    }

    prepareDeliveryData(deliverySettings, site) {
        // Assignments here, instead of getting the data at landing
        const regionSettings = deliverySettings?.find(settings => settings.id == site._id);

        return site.delivery = {
            ...site.delivery,
            regionGroups: regionSettings?.regionGroups || site.delivery.regionGroups,
            regions: regionSettings?.regions || site.delivery.regions,
        }
    }

    isPushPaymentToWallet(spm, pm) {
        if (
            // Regular validation
            spm.paymentType === pm.paymentType ||
            // CreditGuard typings..
            spm.paymentType == 'CreditGuard' && pm.paymentType == 'creditGuard' ||
            // Support old wallets
            (['creditGuard', 'CreditGuard'].includes(spm.paymentType) && pm.paymentType == 'creditCard')
        ) return true;

        return false;
    }

}
