import { Injectable, NgZone, EventEmitter, Inject, ElementRef } from '@angular/core';
import { Observable, of, BehaviorSubject, Subscription, forkJoin, timer, throwError } from 'rxjs';
import { map, catchError, take, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { DomSanitizer, Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { Location, DecimalPipe, DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

import { ToastrService } from 'ngx-toastr';
import { NgBlockUI } from 'ng-block-ui';

import { environment } from '../environments/environment';
import { SpecialMessage, SpecialMessagesService } from './_core/special-messages.service';
import { StorageService } from './_core/storage.service';
import { MessageDialogComponent } from './dialogs/message-dialog/message-dialog.component';
import { MainMessageDialogComponent, MainMessageDialogOptions } from './dialogs/main-message-dialog/main-message-dialog.component';
import { AnimationDialogComponent } from './dialogs/animation-dialog/animation-dialog.component';
import { SignInDialogComponent } from './sign-in/sign-in-dialog/sign-in-dialog.component';
import { SplashScreenDialogComponent } from './dialogs/splash-screen-dialog/splash-screen-dialog.component';
import { SearchDetails } from './_core/OrganizationBucket';
import { LoyaltyTermsDialogComponent } from './dialogs/loyalty-terms-dialog/loyalty-terms-dialog.component';
import { StyleService } from './_core/style.service';
import { LoggerService } from './_core/logger.service';
import { JoinChannelOriginType } from './_core/loyalty.service';

import allImages from '../assets/svg-icons.json';
import { get, each, merge, cloneDeep, assignIn, omit, pick, compact, omitBy, isNil, isEmpty, concat } from 'lodash-es';
import moment from 'moment-timezone';
import accessibilityDomain from '../assets/accessibility-domain-config/domain-config.json';

declare const $: any;

interface SeoData {
    title: string;
    subtitle: string;
    seo: {
        [key: string]: {
            path: string,
            metaTitle: string,
            metaDescription: string
        }
    }
}

interface InterdealConfig {
    sitekey: string;
    Position: string;
    domains: {
        js: string;
        acc: string;
    };
    Menulang: string;
    btnStyle: {
        vPosition?: string[];
        scale: string[];
        draggable?: boolean;
        color?: {
            main: string;
            second: string;
        };
        icon?: {
            outline: boolean;
            type: number;
            shape: string;
        };
    };
}

export interface Notification {
    type?: 'text' | 'simple-dialog';
    title?: string;
    orgName: string;
    message: string;
    date: Date,
}

export interface Domain {
    defaults: any;
    brand: string;
    organizationGroup: string[];
    name: string;
    hosts: any,
    multiLanguageEnabled: boolean,
    smartAppBannerEnabled: boolean,
    googleTagManagerId: string,
    signInImageBackground: boolean,
    hasCustomSignInVideo: boolean,
    signInMediaSource: string,
    clubIds: string[],
    links: any,
    translations: any,
    headerCustomTitle?: string;
    headerCustomImagePath?: string;
    showSubGroups?: boolean;
    theme?: {
        appPrimaryBackgroundColor?: string;
        appSecondaryBackgroundColor?: string;
        appSecondaryTextColor?: string;
        cardsBackgroundColor?: string;
        footerBackgroundColor?: string;
        footerFontColor?: string;
        useBrandCustomIcons?: boolean,
    },
    domainSettings?: DomainSettings
    // And more...
}

export const ACTION_TYPES = {
    PAYMENT_LINK: 'PAYMENT_LINK',
    PAYMENT_SUBMIT: 'PAYMENT_SUBMIT',
};

type DomainSettings = {
    active: boolean,
    outOfUseMessage: string,
    splashTiming: string,
}

@Injectable({
	providedIn: 'root',
})
export class AppService {
    public isProduction = environment.production;
    public appConfig: any = environment.appConfig;
    public isApp: boolean = false;
    public appStrings: any;
    public domainStrings: any;
    public landingUrlParams: any;
    public currentFrame: any = null;
    public cordovaPlatform: string;
    public landingUrl: string;
    public previousUrl: string;
    public currentUrl: string;
    public currentSiteID: string;
    public clientId: string; //ori: used for verifying non cash payment on device
    public SMSRetrieverHash: string;
    public skin: string = null;
    public joinChannelOriginType: JoinChannelOriginType;
    public upperCaseSkin: string = null;
    public dashboardInitialLoad: boolean = true;
    public isMessageDialogOpen: boolean = false;
    public paused: boolean = false;
    public webAppSmartBannerOpened: boolean = false;
    public webAppSmartBannerWatched: boolean = false;
    public confirmBeforeRedirect : boolean = false;
    public splashAlreadyDisplayed: boolean = false;
    public redirectFromAppOrSite: boolean = false; // indicates the navigation source is an internal link from TA or a restaurant site, and not an external public link
    public relocateContrastButton: boolean = false;

    public defaultServiceOrderType: 'tabitpay' | 'delivery' | 'takeaway' = 'delivery';
    public defaultServiceButtonsLoaded: boolean = false; // wait for the domain config to load before showing icons
    public orderProcessReferringRoute: {route: string, query?: any} = {route: '/home/dashboard'}; // tabit-app: keep track of the order process referring page for exact back-button functionality
    public oneTimeCacheBusting: string = this.appConfig.version.replace(/[.]/g, '_');
    public get iconsCacheBusting(): number {return Date.now()};

    // private data store
    private privateStore = {
        _user: new BehaviorSubject(<any> {}),
        _TOSideMenuOpen: new BehaviorSubject(false),
    };

    // public data store
    public readonly publicStore = {
        user$: this.privateStore._user.asObservable(),
        TOSideMenuOpen$: this.privateStore._TOSideMenuOpen.asObservable(),
        sideMenuStatus: {isOpen:false, isOpenDelay:false}
    };

    /*
    public ngLottieDefaultOptions: AnimationOptions = {
        autoplay: true,
        loop: false,
        beforeAnimationIsCreated: (player:any) => player.setLocationHref('')
    }
    */

    // Google Analytics Cross Domain Tracking Client ID (_ga)
    public googleAnalyticsTracker: any;
    public googleAnalayticsLinkerParam: string;

    public storageToken_keys: string = "tabit_co_keys";

    public appHttpOptions = {
        headers: new HttpHeaders({
            'Authorization': '',
            'Content-Type': 'application/json',
            'White-Label': ''
        }),
    };

    account: any = {
        favorites: [],
        history: [],
        loadingHistory: true,
        sitePrefs: {
            order: [],
            book: [],
            pay: []
        }
    };
    user: any = {}; // Need to be an empty object, because in auth.service and entity.service we add the loyaltyCustomer even before anything else exists in appService.user;
    userPoints: number;
    reservations: any[] = null;
    allergies: any = [];
    categories = [];
    categoriesMap: any = {};
    tagsSubscription: Subscription;

    allowsBackForwardNavigationGesturesIsEnabled: boolean = false;
    emailValidationNeeded: boolean = false;

    initStorage() {
        this.allergies = [];
        this.categories = [];
    }

    public toggleToSideMenu(): void {
        this.publicStore.TOSideMenuOpen$
            .pipe(take(1))
            .subscribe((sideMenuState) => {
                this.updatePrivateStore('TOSideMenuOpen', !sideMenuState)
            })
    }

    // Those are the names registering at Mat Icons Registry, matching the names from /assets/images/icons/*.svg
    images: {[key: string]: string} = allImages.images;
    brandServiceIcons: string[] = allImages.brandServiceIcons;
    icons: string[] = allImages.icons;

    serviceActions: any[] = [
        {
            id: 'book',
            icon: 'reserve unactive',
            iconActive: 'reserve active',
            text: 'book_action',
            color: '#FCA8A8',
        }, {
            id: 'pay',
            icon: 'tpay unactive',
            iconActive: 'tpay active',
            text: 'pay_action',
            color: '#ABE594',
        },
        {
            id: 'quickpay',
            icon: 'money_check_alt',
            iconActive: 'money_check_alt',
            text: 'quickpay_action',
            color: '#ABE594',
        }, {
            id: 'order',
            icon: 'take away unactive',
            iconActive: 'take away active',
            text: 'order_action',
            color: '#BFA9FC',
        }, {
            id: 'eatin',
            icon: 'home unactive',
            iconActive: 'home active',
            text: 'eatin',
            color: '#BFA9FC',
        },
    ];

    backIcon: string;
    nextIcon: string;
    userRankNumber: number = 8;
    ranks: any = [
        { level: 0, score: 25 },
        { level: 1, score: 50 },
        { level: 2, score: 100 },
        { level: 3, score: 200 },
        { level: 4, score: 400 },
        { level: 5, score: 800 },
        { level: 6, score: 1600 },
        { level: 7, score: 3200 }
    ]

    currency: string = '₪';
    us_currency: string = '$';
    showAppBanner: boolean = false;
    emailRegex = /^(([^א-ת<>()\[\]\\.,;:\s@"]+(\.[^א-ת<>()\[\]\\.,;:\s@"]+)*))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/i;

    public localeId: string;
    public direction: any;
    public isOriginLanguage: boolean = true;

    public starRatings: any = [5, 4, 3, 2, 1];
    public priceLevels: any = [
        { id: 3, value: '₪₪₪' },
        { id: 2, value: '₪₪' },
        { id: 1, value: '₪' }
    ];

    public loadingCoreData = new BehaviorSubject<boolean>(true);
    public loadingMetaData = new BehaviorSubject<boolean>(true);
    public isWhiteLabel = new BehaviorSubject<boolean>(false);
    public subscribedToLocationAndGotOrganizations = new BehaviorSubject<boolean>(false);
    public isPhoneResumed = new BehaviorSubject<boolean>(false);

    public directionSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this.appConfig.direction);
    public locale: BehaviorSubject<string> = new BehaviorSubject<string>(this.appConfig.locale);
    public lastSiteOpened: BehaviorSubject<string> = new BehaviorSubject(null);
    public webSeoTitle: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public benefitDetailsDataSubject: BehaviorSubject<any> = new BehaviorSubject({});

    public androidBackButton: EventEmitter<void> = new EventEmitter();
    public toMenuBackButton: EventEmitter<void> = new EventEmitter();
    public resetCurrentPage: EventEmitter<void> = new EventEmitter();
    public openLoyaltyDialog: EventEmitter<void> = new EventEmitter();
    public closeMobileBasket: EventEmitter<void> = new EventEmitter();
    // user-related events
    public userLoggedOut: EventEmitter<void> = new EventEmitter();
    public userSignedIn: EventEmitter<void> = new EventEmitter();
    // needed only due to the many instances of "user" / "user.wallet" and circular deps issues. will be obsolete upon a fix.
    public populateUserWallet: BehaviorSubject<any> = new BehaviorSubject<{}>({});

    public googlePlaceDetailsSubject: BehaviorSubject<any> = new BehaviorSubject({
        showReviews: false,
        showRating: false,
        showPrice: false
    });

    // ********************************************************************************************** //
    // The logic must be here to prevent circular dependency
    private isPushNotificationsDisabledSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    public isPushNotificationsDisabled = this.isPushNotificationsDisabledSubject.asObservable();

    private notificationsSubject = new BehaviorSubject<Notification[]>([]);
    public notifications = this.notificationsSubject.asObservable();

    private domainSubject: BehaviorSubject<Domain> = new BehaviorSubject(null);
    public domain = this.domainSubject.asObservable();
    // ********************************************************************************************** //

    public isIOS: boolean; // indicated the browser runs on ios platform
    public isPixelInitialized: boolean = false;

    private accessibilityDeclarationURL: string;
    public accessibilityScriptId = 'custom-accessibility-script';

    public serverDateDifference: number = 0;

    constructor(
        public dialog: MatDialog,
        public toastrService: ToastrService,
        public specialMessagesService: SpecialMessagesService,
        private storageService: StorageService,
        private router: Router,
        private sanitizer: DomSanitizer,
        private iconRegistry: MatIconRegistry,
        private location: Location,
        private http: HttpClient,
        private titleService: Title,
        private metaService: Meta,
        private ngZone: NgZone,
        private translateService: TranslateService,
        private _decimalPipe: DecimalPipe,
        private deviceService: DeviceDetectorService,
        private loggerService: LoggerService,
		public platformService: Platform,
        public styleService: StyleService,
        @Inject(DOCUMENT) private document: Document
    ) {
        this.locale.subscribe(locale => this.localeId = locale);

        // visual flags initialization
        this.isIOS = this.platformService.IOS || this.platformService.SAFARI;

        this.clientId = get(window, 'device.uuid') || this.storageService.getItem("TBCLIENTID"); //ori: used for verifying non cash payment on device
        if (!this.clientId) {
            this.clientId = this.getObjectId();
            this.storageService.setItem('TBCLIENTID', this.clientId);
        }

        this.directionSubject.subscribe(direction => {
            this.direction = direction;
            this.backIcon = direction == 'rtl' ? 'keyboard_arrow_right' : 'keyboard_arrow_left';
            this.nextIcon = direction == 'rtl' ? 'keyboard_arrow_left' : 'keyboard_arrow_right';
        })

        // append material's platform-detection service class
        if (this.platformService.ANDROID) {
            document.body.classList.add('_ANDROID');
        } else if (this.platformService.IOS) {
            document.body.classList.add('_IOS');
            if (this.platformService.SAFARI) document.body.classList.add('_SAFARI');
        }

        if (window['chrome']) document.body.classList.add('_CHROME');

        this.currentUrl = this.router.url;

        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (!this.landingUrl && event.url) {
                    this.landingUrl = event.url;
                    // console.log('=== router > NavigationStart - Landing URL: ', this.landingUrl, event);
                }
            };
            if (event instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = event.url;
                // console.log('router > NavigationEnd: ', event);
                this.updateAppSmartBannerURL();
                // close the side menu
                this.updatePrivateStore('TOSideMenuOpen', false);

                // 2020-02-02: Due to a bug in Safari or the QR Scanner plugin.. this is required (otherwise a background-color:transparent remains after being on the QR Code scanner view (qr-scanner))
                setTimeout(() => {
                    $('html').removeAttr('style');
                    $('body').removeAttr('style');
                }, 200);

            };
        });

        this.registerIcons(this.icons);
        this.registerIcons(this.brandServiceIcons, 'brands/tabit/'); // load the brand default icons

        each(this.ranks, (rank, index) => {
            rank.name = this.translate('RANKS.' + index);
            rank.image = this.base('assets/images/ranks/rank-' + (index + 1) + '.png');
        })

        each(this.images, (val, key) => {
            this.images[key] = this.base('assets/images/' + val);
            // new Image().src = this.images[key];
        });

        if (['en-US', 'en-AU'].includes(this.appConfig.locale)) {
            this.currency = '$';

            this.priceLevels = [
                { id: 3, value: '$$$' },
                { id: 2, value: '$$' },
                { id: 1, value: '$' }
            ];
        }

        // append mobile/desktop and platform classes to body
        this.setBodyCssPlatformClasses();

        this.generateAccessibilityMenuScript(accessibilityDomain);
    }

    // Translation methods (can't move to translation service to prevent circular dependency)
    public updateTranslations(strOverrides) {
        this.translateService.setTranslation(this.localeId, strOverrides, true);
    }

    public translate(path: string, args?, strOverrides?: any): string {
        if (strOverrides && strOverrides[this.localeId]) this.updateTranslations(strOverrides[this.localeId]);

        return this.translateService.instant(path, args);
    }

    public stopLoadingCoreData() {
        this.loadingCoreData.next(false);
    }

    public stopLoadingMetaData() {
        this.loadingMetaData.next(false);
    }

    public setDirection(dir: string) {
        this.directionSubject.next(dir);
    }

    public safariViewController(url, closeCallback?: Function) {
        if (window['SafariViewController']) {
            // Use the Safari View Controller Plugin
            window['SafariViewController'].show({
                // If the URL has Hebrew characters we must encode the URL, otherwise it won't work in the Safari Web Browser overlay
                // However, if the URL already includes special characters - we don't encode it, as it might become "double encoded" and make the URL corrupt
                url: /[א-ת]+/.test(url) ? encodeURI(url) : url,
                hidden: false,
                //animated: false,
                //transition: 'curl',
                enterReaderModeIfAvailable: false,
                tintColor: "#FFFFFF",
                barColor: "#FFFFFF",
                controlTintColor: "#333333"
            }, (result) => {
                if (result.event === 'opened') {
                    console.debug('SafariViewController Opened');
                } else if (result.event === 'loaded') {
                    console.debug('SafariViewController Loaded');
                } else if (result.event === 'closed') {
                    console.debug('SafariViewController Closed');
                    if (closeCallback) closeCallback();
                }
            });
        } else {
            // Fallback to the external system browser
            window.open(url, '_system');
        }
    }

    public updateAppSmartBannerURL() {
        if (window['cordova'] || this.isDesktop() || this.webAppSmartBannerWatched) return;
        // console.log('=== updateAppSmartBannerURL ===', this.currentUrl);
        setTimeout(() => {
            this.webAppSmartBannerOpened =  this.showAppBanner && (
                                                !/article/.test(this.currentUrl)
                                            ) ?
                                            true : false;
            console.debug('landingUrl: ' + this.landingUrl, 'currentURL: ' + this.currentUrl, 'preveiousURL: ' + this.previousUrl);

            // this.alert({ mode: 'alert', title:'app_smart_banner_title',  buttonImage: storeIcon, content: 'app_smart_banner_body', confirmText: '' }).then(response => {
            //     window.location.href = `${this.appConfig.appURLScheme}://#${encodeURI(this.landingUrl)}`;
            // }).catch(err => {
            // });

            /*
            this.toastrService.info(`<a href="${this.appConfig.appURLScheme}://#${encodeURI(this.landingUrl)}" target="_blank"><img src="${storeIcon}" />להורדת האפליקציה בחינם לחצו כאן!</a>`, null, {
                enableHTML: true,
                showCloseButton: true,
                toastTimeout: 300000,
                position: 'top-full-width',
                animate: 'slideFromTop',
            });
            */
        }, 200);

        /*
        if (!$('meta[name="apple-itunes-app"]').length) {
            $('head').append('<meta name="apple-itunes-app" content="app-id='+this.appConfig.appleID+', app-argument=">');
        }

        let content = $('meta[name="apple-itunes-app"]').attr('content');
        content = content.replace(/app-argument=(.*)$/, 'app-argument='+this.appConfig.appURLScheme+'://#'+encodeURI(this.currentUrl));
        $('meta[name="apple-itunes-app"]').attr('content', content);
        //console.log($('meta[name="apple-itunes-app"]')[0]);
        */
    }

    public redirect(routerArray: any, params?: any): Promise<boolean> {
        console.debug('=== redirect: ', routerArray, params);
        this.closeAllToasts();
        return this.router.navigate(routerArray, params);
    }

    public getRedirectUrl(routerArray: any, params?: any): string {
        console.debug('=== getRedirectUrl: ', routerArray, params);
        this.closeAllToasts();

        // Add query parameters if provided
        const queryParams = params?.queryParams ? new URLSearchParams(params.queryParams).toString() : '';

        // Combine the base URL and query parameters
        const fullUrl = queryParams ? `${this.base(routerArray[0], false)}?${queryParams}` : this.base(routerArray[0], false);

        console.debug('Constructed URL: ', fullUrl);

        // Return the plain URL
        return fullUrl;
    }


    public setAccessibilityFeatures(features) {
        try {
            this.storageService.setItem('accessibility_features', JSON.stringify(features));
        } catch (error) {}
    }

    public getAccessibilityFeatures() {
        try {
            return JSON.parse(this.storageService.getItem('accessibility_features'));
        } catch (error) {
            return [];
        }
    }

    public convertQueryStringToQueryParams(queryString: string): Object {
        if (!queryString) return;

        // In case we got a URL instead of a queryString we prepare it

        let urlParts: Array<string> = queryString.split('?');
        let paramPairs: Array<string> = urlParts[1] ? urlParts[1].split('&') : urlParts[0].split('&');
        let queryParams: Object = {};

        paramPairs.map(paramPair => paramPair.split('=')).forEach(paramPairArr => {
            if (paramPairArr.length < 2) return;
            queryParams[paramPairArr[0]] = paramPairArr[1];
        });

        return queryParams;
    }

    public goBack() {

        if ([ '/', '/home', '/home/dashboard'].includes(this.currentUrl)) return;

        // * on back event, redirect to dashboard if:
        // 1. user is in a service-type menu selection
        // 2. after navigation from "tabit-order?site=austin" to "app-site/austin"
        // 3. when navigation back after login
        // 4. when in RSV final step/management - go back to home page
        if  (
            this.currentUrl.includes('/order?service-type=') ||
            (this.currentUrl.includes('/app-site/') && this.previousUrl.includes('/tabit-order?site=')) ||
            (this.previousUrl == '/sign-in' && this.isAuthUser) ||
            (this.currentUrl.includes(`${this.getTranslatedRoute('reservation')}/management`) || this.currentUrl.includes('step=summary')) ||
            (this.currentUrl.includes('/create-card') && this.currentUrl.includes('step=0')) ||
            this.currentUrl.includes('/pay/scanner')
        ) {
            this.redirect([window['cordova'] ? '/home/dashboard' : '/']);
        } else if (this.previousUrl && this.previousUrl != '/') {
            this.location.back();
        } else if (window['cordova']) {
            this.redirect(['/home/dashboard']);
        } else {
            if (this.confirmBeforeRedirect) {
                return this.mainMessage({
                    dialogType: 'info',
                    dialogTitle: 'in_progress_message',
                    dialogText: 'confirm_exit',
                    primaryButtonText: 'cancel_order',
                    secondaryButtonText: 'continue'
                }).then(response => {
                    this.redirect(['/']);
                }).catch(err => {});
            } else this.redirect(['/']);
        }
        // When redirecting - we close aall Toasts
        this.closeAllToasts();

        // The simpler way, working on browswers:
        // =========================================================================
        // this.ngZone.run(() => {
        //     this.location.back(); // Doesn't work in Cordova
        // });

        // We need all of this to support cordova which is against "location.back()"
        // =========================================================================
        /*
        if (this.previousUrl.match(/;/)) {

            let urlChunks = this.previousUrl.split(';');
            let url = urlChunks.splice(0, 1)[0];
            let urlParams = {};
            urlChunks.map(paramPair => paramPair.split('=')).forEach(paramPairArr => {
                if (paramPairArr.length < 2) return;
                urlParams[paramPairArr[0]] = paramPairArr[1];
            });
            this.redirect([url, urlParams]);

        } else if (this.previousUrl.match(/\?.+/)) {

            let urlSides = this.previousUrl.split(/\?/);
            let url = urlSides.splice(0, 1)[0];
            let paramPairs = urlSides[0].split(/&/);
            let urlParams = {};
            paramPairs.map(paramPair => paramPair.split('=')).forEach(paramPairArr => {
                if (paramPairArr.length < 2) return;
                urlParams[paramPairArr[0]] = paramPairArr[1];
            });
            this.redirect([url], { queryParams: urlParams });

        } else {

            this.redirect([this.previousUrl]);

        }
        */
    }

    public appSidenav: MatSidenav;
    public blockUI: NgBlockUI;
    public setAppSidenav(appSidenav: MatSidenav) {
        this.appSidenav = appSidenav;
    }
    public toggleAppSidenav(): Promise<any> {
        return this.appSidenav.toggle();
    }

    public getErrorText(err, _default?) {
        let message;// = err.message;
        if (err.code) {
            message = this.getErrorCodeTrans(err.code);
        }
        if (!message) message = _default ? this.translate(_default) : err.message;
        return message;

    }

    public getErrorCodeTrans(code) {
        return this.translateService.getTranslation(this.localeId).subscribe(translations => {
            return translations._CODES[code];
        })
    }

    public notificationsGetValue(slice: boolean) {
        return slice ? this.notificationsSubject.getValue().slice() : this.notificationsSubject.getValue();
    }

    public setNotificationsSubject(notifications) {
        this.notificationsSubject.next(notifications);
    }

    public deleteAllNotifications() {
        this.notificationsSubject.next([]);
    }

    public base(path: string, cacheBusting: boolean = true): string {
        let host = this.appConfig.host;
        if (!host) return path;
        this.appConfig.host = host.replace(/\/$/, '') + '/';
        path = path.replace(/^\//, '');

        const optionalCacheBusting = cacheBusting ? `?v=${this.oneTimeCacheBusting}` : '';

        return this.appConfig.host + path + optionalCacheBusting;
    }

    /*
    public ngLottieOptions(options:any) {
        return {...options, beforeAnimationIsCreated: (player:any) => {
            player.setLocationHref('');
        }}
    }
    */

    public handleHttpError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            console.error(error); // log to console instead
            this.signOut();

            return of(result as T);
        };
    }

    public signOut(confirm?, deletedUser?): void {
        if (confirm) {
            this.mainMessage({
                dialogType: 'info',
                dialogText: 'MESSAGES.CONFIRM_SIGNOUT',
                primaryButtonText: 'CONFIRM_SIGN_OUT',
            }).then((res) => {
                this.signOut();
            }).catch(err => { });
            return;
        }

        this.clearLocalStorage(deletedUser);
        this.clearSessionStorage(true);
        this.deleteAllNotifications();

        this.user = null;
        this.updatePrivateStore('user', null);

        // we need to clear the payment page after user signs out
        this.userLoggedOut.emit();

        if (/tabit-order/.test(this.currentUrl) && !window['cordova']) return;

        if (deletedUser) {
            this.redirect(['/home'])
            return;
        };

        this.stopBlock();
        // if (/tabit-order/.test(this.currentUrl) && !window['cordova']) return this.location.back();
        this.redirect(['/sign-in']);
    }

    restoreKEY(key) {
        let keys = JSON.parse(localStorage.getItem(this.storageToken_keys)) || {};
        return keys[key];
    };

    cacheKEY(key, val) {
        let keys = JSON.parse(localStorage.getItem(this.storageToken_keys)) || {};
        keys[key] = val;
        this.storageService.setItem(this.storageToken_keys, JSON.stringify(keys));
    };
    // // We've changed the messages to work solely from the mainMessage method
    // public message(settings, args?) {
    //     return this.messageDialog(assignIn(settings, args));
    // }
    // public confirm(args?) {
    //     return this.messageDialog(assignIn({ mode: 'confirm' }, args));
    // }
    // public alert(args?) {
    //     return this.messageDialog(assignIn({ mode: 'alert', title: 'error_title', content: null }, args));
    // }
    // For TabitPay
    public prompt(args?) {
        return this.messageDialog(assignIn({ mode: 'prompt', width: "400px", title: 'please_enter_value', content: null, label: 'please_enter_value', panelClass: '' }, args));
    }
    // For TabitPay
    public getSignature(args?) {
        return this.messageDialog(assignIn({ mode: 'signature', width: "300px", title: 'please_enter_signature', content: null, label: 'please_enter_signature', panelClass: '' }, args));
    }

    public openSigninDialog(args?) {
        return this.signInDialog(args);
    }

    public mainMessage(args: MainMessageDialogOptions = {}) {
        return this.mainMessageDialog(args);
    }

    public mainMessageDialog(args: MainMessageDialogOptions = {}) {
        // merge the importProcess into default settings
        return new Promise((resolve, reject) => {
            console.debug('Message Dialog Opened');
            this.isMessageDialogOpen = true;
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogData: MainMessageDialogOptions = merge({
                    dialogType: args.dialogType,
                    dialogTitle: 'please_confirm',
                    dialogText: 'are_you_sure',
                    primaryButtonText: 'OK',
                    primaryButtonActions: {},
                    secondaryButtonText: 'Cancel',
                    secondaryButtonActions: {},
                    hideXIcon: true,
                    disableAutoFocus: false,
                    buttonsLayout: 'column',
                    secondaryButtonAppearance: 'anchor',
                }, args);

                const panelClass = ['wl-cards-background-color', 'main-message-dialog-container'];
                if (!dialogData.hideXIcon) panelClass.push('x-button-visible');

                const dialogRef = this.dialog.open(MainMessageDialogComponent, {
                    panelClass,
                    data: dialogData,
                    position: {
                        top: '8rem'
                    },
                    autoFocus: !dialogData.disableAutoFocus,
                    disableClose: true
                });

                this.centerDialogContainer(dialogRef);

                dialogRef.afterClosed()
                .subscribe(result => {
                    console.debug('Message Dialog Closed');
                    this.isMessageDialogOpen = false;

                    if (result?.primaryButtonClick) {
                        resolve(result);
                    // Close button clicked
                    } else {
                        reject(result);
                    }
                });
            })
        })
    }

    // center material dialog on the screen
    public centerDialogContainer(dialogRef): void {
        // Safely access deeply nested properties using optional chaining
        const overlayEl = dialogRef?._ref?.overlayRef?.overlayElement;
        if (overlayEl && overlayEl.parentElement) {
            // Add a class to center the dialog container
            overlayEl.parentElement.classList.add('center-dialog-container');
            // Move backdrop back into the wrapper container, if there's a previous sibling
            const parentElement = overlayEl.parentElement;
            const backdrop = parentElement.previousElementSibling;
            if (backdrop) {
                parentElement.insertBefore(backdrop, overlayEl);
            }
        }
    }

    public messageDialog(args?) {
        const base = assignIn({ mode: 'confirm', width: "300px", cancelText: 'Cancel', confirmText: 'OK', title: 'please_confirm', content: 'are_you_sure', panelClass:'' }, args);
        return new Promise((resolve, reject) => {
            console.debug('Message Dialog Opened');
            this.isMessageDialogOpen = true;
            let panelClass = ['wl-cards-background-color', 'rounded-dialog'];
            if (base?.panelClass) panelClass.push(base.panelClass);
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                const dialogRef = this.dialog.open(MessageDialogComponent, {
                    width: base.width,
                    maxWidth: '90vw',
                    direction: this.direction,
                    disableClose: !!args?.disableClose,
                    panelClass,
                    data: base,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    console.debug('Message Dialog Closed');
                    this.isMessageDialogOpen = false;

                    if (result && result != "") {
                        resolve(result);
                    } else {
                        reject(null);
                    }
                });
            });
        });
    }

    public signInDialog(args?) {
        return new Promise((resolve, reject) => {
            console.debug('SignIn Dialog Opened');
            this.isMessageDialogOpen = true;
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(SignInDialogComponent, {
                    direction: this.direction,
                    width: '320px',
                    disableClose: true,
                    autoFocus: false,
                    panelClass: 'sign-in-dialog-container',
                    data: args,
                    position: {
                        top: '4rem'
                    },
                });

                this.centerDialogContainer(dialogRef);

                dialogRef.afterClosed().subscribe(result => {
                    this.isMessageDialogOpen = false;
                    resolve(result);
                });
            });
        });
    }

    public animDialog(args) {
        let data = assignIn({ mode: 'alert', width: "300px", cancelText: 'Cancel', confirmText: 'OK', title: 'please_confirm', content: null }, args);
        if (data.darkThemeWithCenteredText) {
            data.panelClass = 'animation-dialog-dark';
            data.backdropClass = 'dialog-backdrop-dark';
        }
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(AnimationDialogComponent, {
                    width: data.width,
                    backdropClass: data.backdropClass,
                    panelClass: data.panelClass || 'animation-dialog',
                    data: data,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    resolve(result);
                });
            });
        });
    }

    public splashScreensDialog(image) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef = this.dialog.open(SplashScreenDialogComponent, {
                    width: '300px',
                    // backdropClass: data.backdropClass,
                    panelClass: 'splash-screen-dialog',
                    data: image,
                    autoFocus: false
                });
                dialogRef.afterClosed().subscribe(result => {
                    resolve(result);
                });
            });
        });
    }

    public toastr(message, args?) {
        const options = assignIn({ positionClass: 'toast-bottom-full-width', enableHtml: true }, args);
        message = this.translate(message);

        switch (options.type) {
            case "success":
                this.toastrService.success(message, options.title, options);
                break;
            case "error":
                this.toastrService.error(message, options.title, options);
                break;
            case "warning":
                this.toastrService.warning(message, options.title, options);
                break;
            default:
                this.toastrService.info(message, options.title, options);
        }
    }

    public popSpecialMessage(message, type: SpecialMessage['type'] = 'plain', remove, timer) {
        if (!message) return;
        let specialMessage: SpecialMessage = { message: this.translate(message), type, remove };
        this.specialMessagesService.popSpecialMessage(specialMessage, timer);
    }

    public callPhone(tel) {
        let telURL = `tel:${tel}`;
        if (window['cordova']) {
            window.open(telURL, '_system');
        } else {
            window.location.href = telURL;
        }
    }

    public closeAllToasts() {
        this.toastrService.clear();
    }

    public scrollToTop(jelement?, delay = 300) {
        $(jelement).animate({ scrollTop: "0px" }, delay);
        if (window.scrollY > 0) {
            setTimeout(() => {
                $(jelement).animate({ scrollTop: "0px" }, delay);
            }, 300);
        }
    }

    public scrollToElement(jelement) {
            if ($(jelement).first()[0]) $(jelement).first()[0].scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
    }

    public scrollToNextInternalElement(wrapperElement :ElementRef, internalClassName: string): void {
        const scrollableElement = wrapperElement.nativeElement;
    
        // Get all elements with the class 'to-tbl-extras-row'
        const rows = Array.from(
            scrollableElement.querySelectorAll(internalClassName)
        ) as HTMLElement[]; // Explicitly cast to HTMLElement[]
    
        // Find the first row that is not fully visible and below the current scroll position
        const nextInvisibleRowTop = rows.reduce((nextTop, row) => {
            const rect = row.getBoundingClientRect();
            const scrollableRect = scrollableElement.getBoundingClientRect();
    
            // Check if the row is partially or fully outside the viewport
            if (rect.bottom > scrollableRect.bottom) {
                const offsetTop = row.offsetTop; // Position of the row relative to the scrollable element
                if (nextTop === 0 || offsetTop < nextTop) {
                    return offsetTop;
                }
            }
            return nextTop;
        }, 0);
    
        // Scroll to the next invisible row if it exists
        if (nextInvisibleRowTop > 0) {
            scrollableElement.scrollTo({
                top: nextInvisibleRowTop,
                behavior: 'smooth',
            });
        } else {
            // If no invisible rows are found, scroll to the bottom
            scrollableElement.scrollTo({
                top: scrollableElement.scrollHeight,
                behavior: 'smooth',
            });
        }
    }

    startBlock(args: any = {}) {
        if (args.class) $("body").addClass(args.class);
        if (args.text) args.text = this.translate(args.text);
        this.blockUI.start(args.text)
    }

    stopBlock(args: any = {}) {
        if (args.class) $("body").removeClass(args.class);
        this.blockUI.stop();
    }

    stopAllBlocks() {
        this.blockUI.reset();
    }

    //-------------------------------------------------------->
    // time calculations
    //-------------------------------------------------------->

    public calcServerDateDiff(
        clientDate,
        serverDate,
        timezone: string | undefined = undefined,
        silent: boolean = false,
        shouldCheckLocalStorage: boolean = false
    ): number | undefined {
        // Early check if serverDate is not provided
        if (!serverDate) {
            return undefined;
        }

        // Check Local Storage if required
        if (shouldCheckLocalStorage) {
            const existingValue = localStorage.getItem('orderTimeDifference');
            if (existingValue !== null) {
                this.serverDateDifference = Number(existingValue);
                return this.serverDateDifference;
            }
        }

        // Handle timezone difference if provided
        if (timezone) {
            this.checkServerTimeClientDiff(clientDate, serverDate, timezone, silent);
            return this.serverDateDifference;
        }

        // Calculate simple time difference if no timezone is provided
        this.serverDateDifference = new Date(serverDate).valueOf() - clientDate.valueOf();
        return this.serverDateDifference;
    }

    public getTimeDifference(clientDate, serverDate, timezone) {
        let timeDifference = (serverDate - clientDate) / (1000 * 60);

        // Get the current time in the server's time zone
        const serverTimeUtcOffset = moment().tz(timezone).utcOffset();

        // Get the current time in the client's time zone
        const clientTimeUtcOffset = moment().tz(Intl.DateTimeFormat().resolvedOptions().timeZone).utcOffset();

        // Calculate the time difference
        // Account for the time zone difference between the United States and France
        timeDifference += (serverTimeUtcOffset - clientTimeUtcOffset);

        return timeDifference;
    }

    public checkTimeDifferenceChange(clientDate, serverDate, timezone) {
        let timeDifference = this.getTimeDifference(clientDate, serverDate, timezone);
        const serverDateDiffInMinutes = (this.serverDateDifference ?? 0) / (1000 * 60);
        const timeDiff = serverDateDiffInMinutes - timeDifference;
        return this.hasTimeDiff(timeDiff);
    }

    public checkServerTimeClientDiff(clientDate, serverDate, timezone, silent) {
        const timeDifference = this.getTimeDifference(clientDate, serverDate, timezone);
        this.serverDateDifference = (timeDifference * 1000 * 60);

        // If the server and client clock are in a different timezone or are significantly apart - show a warning
        // 5 Minutes difference
        if (!silent) this.showTimeDiffMessage(timeDifference);

    }

    public hasTimeDiff(diff: number): boolean {
        const THRESHOLD = 5;
        return Math.abs(diff) > THRESHOLD;
    }
    
    public showTimeDiffMessage(timeDifference: number) {
        return new Promise((resolve) => {
            if (!this.hasTimeDiff(timeDifference)) {
                resolve({}); // No significant time difference
                return;
            }
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.mainMessage({
                dialogType: 'error',
                dialogTitle: 'server_time_client_diff_dialog.title',
                dialogText: this.translate('server_time_client_diff_dialog.content', {time_diff: this.formatDuration(Math.abs(timeDifference))}),
                primaryButtonText: 'server_time_client_diff_dialog.ok_button',
                hideSecondaryButton: true
            }).then(response => {
                resolve(response);
            }).catch(err => {
                resolve(err);
            });
        });
    });
    }

    public formatDuration(duration) {
        if (!duration) return;

        let formattedDuration = null;
        let hoursOnlyFormat = 'h [' + this.translate('hours') + ']';
        let hoursAndMinutesFormat = 'h [' + this.translate('hours') + '] m [' + this.translate('minutes') + ']';

        if (Math.round(duration) % 60 == 0) {
            formattedDuration = moment.duration(duration, 'minutes').format(hoursOnlyFormat);
        } else {
            formattedDuration = moment.duration(duration, 'minutes').format(hoursAndMinutesFormat);
        }

        return formattedDuration;
    };

    public getRealDate() {// ori: need to return the server date
        if (this.loadingCoreData.getValue()) console.error('Calling getRealDate() before server time finished loading');
        var dif = this.serverDateDifference || 0;
        var d = new Date().valueOf() + dif;
        return new Date(d);
    }

    public getRealDateMoment() {
        return moment(this.getRealDate());
    }

    public daysMap: any = ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"];

    public isTimeslotsActive(timeslot, dayOp = 0) {
        let md: moment.Moment = this.getRealDateMoment();
        let d = md.day() + dayOp;
        if (d > 6) {
            d = (d % 6) - 1;
        }

        let dayM = 24 * 60;
        let startThreshhold = 5 * 60;

        let cmd = getMinutes(md);
        if (cmd < startThreshhold) {
            d -= 1;
            if (d < 0) d = 6;
            cmd += dayM;
        }
        if (dayOp) cmd = startThreshhold;

        var day: any = timeslot[this.daysMap[d]];
        if (!day.active) return null;
        if (day.default) day = timeslot.default;

        let slotFound, slotNext;

        for (let i = 0; i < day.timeSlots.length; i++) {
            let slot = day.timeSlots[i];
            if (!slot.prepared) {
                slot.prepared = true;
                slot.mFrom = getMinutesString(slot.from);
                slot.mTo = getMinutesString(slot.to);
                if (slot.mTo < slot.mFrom) {
                    slot.mTo += dayM;
                } else if (slot.mFrom < startThreshhold) {
                    slot.mFrom += dayM;
                    slot.mTo += dayM;
                }
                slot.text = slot.from + " - " + slot.to;
            }
            if (cmd < slot.mFrom) {
                slotNext = slot;
                break;
            } else if (cmd >= slot.mFrom && cmd <= slot.mTo) {
                slotFound = slot;
                break;
            }
        }
        if (!slotFound && !slotNext && dayOp < 6) {
            return this.isTimeslotsActive(timeslot, ++dayOp);
        }

        if (slotFound && dayOp > 1) {
            return { slotNext: slotFound }
        }
        return {
            slotFound: slotFound,
            slotNext: slotNext
        }

        function getMinutes(m) {
            return (m.hours() * 60) + m.minutes();
        }

        function getMinutesString(s) {
            let arr: any = s.split(":");
            return Number(arr[0]) * 60 + Number(arr[1]);
        }

    };

    removeCordovaWrapperSplash() {
        // Hiding the Splash Screen
        const splash = document.querySelector('#splash');
        if (!splash) return;

        splash.classList.add('transition');
        setTimeout(() => {
            splash.remove();
            console.debug('=== Cordova splash screen closed ===');
        }, 600); // Delay required for the animation to finish
    }

    setStatusBarStyle(style: string): void {
        if (window['StatusBar']) {
            if (style == 'dark') {
                window['StatusBar'].styleDefault();
            } else {
                window['StatusBar'].styleLightContent();
            }
        }
    }

    public isReservationRelevant(reservation) {
        if (!reservation.organization || reservation.type == 'walked_in' || !reservation.reservation_details?.reserved_from) return false;
        return moment(reservation.reservation_details.reserved_from).isAfter(moment().subtract(1, 'hour'));
    }

    public shareWidgetExists() {
        return !!(window['plugins'] && window['plugins'].socialsharing);
    }

    public shareWidget(overrideUrl?: string, overrideMessage?: string) {
        // https://github.com/EddyVerbruggen/SocialSharing-PhoneGap-Plugin
        if (!(window['plugins'] && window['plugins'].socialsharing)) return;

        // Note:
        // Note: Since we have two different site-details, and we want to share the Web version one - we replace the current URL from /app-site to /site
        const sharedURL = !overrideUrl ? this.appConfig.tabitWebURL.replace(/\/$/, '') + this.currentUrl.replace('/app-site/', '/site/') : overrideUrl;

        console.debug('shareWidget > currentURL: ', this.currentUrl);
        console.debug('shareWidget > sharedURL: ', sharedURL);

        window['plugins'].socialsharing.shareWithOptions({
            url: sharedURL,
            subject: 'Tabit App', // fi. for email
            message: !overrideMessage ? this.translate('share_this') : overrideMessage, // not supported on some apps (Facebook, Instagram)
            //files: ['', ''], // an array of filenames either locally or remotely
            //chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
            //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
            //iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
        }, (success) => {
            console.debug('Share Widget - Success:', success);
        }, (error) => {
            console.debug('Share Widget - Error:', error);
        });
    }

    public getObjectId() { //ori: return a unique new mong object id
        const timestamp = (new Date().getTime() / 1000 | 0).toString(16);
        return timestamp + 'xxxxxxxxxxxxxxxx'.replace(/[x]/g, () => {
            return (Math.random() * 16 | 0).toString(16);
        }).toLowerCase();
    }

    public detectDevice(): string {
        // 768px width and above is considered desktop (iPad Portrait, which is 768px width should behave like Desktop)
        // Up to 767px width is considered Mobile
        // Landscape orientation is also considered desktop, as long as the width is less than 1024 (iPad landscape is 1024px width; iPhone in landscape mode is 896px)

        if (
            window.innerWidth < 768 ||
            (typeof window.orientation != 'undefined' && window.orientation != 0 && window.innerWidth < 1024)
        ) {
            // console.log('=== AppService - Detect Device: Mobile');
            return 'mobile';
        } else {
            // console.log('=== AppService - Detect Device: Desktop');
            return 'desktop';
        }
    }

    public isDesktop(): boolean {
        return this.detectDevice() == 'desktop';
    }

    public isMobile(): boolean {
        return this.detectDevice() == 'mobile';
    }

    public isMobileWeb(): boolean {
        return this.isMobile && !window['cordova'];
    }

    public isMobileLandscape(): boolean {
        if (window.orientation != 0 && window.innerWidth < 1024) {
            return true;
        } else {
            return false;
        }
    }
    public isTablet(): boolean {
        if (window.innerWidth >= 768 && window.innerWidth < 1024) {
            return true;
        } else {
            return false;
        }
    }

    public isSafari(): boolean {
        if (/Safari/.test(navigator.userAgent) && /Apple Computer/.test(navigator.vendor)) {
            return true;
        } else {
            return false;
        }
    }

    public isAuthUser(): boolean {
        return !!(this.user?.loyaltyCustomer);
    }

    public sendLogger(args: { message?: string, durationInSeconds?: number, tracker?: any, tabitOrder?: any, auditData?: any, giftCard?:any, payment?: any, tabitPay?: any, module?: string, user?: any, walletPayment?: any}) {
        const additionalData = {
            user: this.user,
            realDateISOString: this.getRealDate().toISOString(),
            realDate: this.getRealDate(),
            clientId: this.clientId,
            appHttpOptions: this.appHttpOptions
        };
        this.loggerService.sendLog(args, additionalData);
    }

    private clearLocalStorage(deletedUser) {
        this.storageService.setItem('isTimeDifference', '');
        this.storageService.setItem('token', '');
        this.storageService.setItem('refreshToken', '');
        this.storageService.setItem('loyaltyToken', '');
        this.storageService.setItem('loyaltyRefreshToken', '');
        this.storageService.setItem('state__recentOrders', '[]');
        this.storageService.setItem('state__futureReservations', '[]');
        this.storageService.setItem('state__sitesEvents', '[]');
        this.storageService.setItem('state__clubsData', '[]');

        if (deletedUser) {
            this.storageService.setItem('historyLoadType', '');
            this.storageService.setItem('cachedAddresses', '');
        }
    }

    public clearSessionStorage(deleteOrderCustomerOnly?: boolean) {
        // Prevent any saving session data for the App
        // we don't need persistancy here
        if (this.isApp) return;
        // try to delete the order-customer that's saved in the session storage (under the 'tos_order_[siteId]' key)
        if (deleteOrderCustomerOnly) {
            try {
                const siteId = this.convertQueryStringToQueryParams(this.currentUrl)['site'];
                const sessionOrder = JSON.parse(sessionStorage.getItem(`tos_order_${siteId}`));

                if (sessionOrder) {
                    // delete user-specific data
                    if (sessionOrder.order) sessionOrder.order.customer = {};
                    sessionOrder.wallet = null;
                    sessionOrder.walletPms = null;
                    // update the sessionStorage data
                    if (sessionStorage) sessionStorage.setItem(`tos_order_${siteId}`, JSON.stringify(sessionOrder));
                }
                // delete user wallet from service storage
                if (this.user) {
                    this.user.wallet = null;
                }
            } catch (error) {
                console.debug(`clearSessionStorage error:`, error);
            }

        } else {
            // If we would add another usage of session-storage, please add logic
            // we would need to delete only 'tos_order_' items for Tabit Order
            sessionStorage.clear();
        }
    }

    public getPage$(urlIdentifier: String): Observable<any> {
        return this.http.get<SeoData>(`${this.appConfig.tabitBridge}/page/` + urlIdentifier, { observe: 'response' });
    }

    public urlIdentifierRedirectFromError(err: HttpErrorResponse) {
        let urlIdetntifier = err.headers.get('bridge-redirect');
        if (urlIdetntifier && err.status === 400) return decodeURIComponent(urlIdetntifier);
        return null;
    }

    public getServiceAvailableTimestamp(site: any, service: SearchDetails['service'], availability = 'availability'): number | null {
        let timestamp = parseInt(get(site, `servicesInDetails.${service}.${availability}.availabilitySlotBeginAt`));
        return timestamp ? timestamp : null;
    }

    public isPreorderAvailableNow(site: any, service: any, availability = 'availability'): boolean {
        if (!get(site, `servicesInDetails.${service}.${availability}.available`)) return false;
        if (!get(site, `servicesInDetails.${service}.active`)) return false;
        let timestamp = this.getServiceAvailableTimestamp(site, service, availability);
        if (!timestamp) return false;
        return (Date.now() - timestamp) < 0;
    }

    public getStartFromTime(site: any, service: any, availability = 'availability') {

        // If site is avaiable, the start time in the past is not interesting:
        if (availability == 'availability' && get(site, `servicesInDetails.${service}.${availability}.available`)) return null;

            // If non avaiablility reason is the lack of delivery for your region,, start time is not relevant:
        if (service === 'delivery' && !get(site, `servicesInDetails.delivery.${availability}.deliveryRegion`)) return null;

        // Not showing in case of errors
        let timestamp = this.getServiceAvailableTimestamp(site, service, availability);
        if (!timestamp) return null;

        // Not showing in case of errors
        let beginTime = moment(timestamp);
        if (!beginTime.isValid()) return null;

        // If the begin time is tommorow, start time is not relevant:
        if (moment().day() !== moment(beginTime).day()) return null; // The "notAvailableBadge" will shown for that case

        return new Date(timestamp);
    }

    setSeoData(data: any) {
        this.titleService.setTitle(data.seo[this.appConfig.locale.toLocaleLowerCase()].metaTitle);
        if (data.seo[this.appConfig.locale.toLowerCase()].metaDescription) {
            this.metaService.updateTag(
                { name: 'description', content: data.seo[this.appConfig.locale.toLocaleLowerCase()].metaDescription },
                `name='description'`
            );
        }
    }

    prepareTagsWithoutOccasions(tags: any) {
        each(tags, tag => {
            if (typeof tag.image == 'undefined' || tag.image == '') tag.image = this.images.default_cat_image;
        });

        return tags?.filter(tag => tag.type != 'occasions');
    }

    getTranslatedRoute(route) {
        if (route) {
            return this.redirectValueByLocale(route, 'ROUTE') || '/';
        } else return '/';
    }

    redirectValueByLocale(redirectValue, prefix?: string) {
        const translatedValue = prefix ? this.translate(prefix + '.' + redirectValue) : this.translate(redirectValue);
        return '/' + translatedValue || '/';
    }

    setIsPushNotificationsDisabled(disabeld: boolean) {
        this.isPushNotificationsDisabledSubject.next(disabeld);
    }

    addSpecificSiteGTM(googleTagManagerId: string) {
        const script = document.getElementById(googleTagManagerId);
        if (script) {
            return;
        }

        try {
            window['dataLayer'] = window['dataLayer'] || [];
            window['dataLayer'].push({
                'gtm.start': new Date().getTime(),
                event:'gtm.js'
            });

            const firstScript = document.getElementsByTagName('script')[0];
            const gtmScript = document.createElement('script');
            gtmScript.async = true;
            gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=${googleTagManagerId}&l=dataLayer`;
            gtmScript.id = googleTagManagerId;
            firstScript.parentNode.insertBefore(gtmScript, firstScript);
        } catch(err) {
            console.debug('App-Service | Error loading addSpecificSiteGTM');
        }

    }

    addSpecificSiteGA(measurementId: string) {
        // Based on Google's GA4 documentation, we can use the same gtag.js script for multiple GA4 properties.
        // https://developers.google.com/analytics/devguides/collection/ga4/reference/config
        // Also note that Google recommend to use the SAME datalayer for all GTM/GA4 properties.

        const script = document.getElementById(measurementId);

        // If no gtag script exists yet - we add it
        if (!script) {
            // Official gtag installation instructions example:
            // <!-- Google tag (gtag.js) -->
            // <script async src="https://www.googletagmanager.com/gtag/js?id={{measurementId}}"></script>
            // <script>
            // window.dataLayer = window.dataLayer || [];
            // function gtag(){dataLayer.push(arguments);}
            // gtag('js', new Date());

            // gtag('config', '{{measurementId}}');
            // </script>

            try {
                const gtagScript: HTMLScriptElement = document.createElement('script');
                gtagScript.async = true;
                gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
                gtagScript.id = measurementId;
                document.head.append(gtagScript);

                window['dataLayer'] = window['dataLayer'] || [];
                const gtagFunctionScript: HTMLScriptElement = document.createElement('script');
                gtagFunctionScript.innerHTML = 'function gtag(){dataLayer.push(arguments)};';
                document.head.append(gtagFunctionScript);

                window['gtag']('js', new Date());
            } catch(err) {
                console.debug('App-Service | Error loading addSpecificSiteGA');
            }
        }

        // Adding our specific site GA4 property
        window['gtag']('config', measurementId, { send_page_view: false });
        console.debug(`GA4 Configuration added for: ${measurementId}`);
    }

    addSpecificSiteFBPixel(pixelId: string) {
        if (!pixelId || this.isPixelInitialized) return;
        window['fbq']('init', pixelId);

        const documentId = `fbk-${pixelId}`;
        const isScriptAlreadyInitialized = !!document.getElementById(documentId);

        if (!isScriptAlreadyInitialized) {
            // Adding facebook image
            const noscript = document.createElement('noscript');
            noscript.setAttribute("id", documentId);
            const img = document.createElement('img')
            img.setAttribute('src', `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`)
            img.setAttribute("height", "1")
            img.setAttribute("width", "1")
            img.setAttribute("style", "display:none;visibility:hidden")
            noscript.appendChild(img)
            document.body.appendChild(noscript)
        }

        this.isPixelInitialized = true;
        console.debug('Facebook Pixel initialized with ID: ', pixelId);
    }

    validateLocalStorageData(dataName: string) {
        let localStorageData = '[]';
        let dataForValidation;

        dataForValidation = this.storageService.getItem(dataName);
        if (dataForValidation?.indexOf('undefined') === -1) {
            localStorageData = dataForValidation;
        }

        return localStorageData;
    }

    removeNullProperties(obj, removeEmpty: boolean = false) {
        for (let prop of Object.keys(obj)) {
            const value = obj[prop];

            // Check if value is null
            if (value === null) {
                delete obj[prop];
            }
            // Check if removeEmpty is true and value is an empty string, undefined, or null (but not false or 0)
            else if (removeEmpty && (value === '' || value === undefined)) {
                delete obj[prop];
            }
            // If the value is an object, recursively apply the function
            else if (typeof value === 'object' && value !== null) {
                this.removeNullProperties(value, removeEmpty);
    
                // If the nested object becomes empty, remove it as well
                if (removeEmpty && Object.keys(value).length === 0) {
                    delete obj[prop];
                }
            }
        }

        return obj;
    }

    validateWLEmail() {
        if (window['cordova'] && this.skin && this.userConnectedAndNoEmail()) {
            this.emailValidationNeeded = true;
            this.emailInputAlert('MESSAGES.EMAIL_MISSING', 'redirect_to_profile');
        }
    }

    userConnectedAndNoEmail(): boolean {
        if (this.isAuthUser() && !this.user?.loyaltyCustomer?.Email) return true;
        return false;
    }

    emailInputAlert(dialogText, primaryButtonText) {
        this.mainMessage({
            dialogType: 'error',
            dialogText,
            primaryButtonText,
            hideSecondaryButton: true
        }).then(response => {
            this.redirect(['profile/my-account']);
        }).catch(() => {
            console.error('=== User is not supposed to connect without email ===');
        });
    }

    showNotifyIcon(): boolean {
        // For now we use only for WL
        if (!this.skin) return false;
        if (this.userConnectedAndNoEmail()) return true;
        return false;
    }

    getDecimalFromInteger(val: number, digitsInfo?: string) {
        if (!digitsInfo) digitsInfo = '1.2-2';
        return this._decimalPipe.transform(val, digitsInfo);
    }

    isShowSplashScreen(domain: any): boolean {
        if (this.splashAlreadyDisplayed) return false;
        // If there is a timing, override the regular logic
        if (domain.domainSettings?.splashTiming) {
            const splashTiming = domain.domainSettings?.splashTiming;
            const now = moment();
            const splashDateFrom = moment.utc(splashTiming.from);
            const selectedDateTo = moment.utc(splashTiming.to);

            const isShow = now.isSameOrAfter(splashDateFrom) && now.isSameOrBefore(selectedDateTo);
            this.splashAlreadyDisplayed = isShow;

            return isShow;
        }
        if (domain?.domainSettings?.active !== false && domain?.links?.splashScreenImageURL && window['cordova'] && /home/.test(window.location.href)) {
            this.splashAlreadyDisplayed = true;
            return true;
        }
        return false;
    }

    removeOrderTimeDifferenceFromStorage() {
        this.storageService.removeItem('orderTimeDifference');
    }

    saveOrderTimeDifferenceToStorage(removeExisting: boolean = false): void {
        const storageKey = 'orderTimeDifference';

        // Remove the existing value from storage if the flag is set
        if (removeExisting) {
            this.removeOrderTimeDifferenceFromStorage()
        }

        // Check if the value already exists in storage
        const existingValue = localStorage.getItem(storageKey);

        // Only set the value if it doesn't already exist
        if (existingValue === null && this.serverDateDifference !== undefined) {
            this.storageService.setItem(storageKey, this.serverDateDifference);
        }
    }

    setSkin(skinFromDomain?: string) {
        let skin: string = skinFromDomain ? skinFromDomain : null;
        if (window['config']?.skin) {
            skin = window['config'].skin;
        } else if (/skin=[^&]+/.test(location.href)) {
            skin = location.href.match(/skin=([^&]+)/)[1];
        } else if (this.storageService.getItem('skin')) {
            skin = this.storageService.getItem('skin');
        }

        if (skin == 'default') {
            this.storageService.removeItem('skin');
            this.skin = null;
        } else if (skin) {
            // Saving the skin to the local storage (only for cordova)
            if (window['cordova']) this.storageService.setItem('skin', skin || skinFromDomain);

            this.fileExists(skin)
            .subscribe(response => {
                if (response == true) {
                    // Injecting the skin override
                    $('head').append('<link href="'+this.base('/assets/skins/'+skin+'.css')+'" rel="stylesheet" />');
                }
            });

            // Updating the white-label headers for the Bridge
            this.appHttpOptions.headers = this.appHttpOptions.headers.set('White-Label', skin);
            console.debug('app.component - App Service appHttpOptions > White-Label', this.appHttpOptions.headers.get('White-Label'));

            this.skin = skin;
        }

        if (this.skin) this.upperCaseSkin = this.skin.toUpperCase();
    }

    fileExists(skin: string): Observable<boolean> {
        const safePath = this.base('/assets/skins/'+skin+'.css', false);
        return this.http.get(safePath, { responseType: 'text' })
            .pipe(
                map(response => {
                    return true;
                }),
                catchError(error => {
                    return of(false);
                })
            );
    }

    // tabit-app: keep track of the order process referring page for exact back-button functionality
    setOrderProcessReferringRoute() {
        try {
            this.orderProcessReferringRoute = {
                route: decodeURI(this.currentUrl.split('?')[0]),
                query: this.convertQueryStringToQueryParams(this.currentUrl) || null,
            }
        } catch (error) {
            console.error('setOrderProcessReferringRoute error:', error);
            this.orderProcessReferringRoute = {
                route: '/',
                query: null,
            }
        }
    }

    getValidBaseUrl(url, cacheBusting?) {
        if (!url) return;
        if (/http/.test(url)) return url;
        return this.base(url, cacheBusting);
    }

    registerIcons(icons: string[], innerSvgPath='') {
        each(icons, (ico) => {
            // 2019-07-04 - Due to an issue with SVG not returning with CORS header from AWS CloutFront - Added cache-busting which seems to resolve the issue - temporarily!
            this.iconRegistry.addSvgIcon(`${ico}`, this.sanitizer.bypassSecurityTrustResourceUrl(this.base(`assets/images/svg/${innerSvgPath+ico}.svg?v=${this.iconsCacheBusting}`, false)));
        });
    }

    updatePrivateStore(key: string, value: any): void {
        this.privateStore[`_${key}`].next(cloneDeep(value));
    }

    setBodyCssPlatformClasses() {

        if (this.isMobile()) {
            document.body.classList.add("_mobile");
        } else {
            document.body.classList.add("_desktop");
        }

        // append material's platform-detection service class
        if (this.platformService.ANDROID) {
            document.body.classList.add('_ANDROID');
        } else if (this.platformService.IOS) {
            document.body.classList.add('_IOS');
        }
    }

    isNeedToGetUserInfo(): boolean {
        if (!window['cordova']) {
            let url = window.location.href;
            if (/gift-cards/.test(url) || /tabit-order/.test(url)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    }

    getDashboardImageUrl(BasePathPrefix?: boolean) {
        const dashboardImageUrl = `assets/images/dashboard-${this.isDesktop() ? 'desktop' : 'mobile'}.jpg`;
        return BasePathPrefix ? this.base(dashboardImageUrl) : dashboardImageUrl;
    }

    public showLoyaltyTermsAndMailDialog(data) {
        return new Promise((resolve, reject) => {
            this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                let dialogRef =  this.dialog.open(LoyaltyTermsDialogComponent, {
                    panelClass: ['wl-cards-background-color', 'cards-loyalty-terms-dialog'],
                    backdropClass: ['element-before-loyalty-terms-dialog'],
                    disableClose: true,
                    data,
                });
                dialogRef.afterClosed().subscribe(result => {
                    this.isMessageDialogOpen = false;
                    resolve(result);
                });
            });
        });
    }

    public registerBrandIcons() {
        // if brand custom icon don't exist - don't rewrite the already-registered default icon
        const filesValidation$ = [];
        const validIcons = [];

        for (const brandIcon of this.brandServiceIcons) {
            const url: any = this.base(`/assets/images/svg/brands/${this.skin}/${brandIcon}.svg?v=${this.iconsCacheBusting}`, false);
            filesValidation$.push(
                this.http.get(url, { responseType: 'text' } )
                .pipe(
                    map(res => {
                        validIcons.push(brandIcon)
                        return of(true);
                    }),
                    catchError(err => of(err))
                )
            );
        }

        forkJoin(filesValidation$)
        .subscribe(results => {
            // overwrite default service-icons with custom brand icons at Mat Icons Registry (matching the names from /assets/images/icons/brands/<brand>/*.svg)
            this.registerIcons(validIcons, `brands/${this.skin}/`);
            // wait for the domain config to load before showing icons
            this.defaultServiceButtonsLoaded = true;
        }, err => {
            console.debug('Error retrieving icons from server');
        });
    }

    public getDomain() {
        return this.domainSubject.value;
    }

    public updateDomainData(domain: Domain) {
        this.domainSubject.next(domain);
        if (domain.defaults?.googlePlaceDetails) this.googlePlaceDetailsSubject.next(cloneDeep(domain.defaults.googlePlaceDetails));
        // Theme / Skin Override (White Label)
        if (domain.brand) {
            this.setSkin(domain.brand);
            this.overrideMetaData();
        };
        if (domain.translations) {
            this.domainStrings = domain.translations;
            this.updateTranslations(domain.translations[this.localeId])
        };

        this.defaultServiceOrderType = get(domain, 'defaults.defaultServiceOrderType', 'delivery');
        // this.showAppBanner = get(domain, 'smartAppBannerEnabled', true) && !this.isIOS;
        this.showAppBanner = get(domain, 'smartAppBannerEnabled', true);
        this.updateAppSmartBannerURL();
        // updates only "this.userDetails.wallet" that is required in other parts of the app
        this.accessibilityDeclarationURL = get(domain, 'links.accessibilityDeclarationURL');
    }

    public overrideMetaData() {
        $('head').append(`<link rel="shortcut icon" type="image/png" href="assets/images/wl/${this.skin}/favicon.ico"/>`);
        $('head').append(`<link rel="icon" type="image/png" href="assets/images/wl/${this.skin}/favicon.ico"/>`);
    }

    public getAnimationPath(type) {
        const path = this.base(`/assets/animations/${type}.json?v=` + this.iconsCacheBusting, false);
        return path;
    }

    public setCharAt(str, index, chr) {
        if (index > (str?.length-1)) return str;
        return str.substring(0,index) + chr + str.substring(index + 1);
    }

    public getUrlParam(param: string) {
        return this.convertQueryStringToQueryParams(this.currentUrl)[param];
    }

    public inputFocusCallback() {
        // act only if a focused input found
        const focusedInput = $('input:not([type=checkbox]):focus')[0] || $('textarea:focus')[0];
        if (focusedInput) {
            this.document.body.classList.add('keyboardOpen');
        }

        // It'll handle all focused components that'll be hidden by the keyboard
        // We need the timeout to let the viewport and DOM adjust after the cart display:none
        timer(800)
            .pipe(take(1))
            .subscribe(() => {
                if (focusedInput) focusedInput.scrollIntoViewIfNeeded(true);
            })
    }

    public inputBlurCallback() {
        // wait 150ms for the OSK to close
        timer(150)
            .pipe(take(1))
            .subscribe(() => {
                // ignore cases of focus-hopping between text-inputs, because the 'blur' listener will still fire due to the 150ms delay
                const focusedInput = $('input:not([type=checkbox]):focus')[0] || $('textarea:focus')[0];
                if (!focusedInput) this.document.body.classList.remove('keyboardOpen');
            });
    }

    public getServicesConfig(domain) {
        const defaultServices = {
            book: {
                visible: true,
            },
            takeaway: {
                visible: true,
            },
            delivery: {
                visible: true,
            },
            marketplace: {
                visible: true,
            }
        }

        const domainSettings = get(domain, 'defaults.serviceConfiguration.webHomeServices', {});

        const finalSettings = Object.assign(defaultServices, domainSettings);

        return finalSettings;
    }

    public getWebHomeSectionsConfig(domain) {
        const defaultSettings = {
            foodTags: {
                visible: true,
            },
            newAtTabitOrgs: {
                visible: true,
            },
            homeOrderOrgs: {
                visible: true,
            },
            articles: {
                visible: true,
            },
            mainAreas: {
                visible: true,
            },
            events: {
                visible: false,
            },
            areas: {
                visible: true
            },
            marketplace: {
                visible: false,
            },
        }

        const domainSettings = get(domain, 'defaults.serviceConfiguration.webHomeSections', {});

        const finalSettings = Object.assign(defaultSettings, domainSettings);

        return finalSettings;
    }

    public showProfileIcon() {
        if (this.isApp) return false;
        if (this.isMobile()) return !this.currentUrl.includes('step=menu') && !this.currentUrl.includes('step=enter');
        return true;
    }

    openAccessibilityDeclaration() {
        if (window['cordova']) {
            window.open(this.accessibilityDeclarationURL, '_system');
        } else {
            // When opening a window on a browser (especially in Safari Mobile), we must not specify the second argument ("name") because otherwise, the second time the user clicks the button - the window.open won't switch to the relevant tab (in Safari Mobile).
            window.open(this.accessibilityDeclarationURL);
        }
    }

    getCleanUrlParams(queryParams) {
        const cleanParams = omit(queryParams, ['gl', '_gl', 'ga']);
        const params = new HttpParams({ fromObject: cleanParams });

        return params.toString();
    }

    getBaseLocation(queryParams?: any) {
        const path = `${(environment.appConfig.useHash ? '#' : '')}${window.location.pathname}?${this.getCleanUrlParams(queryParams)}`;
        return path;
    }

    objectEmpty(obj) {
        const empty = obj == undefined || obj == null || Object.keys(obj).length == 0;
        return empty;
    }

    getCurrencyByLocal() {
        const currency = this.appConfig.locale == 'he-IL' ? this.currency : this.us_currency;
        return currency;
    }

    getUnitByLocale() {
        switch (this.appConfig.locale) {
            case 'en-US':
                return 'mile';
            case 'he-IL':
            case 'en-AU':
            case 'ar-IL':
            case 'ru-RU':
            default:
                return 'km';
        }
    }

    // Create a single function handler to combine the two operations
    processRequestWithCaptcha(action: string, url: string, reqBody: any, overrideHeaders?: any): Observable<any> {
        // Get the reCaptcha token asynchronously and use it for the HTTP POST request
        return this.getReCaptchaToken(action).pipe(
            switchMap(token => {
                reqBody.recaptchaToken = token;
                // Make the HTTP POST request with the updated headers and requestBody
                return this.http.post(url, this.removeNullProperties(reqBody, true), overrideHeaders || this.appHttpOptions);
            }),
            catchError(error => {
                // Handle the error during token creation (e.g., log, notify, etc.)
                console.error('Error during Token creation:', error);

                // Rethrow the error to propagate it to the subscriber
                return throwError(error);
            })
        );
    }

    getReCaptchaToken(action): Observable<any> {
        const recaptchaSiteKey = this.appConfig?.recaptchaSiteKey;

        return new Observable(observer => {
            if (!recaptchaSiteKey || !window['grecaptcha']) {
                this.sendLogger({ message: 'reCAPTCHA error - Missing main configuration' });
                observer.error(new Error('Missing main configuration'));
            }
            window['grecaptcha'].enterprise.ready(() => {
                window['grecaptcha'].enterprise.execute(recaptchaSiteKey, { action })
                .then(token => {
                    if (!token) {
                        this.sendLogger({ message: 'reCAPTCHA error - Token no generated' });
                        observer.error('Token generation error');
                    }
                    observer.next(token);
                    observer.complete();
                },error => {
                    this.sendLogger({ message: `reCAPTCHA error - ${JSON.stringify(error)}` });
                    observer.error(error);
                });
            });
        })
    };

    public toggleReCaptcha(hide: boolean) {
        const styleId = 'recaptcha-style';
        const styleScript = document.getElementById(styleId);

        // Hide logo
        if (hide) {
            if (styleScript) return;
            let script = document.createElement('style');
            script.id = styleId;
            script.innerHTML = `
            .grecaptcha-badge {visibility: hidden !important; pointer-events: none;}
            `;

            document.head.appendChild(script);
        // Show logo
        } else {
            if (!styleScript) return;

            const parentElement = styleScript.parentNode;
            // Remove the script element from the head
            parentElement.removeChild(styleScript);
        }
    }

    public initFullViewScript(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            // Protection for uniqufying customers
            if (!this.storageService.getItem('FULLVIEWSESSIONID')) {
                this.storageService.setItem('FULLVIEWSESSIONID', this.getObjectId());
            }

            console.log(`FullView session ID: ${ this.storageService.getItem('FULLVIEWSESSIONID')}`);
            this.sendLogger({ message: `FullView Start session ID: ${ this.storageService.getItem('FULLVIEWSESSIONID')}`});

            const scriptId = 'fullview-script';
            const fvConfig = {
                src: 'https://install.fullview.io',
                'data-org': '3c88bb60-9b42-4858-ad6d-63aae589a129',
                id: scriptId,
            };

            const fvScript = document.createElement('script');
            Object.entries(fvConfig).forEach(([oKey, oVal]) => fvScript.setAttribute(oKey, oVal));

            fvScript.onload = () => {
                console.log('FullView script loaded successfully');
                this.sendLogger({ message: `FullView script loaded successfully for session ID: ${ this.storageService.getItem('FULLVIEWSESSIONID')}`});
                resolve(); // Resolve the promise when script is loaded
            };

            // Append the script to the document body
            document.body.appendChild(fvScript);
        });
    }

    get isTestEnv() {
        return !/-(dmo|bta|prd|stg)/.test(environment.env);
    }

    generateAccessibilityMenuScript(configData: { [key: string]: string }): void {

        const existingScript = document.getElementById("InterdealScript");
        if (existingScript || !configData) return;

        let sitekey;

        for (const [url, key] of Object.entries(configData)) {
            if (window.location.origin.includes(url)) {
                sitekey = key;
                break;
            }
        }

        if (!sitekey) return;

        const localeConfig = {
            'he-IL': { position: 'right', locale: 'HE' },
            'ar-IL': { position: 'right', locale: 'AR' },
            'ru-RU': { position: 'right', locale: 'RU' },
            'default': { position: 'left', locale: 'EN' }
        };

        const config = localeConfig[this.localeId] || localeConfig['default'];

        const isOnlineReservation = window.location.href.includes('/online-reservations/');
        const position = isOnlineReservation ? (config.position === 'right' ? 'left' : 'right') : config.position;

        const interdealConfig: InterdealConfig = {
            sitekey: sitekey,
            Position: position,
            domains: {
                js: 'https://js.nagich.co.il/',
                acc: 'https://access.nagich.co.il/'
            },
            Menulang: (this.localeId || '').substring(0, 2).toUpperCase() || 'EN',
            btnStyle: {
                scale: ['0.5', '0.5'],
                vPosition: ['50%', '70%'],
                draggable: true,
                color: {
                    main: "#000000",
                    second: "#ffffff"
                },
                icon: {
                    outline: false,
                    type: 11,
                    shape: 'semicircle',
                }
            }
        };

        try {
            window['interdeal'] = interdealConfig;
            const coreCall = document.createElement('script');
            coreCall.src = window['interdeal'].domains.js + 'core/4.6.11/accessibility.js';
            coreCall.defer = true;
            coreCall.integrity = 'sha512-SVffVpbO/SKg8zoE3NWu0Ct32mTRDO5b27N4qA5C+e6ctRHVwAk158RdBWMlaD2UuAtQUxeRwY71joiCkwsFVA==';
            coreCall.crossOrigin = 'anonymous';
            coreCall.setAttribute('data-cfasync', 'true');
            coreCall.setAttribute('id', 'InterdealScript');
            document.body ? document.body.appendChild(coreCall) : document.head.appendChild(coreCall);
        } catch (error) {
            console.error('Error in generating Interdeal script', error);
        }
    }

    updateAccessibilityMenuLanguage(): void {

        const existingScript = document.getElementById("InterdealScript");
        if (!existingScript) return;

        const localeConfig = {
            'he-IL': { position: 'right', locale: 'HE' },
            'ar-IL': { position: 'right', locale: 'AR' },
            'ru-RU': { position: 'right', locale: 'RU' },
            'default': { position: 'left', locale: 'EN' }
        };

        const config = localeConfig[this.localeId] || localeConfig['default'];

        const interdeal = window['interdeal'];
        if (!interdeal?.setPosition) {
            return;
        }

        const isOnlineReservation = window.location.href.includes('/online-reservations/');
        const desiredPosition = isOnlineReservation ? (config.position === 'right' ? 'left' : 'right') : config.position;

        if (interdeal?.menuPos !== desiredPosition) {
            interdeal.setPosition(desiredPosition);
        }

        if (interdeal?.lang?.toUpperCase() !== config.locale) {
            interdeal.SetLocale(config.locale);
        }
    }

    public getAlphanumericOnly(str: string): string {
        if (!str?.length) return;

        const regexNonAlphanumeric = /[^a-zA-Z0-9]/g;
        return str.replace(regexNonAlphanumeric, "").trim();
    }

}
