

<div class="module-bar trans wl-app-primary-background-color">
    <div class="_inner">

        <!-- show custom image / custom text / fallback text. -->
        <div class="title">
            <!-- we need to wait until the domain api-call finishes to avoid flickering text changes. -->
            <ng-container *ngIf="domainDataLoaded">
                <img
                    *ngIf="domain?.headerCustomImagePath; else headerCustomTitle"
                    [src]="appService.getValidBaseUrl(domain.headerCustomImagePath)"
                    alt="header-custom-image"
                    class="header-custom-image"
                >
                <ng-template #headerCustomTitle>
                    <span preserveTextDirectionByLocale fontSizeByTextLength>
                        {{domain?.headerCustomTitle || ('restaurants' | translate)}}
                    </span>
                </ng-template>
            </ng-container>
        </div>

        <span class="title flex-grow-1"></span>
        <!--
            //2020-01-05: Requested to be removed from the main Dashboard screen
            <div class="share" *ngIf="appService.shareWidgetExists()" mat-icon-button (click)="appService.shareWidget()">
                <mat-icon>share</mat-icon>
            </div>
        -->
        <button mat-icon-button (click)="appService.redirect(['/home/sites'], { queryParams: { topic:'search' } })">
            <mat-icon svgIcon="search"></mat-icon>
        </button>
        <button mat-icon-button (click)="appService.redirect(['/home/near-me'])">
            <mat-icon class="map-icon" svgIcon="map view"></mat-icon>
        </button>
        <notifications-widget-opener></notifications-widget-opener>
    </div>
</div>

<!-- // Deprecated (Unkown component)
<ng-template #searchResultTemplate let-r="result" let-t="term">
    <div class="d-flex align-items-center ">
        <div class="avatar mr-2" [style.background-image]="r.logo | safeStyle"></div>
        <div class="flex-grow-1 text-ellipsis">
            <ngb-highlight [result]="r.name" [term]="t"></ngb-highlight>
        </div>
    </div>
</ng-template>
-->

<span class="fab-fixed-bottom animated fadeIn" *ngIf="showScrollUp">
    <button mat-fab color="primary" (click)="appService.scrollToTop('#dashboard-module-content')">
        <mat-icon>arrow_upward</mat-icon>
    </button>
</span>

<div id="dashboard-module-content" class="module-content wl-app-primary-background-color" ngxDetectScroll (onScroll)="handleScroll($event)" [bottomOffset]="200" [topOffset]="200">

    <div *ngIf="!appService.skin" class="book-selection-bar-section" [style.backgroundImage]="bookSelectionBarImageBackground">
        <div class="overlay"></div>
        
        <h1 (click)="appService.redirect(['/book'])">{{'DASHBOARD.we_have_missed_you!' | translate}}</h1>
        <h2 (click)="appService.redirect(['/book'])">{{'DASHBOARD.when_we_would_see_you?' | translate}}</h2>
        <tabitbook-selection-bar (click)="appService.redirect(['/book'])" [parent]="'app-tabitbook-start'" [$storage]="$storage"></tabitbook-selection-bar>
        <div class="location-widget-wrapper">
            <location-widget [houseRequired]="false"></location-widget>
        </div>

        <!-- <div class="bottom-overlay" [@userPointsAnimation]="userPointsAnimationTrigger">
            <h3>{{userPointsTitle}}</h3>
            <h4>{{userPointsSubtitle}}</h4>
        </div> -->
       

        <!-- <h1>{{'DASHBOARD.we_have_missed_you!' | translate}}<br>{{'DASHBOARD.when_we_would_see_you?' | translate}}</h1>
        <h2>{{'DASHBOARD.all_the_restaurants_click_away' | translate}}</h2>
        <div class="delivery-selection-bar">
            Corona updates - Online booking 
            <div class="button booking" (click)="appService.redirect(['/book'])">{{'DASHBOARD.BUTTONS.book' | translate}}</div>
            <div class="button takeaway" (click)="searchDelivery('takeaway')">{{'DASHBOARD.BUTTONS.takeaway' | translate}}</div>
            <div class="button delivery" (click)="searchDelivery('delivery')">{{'DASHBOARD.BUTTONS.delivery' | translate}}</div>
        </div> -->



        <div class="bottom-overlay"></div>
        <!-- // 2020-04-23 - Decided to remove from the App Dashboard -->
        <!--
        <div class="bottom-overlay" (click)="businessInviteRedirect()">
            <h3>{{ 'business_invite_restaurant_owner' | translate }}</h3>
            <h4>{{ 'business_invite_join_us' | translate }} &rsaquo;</h4>
        </div>
        -->
    </div>

    <div *ngIf="appService.skin" class="book-selection-bar-section smaller" [style.backgroundImage]="bookSelectionBarImageBackground">
        <ng-container *ngIf="appService.skin == 'kfc'">
            <h1 class="bold-title">
                {{'KFC.title' | translate: country}}
            </h1>
            <h1>
                {{'KFC.main_sub_title' | translate}}
                <br>
                {{'KFC.sec_sub_title' | translate}}
            </h1>
        </ng-container>

        <ng-container *ngIf="appService.skin !== 'kfc'">
            <h1>{{ appService.upperCaseSkin + '.title' | translate }}</h1>
            <h2>{{ appService.upperCaseSkin + '.sub_title' | translate }}</h2>
        </ng-container>

        <div class="location-widget-wrapper fixed">
            <location-widget [houseRequired]="false"></location-widget>
        </div>
    </div>

    <!-- TODO - Check if animation needed at all -->
    <div class="tracked-order-container wl-app-primary-background-color" *ngIf="trackedOrders?.length && appService.isAuthUser() && dashboardSectionsDomainConfig?.trackedOrders?.visible">
        <div class="dashboard-panel-header d-flex align-items-center wl-app-primary-background-color">
            <div class="flex-grow-1 title">{{'YOUR_ORDERS' | translate}}</div>
        </div>
        <div class="tracked-order">
            <tracked-order-item *ngFor="let trackedOrder of trackedOrders | orderBy: 'created': true" [siteName]="trackedOrder.orgName" [order]="trackedOrder"></tracked-order-item>
        </div>
    </div>

    <!--<div [@reservationSectionAnimation]="reservationSectionAnimationTrigger" (@reservationSectionAnimation.done)="reservationSectionAnimationFinished()" class="future-reservations-container" *ngIf="futureReservations.length">--> <!-- We can't use ng-container here, because it doesn't work with animations -->
    <div class="future-reservations-container wl-app-primary-background-color" *ngIf="futureReservations?.length && appService.isAuthUser() && dashboardSectionsDomainConfig?.futureReservations?.visible">
        <div class="dashboard-panel-header d-flex align-items-center wl-app-primary-background-color">
            <div class="flex-grow-1 title">{{'future_reservation' | translate}}</div>
        </div>
        <future-reservations [reservations]="futureReservations" [parent]="'dashboard'"></future-reservations>
    </div>

    <!--<div [@recentOrdersSectionAnimation]="recentOrdersSectionAnimationTrigger" (@recentOrdersSectionAnimation.done)="recentOrdersSectionAnimationFinished()" class="recent-orders-container" *ngIf="recentOrders.length">--> <!-- We can't use ng-container here, because it doesn't work with animations -->
    <div class="recent-orders-container wl-app-primary-background-color" *ngIf="recentOrders?.length && dashboardSectionsDomainConfig?.recentOrders?.visible">
        <div class="dashboard-panel-header d-flex align-items-center wl-app-primary-background-color">
            <div class="flex-grow-1 title">{{'your_recent_orders' | translate}}</div>
        </div>
        <div class="recent-orders">
            <app-history-item class="wl-cards-background-color" *ngFor="let recentOrder of recentOrders" type="past_reservation" [siteName]="recentOrder.name" [historyItem]="recentOrder"></app-history-item>
        </div>
    </div>

    <dashboard-section *ngIf="appService.skin == 'aromatlv'" title="near_me" [loading]="loadingMetaData" [items]="nearbyTabitOrgs | slice:0:20" [moreLink]="['/home/near-me']">
        <site-card *ngFor="let org of nearbyTabitOrgs; let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </dashboard-section>

    <dashboard-section title="WEB_CONTAINER.marketplace.home_page_section_title" *ngIf="dashboardSectionsDomainConfig?.marketplace?.visible && marketplaceOrgs?.length" [style.height.rem]="19" [loading]="loadingMetaData" [items]="marketplaceOrgs | slice:0:3" [moreLink]="['app-gift-it']">
        <div class="site-marketplace-container">
            <marketplace-card *ngFor="let org of (marketplaceOrgs | slice:0:3)" [isDashboard]="true" [site]="org"></marketplace-card>
        </div>
    </dashboard-section>

    <!-- <dashboard-section [@clubCardsAnimation]="clubsDataLength" (@clubCardsAnimation.done)="siteCardsAnimationFinished()" title="club_benefits" [loading]="loadingMetaData" [items]="clubsData" [moreLink]="['profile/my-benefits']"> -->
    <dashboard-section title="club_benefits" *ngIf="clubsDataLength && appService.isAuthUser() && dashboardSectionsDomainConfig?.clubsData?.visible" [loading]="!!clubsDataLength" [items]="clubsData" [moreLink]="['profile/my-benefits']">
        <div class="clubs-benefits-container">
            <club-card *ngFor="let clubData of clubsData; let i = index;" [index]="i" [club]="clubData"></club-card>
        </div>
    </dashboard-section>

    <!-- <dashboard-section [@eventCardsAnimation]="sitesEventsLength" (@eventCardsAnimation.done)="siteCardsAnimationFinished()" title="events_and_benefits" *ngIf="showEvents && sitesEventsLength" [higher]="true" [loading]="loadingMetaData" [items]="sitesEvents" [moreLink]="['app-events']"> -->
    <dashboard-section title="events_and_benefits" *ngIf="showEvents && sitesEventsLength" [style.height.rem]="24" [loading]="loadingMetaData" [items]="sitesEvents" [moreLink]="['app-events']">
        <div class="site-events-container">
            <event-card *ngFor="let siteEvent of sitesEvents; let i = index;" [index]="i" [siteEvent]="siteEvent" [tags]="occasionTags"></event-card>
        </div>
    </dashboard-section>

    <dashboard-section *ngIf="dashboardSectionsDomainConfig?.homeOrderOrgs?.visible" [@siteCardsAnimation]="homeOrderOrgsLength" (@siteCardsAnimation.done)="siteCardsAnimationFinished()" title="order_subtitle" [loading]="loadingMetaData" [items]="homeOrderOrgs | slice:0:20" [moreLink]="['/order']">
        <site-card *ngFor="let org of homeOrderOrgs; let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </dashboard-section>

    <ng-container *ngIf="appService.skin != 'aromatlv' && domainDataLoaded">
        <dashboard-section *ngIf="nearbyTabitOrgs?.length && !domain?.showSubGroups" title="near_me" [loading]="loadingMetaData" [items]="nearbyTabitOrgs | slice:0:20" [moreLink]="['/home/near-me']">
            <site-card *ngFor="let org of nearbyTabitOrgs; let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
        </dashboard-section>
        <ng-container *ngFor="let subGroupTag of nearbySubGroupOrgsMap">
            <dashboard-section *ngIf="subGroupTag.orgs?.length" title="{{ tagsService.getTagLabel(subGroupTag._id) }} {{'near_me' | translate}}" [loading]="loadingMetaData" [items]="subGroupTag.orgs | slice:0:20" [moreLink]="['/home/near-me']" [qparams]="getTagParams(tagsService.getTagLabel(subGroupTag._id))">
                <site-card *ngFor="let org of subGroupTag.orgs; let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
            </dashboard-section>
        </ng-container>
    </ng-container>

    <dashboard-section *ngIf="(giftCardsAccountGuid && giftCardDomainConfig?.giftCardEnabled) || domain?.defaults?.externalCardLinks?.length" title="extra_services" [loading]="loadingMetaData">
        <promotion-card *ngIf="giftCardsAccountGuid && giftCardDomainConfig?.giftCardEnabled" [serviceId]="giftCardsAccountGuid" (click)="loyaltyService.redirectToGiftCards('create-card')"></promotion-card>
        <ng-container *ngIf="domain?.defaults?.externalCardLinks?.length">
            <promotion-card *ngFor="let cardLink of domain.defaults.externalCardLinks" [link]="cardLink" (click)="dialogsService.toggleActionFrame('link', null, null, null, cardLink.url)"></promotion-card>
        </ng-container>
    </dashboard-section>

    <dashboard-section *ngIf="dashboardSectionsDomainConfig?.foodTags?.visible" title="cuisine" [loading]="!foodTags?.length" [items]="foodTags" [moreLink]="['/home/tags']">
        <food-category-item
            *ngFor="let tag of foodTags"
            [tagId]="tag._id"
            parentRoute="/home"
            class="category-card-wrapper"
        ></food-category-item>
    </dashboard-section>

    <dashboard-section *ngIf="dashboardSectionsDomainConfig?.newAtTabitOrgs?.visible" title="new_in_tabit" [loading]="loadingMetaData" [items]="newAtTabitOrgs" [moreLink]="['/home/new-at-tabit']">
        <site-card *ngFor="let org of (newAtTabitOrgs | orderBy: 'liveAt': true | slice:0:20); let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </dashboard-section>

    <dashboard-section *ngIf="appService.isAuthUser() && dashboardSectionsDomainConfig?.favorites?.visible" [@siteCardsAnimation]="favoriteOrgsLength" title="my_favorites" [loading]="loadingMetaData" [items]="favoriteOrgs">
        <site-card *ngFor="let org of favoriteOrgs; let i = index;" [index]="i" [siteId]="org._id" (click)="siteClick(org)"></site-card>
    </dashboard-section>
    <!-- all-restaurants-container was removed on 02.11.2021  -->
    <!-- <div *ngIf="!appService.skin" class="all-restaurants-container">
        <h4>{{ 'to_all_sites_title' | translate }}</h4>
        <button mat-stroked-button (click)="appService.redirect(['/home/sites'], { queryParams: { topic:'search' } })">{{ 'to_all_sites' | translate }}</button>
    </div> -->
    <!--
    <div class="dashboard-panel-header d-flex align-items-center">
        <div class="flex-grow-1 title">{{'all_sites' | translate}}</div>
    </div>
    <div class="dashboard-panel">
        <mat-list [@animateList]="animateList" class="generic-list">
            <ng-container *ngFor="let site of nearbyAllOrgs">
                <site-item [siteId]="site._id" (click)="siteClick(site)" (press)="sitePress(site, $event)"></site-item>
            </ng-container>
        </mat-list>
        <div class="show-more-sites">
            <div *ngIf="nearbyAllOrgsLength" class="loading-label">{{'MESSAGES.LOADING_SITES' | translate}}</div>
            <mat-spinner *ngIf="nearbyAllOrgsLength" diameter="40" strokeWidth="4" class="light-grey"></mat-spinner>
        </div>
    </div>
    -->

</div>

