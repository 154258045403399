import { Component, OnInit, OnDestroy, ElementRef, NgZone, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { AppService } from '../../app.service';
import { TabitpayService } from '../tabit-pay/tabit-pay.service';
import { DialogsService } from '../../_core/dialogs.service';
import { StyleService } from '../../_core/style.service';
import { EntityService } from '../../_core/entity.service';

import { OrganizationsService } from '../../_core/organizations.service';
import { timer } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf } from '@angular/common';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { BlockUIModule } from 'ng-block-ui';

declare var navigator: any;
declare var Camera: any;
declare const $: any;

@UntilDestroy()
@Component({
    selector: 'test-scanner',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './test-scanner.component.html',
    styleUrls: ['./test-scanner.component.scss'],
    host: {
        'class': 'host-default'
    },
    standalone: true,
    imports: [BlockUIModule, MatIconButton, MatIcon, WidgetOpenerComponent, NgIf, MatButton, TranslateModule]
})
export class TestScannerComponent implements OnInit, OnDestroy {

    scanning: boolean = false;

    site: string = null;

    // Flash options
    toggleFlashIcon: string = 'flash_on';
    isFlashEnabled: boolean = false;

    availableDevices: MediaDeviceInfo[] = [];
    selectedDevice: any;
    scannerStatus: string;
    scanCounter: number = 0;

    constructor(
        public appService: AppService,
        public orderService: TabitpayService,
        public dialogsService: DialogsService,
        public router: Router,
        private route: ActivatedRoute,
        private organizationsService: OrganizationsService,
        public elementRef: ElementRef,
        public styleService: StyleService,
        public entityService: EntityService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private http: HttpClient,
    ) {
        this.route.queryParams.subscribe(params => {
            if (params.site) this.site = params.site;
        });
    }

    async ngOnInit() {
        try {
            await this.startScanner();
            // this.simulateScanner();
        } catch(err) {
            this.extractFromQR(null, "MESSAGES.PLEASE_ENABLE_CAMERA");

            // To simulate as if a QR Code has been succcessfully scanned - unccomment the following line:
            // this.simulateScanner();

            // To simulate the camera preview screen - uncomment the following two lines:
            this.scanning = true;
            // We need to let the router-outlet "leaver" to disappear first (otherwise we might see the leaving component in the background)
            timer(1000)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.styleService.mainAppComponentTransparent = true;
                });
        }

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.removeBackgroundClasses();
                        this.appService.redirect(['/home/dashboard']);
                    })
                });
        }
    }

    async requestCameraAccess() {
        try {
            if (window['cordova']) {
                // Check and request camera permission using Cordova-specific logic
                await this.requestCameraAccessCordova();
            } else {
                console.error('Tabit Pay > requestCameraAccess > Don\'t have access to web camera');
            }
        } catch (error) {
            this.appService.sendLogger({ message: `Tabit Pay > requestCameraAccess > Error accessing camera: ${JSON.stringify(error)}`});
            console.error('Error accessing camera:', error);
        }
    }

    async requestCameraAccessCordova() {
        try {
            // Check camera authorization status
            if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) { 
                window['cordova'].plugins.diagnostic.getCameraAuthorizationStatus(async (status) => {
                    switch (status) {
                        case window['cordova'].plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                        case window['cordova'].plugins.diagnostic.permissionStatus.DENIED_ONCE:
                        case window['cordova'].plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                            this.appService.sendLogger({ message: `Tabit Pay > requestCameraAccessCordova > Error status: ${status}`});
                            // Request camera permission
                            await this.requestCameraPermission();
                            break;
                        case window['cordova'].plugins.diagnostic.permissionStatus.GRANTED:
                        case window['cordova'].plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                            this.appService.sendLogger({ message: `Tabit Pay > requestCameraAccessCordova > Camera permission granted: ${status}`});
                            // Camera permission is granted only when the app is in use, proceed to accessing the camera
                            await this.accessCamera();
                            break;
                    }
                    // Needed to render the page
                    this.changeDetectorRef.detectChanges();
                }, (error) => {
                    this.appService.sendLogger({ message: `Tabit Pay > requestCameraAccessCordova > Error getting camera authorization status: ${JSON.stringify(error)}`});
                    console.error("Error getting camera authorization status:", error);
                });
            }
        } catch (error) {
            console.error("Error accessing camera with Cordova:", error);
        }
    }

    async requestCameraPermission() {
        try {
            // Request camera permission
            if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                window['cordova'].plugins.diagnostic.requestCameraAuthorization(async (status) => {
                    switch (status) {
                        case window['cordova'].plugins.diagnostic.permissionStatus.NOT_REQUESTED:
                        case window['cordova'].plugins.diagnostic.permissionStatus.DENIED_ONCE:
                        case window['cordova'].plugins.diagnostic.permissionStatus.DENIED_ALWAYS:
                        default:
                            this.appService.sendLogger({ message: `Camera permission permanently denied - ${status}`});
                            break;
                        case window['cordova'].plugins.diagnostic.permissionStatus.GRANTED:
                        case window['cordova'].plugins.diagnostic.permissionStatus.GRANTED_WHEN_IN_USE:
                            await this.accessCamera();
                            break;
                    }
                });

            } else {
                console.error("Error requesting camera permission with Cordova");
            }
        } catch (error) {
            this.appService.sendLogger({ message: `requesting camera permission with Cordova - ${JSON.stringify(error, null, 4)}`});
            console.error("Error requesting camera permission with Cordova:", error);
        }
    }

    async accessCamera() {
        try {
            // After successfully accessing the camera, update the device list
            await this.updateDeviceList();

            // Needed to render the page
            this.changeDetectorRef.detectChanges();
        } catch (error) {
            console.error('Error accessing camera with standard web logic:', error);
        }
    }

    async updateDeviceList() {
        this.availableDevices = await this.getBackFacingCameras();
        console.debug('QR Scanner > updateDeviceList > availableDevices: ', this.availableDevices);

        if (this.availableDevices.length > 0) {
            this.selectedDevice = this.availableDevices[0];
            this.scanning = true;
            this.changeDetectorRef.detectChanges();

            console.debug('QR Scanner > updateDeviceList > selectedDevice: ', this.selectedDevice);
            console.debug('QR Scanner > updateDeviceList > selectedDevice Capabilities: ', this.selectedDevice?.getCapabilities());
        }

        // Needed to render the page
        this.changeDetectorRef.detectChanges();
    }

    async getBackFacingCameras(): Promise<MediaDeviceInfo[]> {
        try {
            // Request access to the camera
            const stream = await navigator.mediaDevices?.getUserMedia({ video: true });

            // Enumerate devices and filter for back-facing cameras
            const devices = await navigator.mediaDevices.enumerateDevices();
            const backFacingCameras = devices.filter((device) => {
                return device.kind === 'videoinput' && this.isBackFacingCamera(device, stream);
            });

            // Close the camera access
            stream.getTracks()?.forEach((track) => track.stop());

            return backFacingCameras;
        } catch (error) {
            console.error('Error accessing camera:', error);
            return [];
        }
    }

    isBackFacingCamera(device: MediaDeviceInfo, stream: MediaStream): boolean {
        const videoTrack = stream.getVideoTracks().find((track) => track.label === device.label);

        if (videoTrack && 'getCapabilities' in videoTrack) {
            const capabilities: any = videoTrack.getCapabilities();
            return capabilities.facingMode === 'environment'; // 'environment' indicates a back-facing camera
        }

        // If the camera does not support capabilities, consider it as a back-facing camera.
        return true;
    }

    onStatusChange(e) {
        console.log(e);
    }

    simulateScanner(ev?) {
        // this.extractFromQR('https://qrstud.io/fwv1dtl');
        this.extractFromQR('https://il-pay.tabit-stage.com/?site=62e6410905a9601b248afb55&oid=64e4884c12d81444411777af');
        // this.extractFromQR('https://tabit-web.tabit-dev-online.com/tabit-order?siteName=misadassah&service=eatin');
    }

    async startScanner() {
        try {
            await this.requestCameraAccess();
            // We need to let the router-outlet "leaver" to disappear first (otherwise we might see the leaving component in the background)
            timer(1000)
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.styleService.mainAppComponentTransparent = true;
                });

            this.scanning = true;

            // Needed to render the page
            this.changeDetectorRef.detectChanges();
        } catch (e) {
            this.extractFromQR(null, "MESSAGES.PLEASE_ENABLE_CAMERA");
        }
    }

    gotoManualEntry() {
        if (this.site) {
            this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: this.site } });
        } else {
            this.appService.redirect(['/pay/sites']);
        }
    }

    extractFromQR(url, message?, retry?: boolean) {
        let args: any = {};

        this.ngZone.run(() => {
            try {
                if (message) {
                    this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: 'MESSAGES.PLEASE_ENABLE_CAMERA',
                        primaryButtonText: 'redirect_to_device_settings'
                    }).then(() => {
                        if (window['cordova'] && window['cordova'].plugins && window['cordova'].plugins.diagnostic) {
                            window['cordova'].plugins.diagnostic.switchToSettings((success: any) => {
                                console.debug('extractFromQR > Cordova Plugin Diagnostic > Redirecting to device settings so the user can enable the camera - SUCCESS: ', success);
                            }, (error: any) => {
                                this.appService.sendLogger({ message: `Tabit Pay > extractFromQR > Cordova Plugin Diagnostic> Redirecting to device settings so the user can enable the camera - ERROR: ${JSON.stringify(error)}`});
                                console.debug('extractFromQR > Cordova Plugin Diagnostic> Redirecting to device settings so the user can enable the camera - ERROR: ', error);
                            })
                        }
                    }).catch(() => {
                        // this.gotoManualEntry();
                        console.debug('Tabit Pay > Error enabling camera');
                    });

                    return;
                }

                // Try to preload qrstudio urls
                if (/qrstud/.test(url)) {
                    return this.unshortenUrl(url)
                    .then(res => {
                        this.extractFromQR(res);
                    }).catch(err => {
                        this.throwInvalidQrError();
                    })
                } else {
                    try {
                        url.split("?")[1].split("&").forEach((val, index) => {
                            let valArr = val.split("=");
                            args[valArr[0]] = valArr[1]
                        });
                    } catch (e) { }

                    // Redirect to Tabit Pay
                    if (args?.site && args?.oid) {

                        console.debug('Tabit Pay > extractFromQR', args);

                        // Fow WL apps we want to preserve the softDeleted logic
                        if (this.appService.skin) {
                            this.extractFromQRForWL(args);
                        } else {
                            this.redirectToTabitPay(args.site, args);
                        }

                    // Redirect to Tabit Order
                    } else if ((args?.site || args?.siteName || args?.sitename)) {
                        console.debug('Tabit Order > extractFromQR', args);
                        const siteName = args?.siteName || args?.sitename;
                        if (siteName) {
                            // Get the site by publicUrl
                            this.entityService.getSiteByPublicUrl(siteName)
                            .then(
                            (site: any) => {
                                if (!site?._id) return this.throwInvalidQrError();
                                args.site = site._id;
                                this.handleTabitOrderRedirect(args);
                            })
                        } else {
                            this.handleTabitOrderRedirect(args);
                        }
                    } else {
                        this.throwInvalidQrError();
                    }
                }
            } catch (e) {
                this.appService.sendLogger({ message: `Tabit Pay > extractFromQR Error: ${JSON.stringify(e)}`});
                alert(e.description);
            }
        });
    }

    unshortenUrl(url: string): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            this.http
            .get(url, { observe: 'response', responseType: 'text' })
            .toPromise()
            .then(response => {
                const realUrl = response.url || url;
                resolve(realUrl);
            })
            .catch(error => {
                this.appService.sendLogger({ message: `Tabit Pay > unshortenUrl Error: ${JSON.stringify(error)}`});
                reject(error);
            });
        });
    }

    private extractFromQRForWL(args, target: 'Pay' | 'Order' = 'Pay') {
        this.organizationsService.fullOrganization(args.site)
        .subscribe(
            // Change redirect target by source
            (site) => target == 'Pay' ? this.redirectToTabitPay(site._id, args) : this.redirectToTabitOrder(site._id, args),
            (error) => {
                this.appService.sendLogger({ message: `Tabit Pay/Order > extractFromQR > this.organizationsService.fullOrganization 1 > Error: ${JSON.stringify(error)}`});
                console.debug('Tabit Pay/Order > extractFromQR > this.organizationsService.fullOrganization 1 > Error: ', error);

                let organizatoinUrlIdentifier: string = this.appService.urlIdentifierRedirectFromError(error);
                if (organizatoinUrlIdentifier) {
                    this.organizationsService.fullOrganization(organizatoinUrlIdentifier).subscribe(
                        // Change redirect target by source
                        (site) => (target == 'Pay' ? this.redirectToTabitPay(site._id, args) : this.redirectToTabitOrder(site._id, args)),
                        (error) => {
                            this.appService.sendLogger({ message: `Tabit Pay > extractFromQR > this.organizationsService.fullOrganization 2 > Error: ${JSON.stringify(error)}`});
                            console.debug('Tabit Pay > extractFromQR > this.organizationsService.fullOrganization 2 > Error: ', error);
                            this.throwInvalidQrError();
                        }
                    );
                } else {
                    this.throwInvalidQrError();
                }
            }
        );
    }

    private redirectToTabitPay(siteID: any, args: any) {
        if (siteID == args.site) {
            console.debug('Tabit Pay > extractFromQR > Site: ', siteID);
            this.appService.redirect(['/pay/tabit-pay'], { queryParams: { site: siteID, oid: args.oid, price: args.price } });
        } else {
            this.appService.sendLogger({ message: `Tabit Pay > extractFromQR > Site does not exist: ${siteID}`});
            console.debug('Tabit Pay > extractFromQR > Site does not exist: ', siteID);
            this.throwInvalidQrError();
        }
    }

    private redirectToTabitOrder(siteID: any, args: any) {
        if (siteID == args.site) {
            console.debug('Tabit Pay > extractFromQR > Site: ', siteID);
            if (args.service) {
                const queryParams = {
                    site: siteID,
                    service: args.service
                };
                if (args.table) queryParams['table'] = args.table;

                this.appService.redirect(['/tabit-order'], { queryParams });
            } else {
                this.appService.redirect(['/app-site', args.site]);
            }
        } else {
            this.appService.sendLogger({ message: `Tabit Order > extractFromQR > Site does not exist: ${siteID}`});
            console.debug('Tabit Order > extractFromQR > Site does not exist: ', siteID);
            this.throwInvalidQrError();
        }
    }

    private handleTabitOrderRedirect(args) {
        // Fow WL apps we want to preserve the softDeleted logic
        if (this.appService.skin) {
            this.extractFromQRForWL(args, 'Order');
        } else {
            this.redirectToTabitOrder(args.site, args);
        }
    };

    removeBackgroundClasses() {
        this.styleService.mainAppComponentTransparent = false;
    }

    async manualCamera() {
        try {
            const imageData = await this.captureImage();
            console.log('Captured image data:', imageData);
            // Further processing with ZXing package
        } catch (error) {
            console.error('Error capturing image:', error);
        }
    }

    captureImage(): Promise<string> {
        return new Promise((resolve, reject) => {
            navigator.camera.getPicture(
                (imageData) => {
                    resolve(imageData);
                },
                (error) => {
                    reject(error);
                },
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,
                    sourceType: Camera.PictureSourceType.CAMERA,
                    encodingType: Camera.EncodingType.JPEG,
                    mediaType: Camera.MediaType.PICTURE
                }
            );
        });
    }

    ngOnDestroy() {
        this.removeBackgroundClasses();

        if (window['QRScanner']) {
            window['QRScanner'].destroy(function (status) {
                console.debug('Close Module QR Status: ', status);
            });
        }
        console.log(`ngOnDestroy - QRScannerComponent`);
    }

    close() {
        this.appService.goBack();
    }

    throwInvalidQrError() {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'error',
                dialogText: "MESSAGES.INVALID_QR"
            }).then(res => {
                this.appService.goBack();
            });
        });
    }

}
