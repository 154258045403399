import { Component, NgZone, OnInit } from '@angular/core';

import { AppService } from '../../app.service';
import { AccountService } from '../../_core/account.service';
import { AuthService } from '../../_core/auth.service';
import { DialogsService } from '../../_core/dialogs.service';
import { EntityService } from '../../_core/entity.service';
import { TranslateModule } from '@ngx-translate/core';
import { WidgetOpenerComponent } from '../../notifications/widget-opener/widget-opener.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'remove-account',
    templateUrl: './remove-account.component.html',
    styleUrls: ['./remove-account.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatIcon, WidgetOpenerComponent, TranslateModule]
})
export class RemoveAccountComponent implements OnInit {

    constructor(
        public appService: AppService,
        public dialogsService: DialogsService,
        public accountService: AccountService,
        public authService: AuthService,
        public entityService: EntityService,
        public ngZone: NgZone,
    ) { }

    ngOnInit(): void {
    }

    deleteCustomer() {
        const phone = this.appService.user.phone || this.appService?.user?.loyaltyCustomer?.Mobile;
        if (!phone) return;

        return this.appService.mainMessage({
            dialogType: 'info',
            dialogText: "delete_account",
            primaryButtonText: "accept",
            secondaryButtonText:"Cancel",
            hideSecondaryButton: false,
        }).then(res => {
            const args = { phone };

            this.appService.startBlock();
            // 20-06-2022 - Consider moving the deletion of the user to somewhere more global
            this.authService.authMobile$(args).subscribe(response => {
                if (response.IsSuccess && response.ResponseData.LoginResult == 'UserExistInLoyalty') {
                    this.appService.stopBlock();
                    this.dialogsService.showVerifyCodeDialog(args)
                    .then(res => {
                        this.authService.deleteLoyaltyUser$().subscribe(res => {
                            console.debug('Deleted user from Loyalty services', res);
                            this.entityService.removeAllPaymentsFromWallet().then(res => {
                                console.debug('Removed payments from wallet');
                                this.accountService.refreshWallet().then(res => {
                                    console.debug('Refreshed user wallet');
                                    this.authService.deleteUserFromBridge$().subscribe(res => {
                                            console.debug('Deleted user\'s meta data');
                                            this.appService.signOut(false, true);
                                        })
                                    })
                                })
                        })
                    }).catch(err => {
                        console.debug('Error on remove-account', err);
                    })
                } else {
                    return this.appService.mainMessage({
                        dialogType: 'error',
                        dialogText: "MESSAGES.SERVER_ERROR",
                        hideSecondaryButton: true,
                    })
                }
            });
        }).catch(err => {
            this.appService.stopBlock();
        });
    }

}
