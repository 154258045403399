import { Component, OnInit, OnDestroy, HostListener, ChangeDetectionStrategy, ChangeDetectorRef, NgZone } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { trigger, style, animate, transition } from '@angular/animations';
import { Subscription, combineLatest, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { AppService } from '../../app.service';
import { OrderTrackerService } from '../../_core/order-tracker.service';
import { NotificationsService, UnifiedMessagePayload } from '../../_core/notifications.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { ConfigurationsService } from '../../_core/configurations.service';
import { UserReviewsService } from '../../_core/user.reviews.service';
import { StorageService } from '../../_core/storage.service';

import { AnimationItem } from 'lottie-web';

import { isEmpty, get } from 'lodash-es';
import moment from 'moment';
import { AMPMConverterPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { SiteMetaDataComponent } from '../site-meta-data/site-meta-data.component';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { FormsModule } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { LottieComponent } from 'ngx-lottie';
import { HeaderTrackerComponent } from './header-tracker/header-tracker.component';
import { MatTabGroup, MatTab } from '@angular/material/tabs';
import { OrderTrackerHeaderComponent } from './order-tracker-header/order-tracker-header.component';
import { NgIf, NgClass, AsyncPipe, DatePipe } from '@angular/common';

enum MAIN_TABS {
    TRACKER,
    SUMMARY
}

enum ORDER_STEPS {
    ORDER_LOADED,
    ORDER_CONFIRMED,
    ORDER_BEING_PREPARED,
    ORDER_IN_PROGRESS,
    ORDER_TA_READY,
    ORDER_TA_TAKEN,
    ORDER_DELIVERY_ALMOST_READY,
    ORDER_DELIVERY_ON_ITS_WAY,
    ORDER_DELIVERED,
    ORDER_IM_HERE,
    ORDER_THUMBS_UP,
    ORDER_ERROR
}

@Component({
    selector: 'order-tracker',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './order-tracker.component.html',
    styleUrls: ['./order-tracker.component.scss'],
    animations: [
        trigger('buttonAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1 })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [NgIf, OrderTrackerHeaderComponent, MatTabGroup, MatTab, HeaderTrackerComponent, LottieComponent, MatButton, NgClass, FormsModule, OrderSummaryComponent, GoogleMap, MapMarker, SiteMetaDataComponent, AsyncPipe, DatePipe, TranslateModule, AMPMConverterPipe]
})

export class OrderTrackerComponent implements OnInit, OnDestroy {

    @HostListener ('window:focus', ['$event']) onFocus(event: FocusEvent): void {
        this.getData();
    }

    public orderStepsEnum = ORDER_STEPS;

    public user = {
        id: '',
        name: '',
        mobile: '',
        address: ''
    };

    public org: any;
    public order: any;
    public firedTime: moment.Moment;
    public takenTime: moment.Moment;
    public configuration: any;
    public orderAnimationPaths = {};

    public primaryStep: number = MAIN_TABS.TRACKER;
    public secondaryStep: number = ORDER_STEPS.ORDER_LOADED;

    public orderNumber: string;
    public mainNote: string = '';
    public locationDetailsNote: string = '';
    public orderId: string;
    public orgId: string;
    public courier: string;
    public service: string = '';
    public urlReferrer: string = '';
    public imHereCaption: string = 'hey_im_here';

    public orderLoaded: boolean = false;
    public orderReviewd: boolean = true;
    public instructionsSubmitted: boolean = false;
    public showHomePageRedirect: boolean;
    public showImHereExplanation: boolean = true;

    public date = new Date();

    public animationItem: AnimationItem;

    public coordinates: any = {
		lat: null,
		lng: null,
	};

    private redirectSource: string = window['cordova'] ? 'app' : 'web';
    private userReviewReferrer: string = window['cordova'] ? '/home/dashboard' : '/';
    private secondsMultiplierValue: number = 1000;
    private diffInMinutesToShowYouSurePopup: number = 7;
    private stepAfterNotes: number;

    private firedTimeouts: any = [];
    private deliveredTimeout: any;
    private locationInterval: any;
    private pollingInterval: any;
    private delays: any;

    private urlSubscription: Subscription;
    private organizationSubscription: Subscription;
    private orderSubscription: Subscription;
    private reviewsSubscription: Subscription;
    private fireBaseRawNotificationSubscription: Subscription;
    private isPushNotificationsDisabledSubscription: Subscription;
    private displayModeSubscription: Subscription;
    private deliveryLocationSubscription: Subscription;

    public showMapIndication = new BehaviorSubject<boolean>(false);

    constructor(
        public appService: AppService,
        public orderTrackerService: OrderTrackerService,
        private activatedRoute: ActivatedRoute,
        private ngZone: NgZone,
        private notificationsService: NotificationsService,
        private organizationsService: OrganizationsService,
        private changeDetectorRef: ChangeDetectorRef,
        private configurationsService: ConfigurationsService,
        private userReviewsService: UserReviewsService,
        private storageService: StorageService,
    ) {
        this.orderAnimationPaths = this.orderTrackerService.getOrderAnimations();
    }

    ngOnInit() {
        this.notificationsService.setNotificationWidgetPause(true);
        this.notificationsService.setIsOpened(false); //To close the widget in case it was opened

        this.showHomePageRedirect = window['cordova'] || ['en-US', 'en-AU'].includes(this.appService.localeId) ? false : true;

        // 2020-11-05 - If the user clicked the Smart App Banner - and he's not logged in - we should show STILL him the Tracker, just like we do on the Web...
        this.urlSubscription = this.activatedRoute.queryParams.subscribe(params => {
            const paramValues = params;
            if (isEmpty(paramValues)) {
                this.errorHandler('No paramValues provided');
            }
            if (paramValues) {
                this.orderId = paramValues['order-id'];
                this.orgId = paramValues['organization-id'];
                this.orderTrackerService.setDisplayMode(paramValues['displayMode'] || 'full');
                // We don't want the user to get notifications about the order when the tracker is disabled
                this.displayModeSubscription = this.orderTrackerService.displayMode.subscribe(mode => {
                    if (mode == 'summary') {
                        this.primaryStep = MAIN_TABS.SUMMARY;
                    } else {
                        this.notificationsService.deviceId.subscribe(deviceId => {
                            if (deviceId) this.notificationsService.connectDeviceToOrders(deviceId, [this.orderId]);
                        })
                    }
                })

                this.organizationSubscription = this.organizationsService.getFullOrganization(this.orgId, true).subscribe(org => {
                    this.org = org;
                    this.changeDetectorRef.detectChanges();
                }, err => this.errorHandler(`Can't find organization: ${err}`));

                this.imHereCaption = this.orderTrackerService.checkIfImHerePressed(this.orderId) ? 'update_notes' : 'hey_im_here';
                this.showImHereExplanation = this.imHereCaption === 'update_notes' ? false : true;

                // logger
                this.buildAndSendLog(0);
            };

            this.getData();

            // logger
            this.buildAndSendLog(0);

            this.changeDetectorRef.detectChanges();
        });

        this.fireBaseRawNotificationSubscription = this.notificationsService.fireBaseRawNotification.subscribe((message: UnifiedMessagePayload) => {
            this.changeStepBylifeCycle(message.data.lifeCycleName, !!message.data.deliveredAt, this.delays, message.data.event);
        }, error => {
            console.error('Order Tracker subscribe to raw firebase messages error:', error);
        });

        this.isPushNotificationsDisabledSubscription = this.appService.isPushNotificationsDisabled.subscribe(disabled => {
            this.getData();
            if (disabled) this.initiatePolling(true);
            this.changeDetectorRef.detectChanges();
        })

    }

    ngOnDestroy() {
        this.urlSubscription.unsubscribe();
        if (this.organizationSubscription) this.organizationSubscription.unsubscribe();
        if (this.displayModeSubscription) this.displayModeSubscription.unsubscribe();
        if (this.orderSubscription) this.orderSubscription.unsubscribe();
        if (this.reviewsSubscription) this.reviewsSubscription.unsubscribe();
        if (this.deliveryLocationSubscription) this.deliveryLocationSubscription.unsubscribe();
        this.fireBaseRawNotificationSubscription.unsubscribe();
        if (this.isPushNotificationsDisabledSubscription) this.isPushNotificationsDisabledSubscription.unsubscribe();
        this.notificationsService.setNotificationWidgetPause(false);
        this.appService.confirmBeforeRedirect = false;
        this.resetTimers();
        if (this.pollingInterval) clearInterval(this.pollingInterval);
        if (this.locationInterval) clearInterval(this.locationInterval);
    }

    setSecondaryStep(step: number) {
        this.secondaryStep = step;
        this.changeDetectorRef.detectChanges();

        if (step < ORDER_STEPS.ORDER_THUMBS_UP) { // For submit note and Error step we handle separately
            // logger
            this.buildAndSendLog(step);
        }
    }

    _redirectToUserReview() {
        const params = {
            'user-id': get('this.appService.user.loyaltyCustomer', 'CustomerId'),
            'user-name': this.user.name,
            'mobile': this.user.mobile,
            'organization-id': this.org._id,
            'organization-name': this.org.name,
            'order-id': this.orderId,
            'date': moment().toISOString(),
            'referrer': this.userReviewReferrer,
            'from-order': true,
        }

        return this.appService.redirect([`/${this.redirectSource}-user-review`], { queryParams: params });
    }

    _redirectToNotesInput(originStep: number) {
        this.resetTimers();
        this.stepAfterNotes = originStep;
        const diffFromCreated = moment().diff(this.order?.created, 'minutes');
        const confirmationMessage = diffFromCreated <= this.diffInMinutesToShowYouSurePopup ? 'im_here_confirmation_hurry_message' : 'im_here_confirmation_message'

        if (this.configuration?.findYouInformationActive && (diffFromCreated > this.diffInMinutesToShowYouSurePopup || this.imHereCaption == 'update_notes')) {
            this.setSecondaryStep(9);
        } else {
            if (!this.appService.isMessageDialogOpen && !this.appService.paused) {
                this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
                    this.appService.mainMessage({
                        dialogType: 'info',
                        dialogTitle: 'order_tracker.im_here_confirmation_title',
                        dialogText: `order_tracker.${confirmationMessage}`,
                        primaryButtonText: 'order_tracker.im_here_confirm',
                        secondaryButtonText: 'order_tracker.im_here_cancel'
                    }).then(response => {
                        this.configuration?.findYouInformationActive ? this.setSecondaryStep(9) : this.submitNotes();
                    }).catch(err => {
                        if (!err?.secondaryButtonClick) console.error('=== ORDER-TRACKER/COMPONENT: Error with informing im here ===');
                    });
                });
            }
        }
    }

    _redirectToHomePage() {
        const tabitWebURL = this.appService.appConfig.tabitWebURL;
        window.open(tabitWebURL);
    }

    submitNotes() {
        this.orderTrackerService.sendOrdererNotes(this.mainNote, this.orgId, this.orderId, this.locationDetailsNote)
        .subscribe(order => {
            console.debug('*** ORDER-TRACKER/order ***', order);
        }, err => {
            console.error('*** ORDER-TRACKER - Error savings notes ***', err.error);
            // logger
            this.buildAndSendLog(ORDER_STEPS.ORDER_ERROR,err.error);
        });
        this.orderTrackerService.setImHere(this.orderId);
        this.imHereCaption = 'update_notes';
        this.showImHereExplanation = false;

        // logger
        this.buildAndSendLog(ORDER_STEPS.ORDER_THUMBS_UP, this.mainNote);

        this.setSecondaryStep(ORDER_STEPS.ORDER_THUMBS_UP);
    }

    returnToStepBeforeNotes() {
        if (this.stepAfterNotes < ORDER_STEPS.ORDER_TA_READY) {
            this.manageOrderStepsByTime(this.firedTime);
        } else {
            this.setSecondaryStep(this.stepAfterNotes);
        }
        this.getData();
    }

    initiatePolling(rejected: boolean) {
        if (this.pollingInterval) clearInterval(this.pollingInterval);
        this.pollingInterval = setInterval(() => this.getData(), 10 * this.secondsMultiplierValue);
    }

    isShowMapIndication() {
        const isShow =  (
            this.configuration?.showMap &&
            this.secondaryStep == 7 &&
            (this.coordinates.lat && this.coordinates.lng)
        ) ? true : false;

        this.showMapIndication.next(isShow);
    }

    private manageOrderStepsByTime(lifeCycleTime: moment.Moment) {
        this.resetTimers();

        const orderDiffInMilliSeconds = moment().diff(lifeCycleTime, 'seconds') * this.secondsMultiplierValue;
        const numberOfMilliSecondsToOpened = this.delays.openedInSeconds * this.secondsMultiplierValue;
        const numberOfMilliSecondsToPreparing = this.delays.preparingInSeconds * this.secondsMultiplierValue;

        if (this.secondaryStep === ORDER_STEPS.ORDER_LOADED) this.setSecondaryStep(ORDER_STEPS.ORDER_CONFIRMED);

        if (orderDiffInMilliSeconds < numberOfMilliSecondsToOpened) {
            /* This would occur only on init and only if the customer won't close the app
            Otherwise, the step change would occur by orderDiffInMinutes only, without timeout
            */
            this.firedTimeouts.push(    setTimeout(() => this.setSecondaryStep(ORDER_STEPS.ORDER_BEING_PREPARED), (numberOfMilliSecondsToOpened - orderDiffInMilliSeconds)),
                                        setTimeout(() => this.setSecondaryStep(ORDER_STEPS.ORDER_IN_PROGRESS), (numberOfMilliSecondsToPreparing - orderDiffInMilliSeconds)));
        }

        if (orderDiffInMilliSeconds >= numberOfMilliSecondsToOpened && orderDiffInMilliSeconds < numberOfMilliSecondsToPreparing) {
            this.setSecondaryStep(ORDER_STEPS.ORDER_BEING_PREPARED);
            this.firedTimeouts.push(setTimeout(() => this.setSecondaryStep(ORDER_STEPS.ORDER_IN_PROGRESS), (numberOfMilliSecondsToPreparing - orderDiffInMilliSeconds)));
        }

        if (orderDiffInMilliSeconds >= numberOfMilliSecondsToPreparing) {
            this.setSecondaryStep(ORDER_STEPS.ORDER_IN_PROGRESS);
        }
    }

    private resetTimers() {
        if (this.firedTimeouts && this.firedTimeouts.length) {
            this.firedTimeouts.forEach(timeout => {
                clearTimeout(timeout);
            });

            this.firedTimeouts = [];
        }
    }

    private getData() {
        if (this.secondaryStep === ORDER_STEPS.ORDER_IM_HERE) return;
        if (this.orderSubscription) this.orderSubscription.unsubscribe();
        this.orderSubscription = combineLatest([
            this.orderTrackerService.getOrderById(this.orderId, this.orgId),
            this.configurationsService.getOrganizationConfig(this.orgId, 'orderTracker'),
        ]).subscribe(([order, configuration]) => {
            // console.log('=== ORDER-TRACKER | getData ===', order, configuration);
            if (!order) this.errorHandler('Error retrieving order details from ROS: Maybe the order is closed');
            this.setUserData(order);
            this.order = order;
            this.service = order.serviceType;
            this.courier = order.courier;
            this.orderNumber = order.number;
            this.configuration = configuration;
            this.delays = configuration.delays;
            this.diffInMinutesToShowYouSurePopup = configuration.diffInMinutesToShowYouSurePopup;

            this.reviewsSubscription = this.userReviewsService.getUserReviews(this.user.mobile).subscribe(reviews => {
                let review = reviews.find(review => review.orderId == this.orderId);
                this.orderReviewd = !!review;
            })

            // We must override imHereButtonActive to remove the button with *ngIf and prevent adding a duplicate boolean variable
            if (this.orderTrackerService.checkIfImHerePressed(this.orderId) && !this.configuration?.findYouInformationActive) this.configuration.imHereButtonActive = false;

            if (order.lifeCycle.name === 'fired') this.firedTime = order.lifeCycle.dateTime ? moment(order.lifeCycle.dateTime) : moment();
            if (order.lifeCycle.name === 'taken') this.takenTime = order.lifeCycle.dateTime ? moment(order.lifeCycle.dateTime) : moment();

            if (this.checkIfGetDeliveryNote(order)) {
                this.mainNote = order.orderer.deliveryAddress.notes.replace(/(\[.*?\])/g, '').trim(); //Remove the hour
                this.locationDetailsNote = ''; //So all the notes would appear at the upper area only
            }

            if (order && this.org && window['cordova']) this.saveOrderAtLocalStorage(order, this.org);

            this.changeStepBylifeCycle(order.lifeCycle.name, !!(order.delivered && order.delivered.at), this.delays);

            this.changeDetectorRef.detectChanges();

        }, (err) => {
            this.errorHandler(`Error retrieving order details from ROS: ${err}`);
        });
    }

    private changeStepBylifeCycle(lifeCycle: string, delivered: boolean, delays: any, event?: string) {
        this.resetTimers();

        if (this.secondaryStep === ORDER_STEPS.ORDER_IM_HERE) return;

        if (lifeCycle === 'opened') {
            this.setSecondaryStep(ORDER_STEPS.ORDER_CONFIRMED);
            return;
        }

        if (lifeCycle === 'fired') {
            this.manageOrderStepsByTime(this.firedTime);
            return;
        }

        if (lifeCycle === 'prepared') {
            if (this.service === 'takeaway') {
                this.setSecondaryStep(ORDER_STEPS.ORDER_TA_READY);
            } else { // Delivery
                this.setSecondaryStep(ORDER_STEPS.ORDER_DELIVERY_ALMOST_READY);
            }
            return;
        }

        if (lifeCycle === 'taken') {
            if (this.service === 'takeaway') {
                if (this.pollingInterval) clearInterval(this.pollingInterval);
                this.setSecondaryStep(ORDER_STEPS.ORDER_TA_TAKEN);
            } else { // Delivery
                if (this.secondaryStep == ORDER_STEPS.ORDER_DELIVERED) return;
                if (!delivered) {
                    if (this.order && this.configuration?.showMap) this.initCourierLocationPolling();
                    this.setSecondaryStep(ORDER_STEPS.ORDER_DELIVERY_ON_ITS_WAY);
                    const orderDiffInSeconds = moment().diff(this.takenTime, 'seconds') * this.secondsMultiplierValue;
                    const numberOfMilliSecondsToDelivered = delays.deliveredInSeconds * this.secondsMultiplierValue;
                    if (orderDiffInSeconds < numberOfMilliSecondsToDelivered) {
                        this.deliveredTimeout = setTimeout(() => this.setSecondaryStep(ORDER_STEPS.ORDER_DELIVERED), (numberOfMilliSecondsToDelivered - orderDiffInSeconds));
                        return;
                    }
                } else {
                    clearTimeout(this.deliveredTimeout);
                    if (this.locationInterval) clearInterval(this.locationInterval);
                    if (this.pollingInterval) clearInterval(this.pollingInterval);
                }

                this.setSecondaryStep(ORDER_STEPS.ORDER_DELIVERED);
                this.isShowMapIndication();

            }

            return;

        }
    }

    private initCourierLocationPolling() {
        const deliveryServiceURL = this.appService.appConfig.deliveryServiceURL;

        if (deliveryServiceURL) {
            // Only initial location (explanation at the actual function)
            this.setInitialLocation(deliveryServiceURL);
            //**********************************************************/
            this.locationInterval = setInterval(() => {
                this.deliveryLocationSubscription = this.orderTrackerService.getCourierLocation(this.orderId, this.orgId, this.courier).subscribe(res => {
                    if (res?.coordinates) {
                        this.setMapIndication(res.coordinates);
                    }
                })
            }, (60 * this.secondsMultiplierValue));
        }
    }

    private setInitialLocation(url) {
        // Here we want to take only initial location in order to prevent the user to wait a minute
        // until the map indication tab would appear (even though there is actual location at the server)
        this.orderTrackerService.getCourierLocation(this.orderId, this.orgId, this.courier)
        .pipe(take(1))
        .subscribe(res => {
            if (res?.coordinates) {
                this.setMapIndication(res.coordinates);
            }
        })
    }

    private setMapIndication(coordinates) {
        this.coordinates = coordinates;
        this.isShowMapIndication();
    }

    private errorHandler(err) {
        // logger
        this.buildAndSendLog(ORDER_STEPS.ORDER_ERROR, err);

        this.setSecondaryStep(ORDER_STEPS.ORDER_ERROR); // Error step
        throw new Error(err);
    }

    private checkIfGetDeliveryNote(order: any): boolean {
        const source = order.source;
        if (((source.indexOf('restaurant') < 0) || this.orderTrackerService.checkIfImHerePressed(this.orderId)) && order.orderer.deliveryAddress.notes) {
            return true;
        }
        return false;
    }

    private setMessageForLogger(step: number): string {
        let message: string = '';

        switch (step) {
            // LANDING PAGE - LOADING ORDER
            case ORDER_STEPS.ORDER_LOADED:
                message = 'Tracker - Loading order';
                break;
            // DELIVERY/TA CONFIRMED
            case ORDER_STEPS.ORDER_CONFIRMED:
                message = 'Tracker - Order Confirmed';
                break;
            // DELIVERY/TA BEING PREPARED
            case ORDER_STEPS.ORDER_BEING_PREPARED:
                message = 'Tracker - Order being prepared';
                break;
            // DELIVERY/TA IN PROGRESS
            case ORDER_STEPS.ORDER_IN_PROGRESS:
                message = 'Tracker - Still being prepared';
                break;
            // TA READY
            case ORDER_STEPS.ORDER_TA_READY:
                message = 'Tracker - TA Ready';
                break;
            // TA TAKEN
            case ORDER_STEPS.ORDER_TA_TAKEN:
                message = 'Tracker - TA Taken';
                break;
            // ALMOST READY FOR DELIVERY
            case ORDER_STEPS.ORDER_DELIVERY_ALMOST_READY:
                message = 'Tracker - Ready for delivery';
                break;
            // ON IT'S WAY
            case ORDER_STEPS.ORDER_DELIVERY_ON_ITS_WAY:
                message = 'Tracker - On it\'s way';
                break;
            // DELIVERED
            case ORDER_STEPS.ORDER_DELIVERED:
                message = 'Tracker - Delivered';
                break;
            // I'M HERE
            case ORDER_STEPS.ORDER_IM_HERE:
                message = 'Tracker - I\'m here';
                break;
            // INSTRUCTIONS SUBMITTED
            case ORDER_STEPS.ORDER_THUMBS_UP:
                message = 'Tracker - Instructions submitted';
                break;
            // ERROR
            case ORDER_STEPS.ORDER_ERROR:
                message = 'Tracker - Error';
                break;
        }

        return message;
    }

    private buildAndSendLog(message, additional?: string): any {
        const logMessage = this.setMessageForLogger(message);
        const additionalData = additional ? additional : '';

        const tracker = {
            siteId: this.orgId,
            siteName: this.org?.name,
            orderId: this.orderId,
            orderNumber: this.orderNumber,
            serviceType: this.service,
            additionalData,
        };

        this.appService.sendLogger({ message: logMessage, tracker, module: 'tracker' });
    }

    private saveOrderAtLocalStorage(order: any, org: any) {
        const ordersFromLocalStorage = JSON.parse(`${this.appService.validateLocalStorageData('state__customer_orders')}`);
        const foundOrder = ordersFromLocalStorage.find(savedOrder => savedOrder._id === order._id)
        if (!foundOrder) {
            order.orgName = org.name;
            ordersFromLocalStorage.push(order);
            this.storageService.setItem('state__customer_orders', JSON.stringify(ordersFromLocalStorage || []));
        };
    }

    private setUserData(order) {
        this.user.name = order.orderer?.name,
        this.user.mobile = order.orderer?.phone,
        this.user.address = this.orderTrackerService.getUserAddress(order);
    }

    siteIcon(): google.maps.Icon {
        return {
            url: this.appService.images.his_delivery, 
            scaledSize: new google.maps.Size(32, 32)
        };
    }

}
