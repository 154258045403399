// Instead of polyfills.ts
import 'zone.js';
import '@angular/localize/init';
// import 'global-polyfill';
// ============================== //
import { enableProdMode, LOCALE_ID, Injectable, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import { provideClientHydration } from '@angular/platform-browser';
import { init as initApm } from '@elastic/apm-rum';

import localeHe from '@angular/common/locales/he';
import localeHeExtra from '@angular/common/locales/extra/he';

import localeAr from '@angular/common/locales/ar-IL';
import localeArExtra from '@angular/common/locales/extra/ar-IL';

import localeRu from '@angular/common/locales/ru';
import localeRuExtra from '@angular/common/locales/extra/ru';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeHe, 'he', localeHeExtra);
registerLocaleData(localeAr, 'ar-IL', localeArExtra);
registerLocaleData(localeRu, 'ru', localeRuExtra);

import { environment } from './environments/environment';
import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { MarkdownModule, MARKED_OPTIONS, MarkedRenderer } from 'ngx-markdown';
import { appStrings } from './app/_core/static/translations';
import { Observable } from 'rxjs';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { withInterceptorsFromDi, provideHttpClient, HttpClient } from '@angular/common/http';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireModule } from '@angular/fire/compat';
import { SharedModule } from './app/_core/shared.module';
import { CoreModule } from './app/_core/core.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AccessabilitySupportModule } from './app/accessability/accessability-support.module';
import { GoogleMapsLoaderService } from './app/_core/google-maps-loader.service';
import { WebArticle } from './app/web-container/web-article/web-article';
import { DatePipe, DecimalPipe, CurrencyPipe } from '@angular/common';
import { HttpInterceptorProviders } from './app/_core/refresh-token-interceptor';
import { AppInitService } from './app/app-init.service';
import { HAMMER_GESTURE_CONFIG, HammerGestureConfig, BrowserModule, HammerModule, bootstrapApplication } from '@angular/platform-browser';
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { NoPreloading, provideRouter, withHashLocation, withPreloading, withViewTransitions } from '@angular/router';
import { MatDialogModule } from '@angular/material/dialog';

import {
    ToMoneyPipe,
    ToCardPipe,
    ToCurrencyInitialsPipe,
    ToPaymentExpiration,
    ToPhonePipe
} from './app/tabit-order/services/to.pipes';

class TranslationLoader implements TranslateLoader {
    getTranslation(lang: string) {
        // Condition satisfies upon page load. com.json is loaded.
        return new Observable(observer => {
            if (appStrings[lang]) {
                observer.next(appStrings[lang]);
                observer.complete();
            } else {
                observer.error('No app strings provided');
            }
        });
    };
}

// Markdown links are opened in a new window
function markedOptionsFactory(): any {
    const renderer = new MarkedRenderer();

    renderer.link = (href: string, title?: string, text?: string) => {
        return `<a href="${href}" ${(title ? `title="${title}"` : '')} target="${window['cordova'] ? '_system' : '_blank'}">${text}</a>`;
    };
    return { renderer };
}

function loadAfterBundle(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.Init();
    }
}
@Injectable()
class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        'pinch': { enable: false },
        'rotate': { enable: false },
    }
}

const globalRippleConfig: RippleGlobalOptions = {
    disabled: true,
    animation: {
        enterDuration: 0,
        exitDuration: 0
    }
};

const protocol = 'https://';

initApmAgent();

if (environment.production) {
    enableProdMode();
}

const bootstrap = () => {
    bootstrapApplication(AppComponent, {
        providers: [
            provideRouter(
                routes,
                withViewTransitions(),
                withPreloading(NoPreloading),
                ...(environment.appConfig.useHash ? [withHashLocation()] : [])
            ),
            provideClientHydration(), // Ensure this is included
            provideHttpClient(withInterceptorsFromDi()),
            provideAnimations(),
            importProvidersFrom(
                BrowserAnimationsModule,
                AccessabilitySupportModule,
                BrowserModule,
                CoreModule,
                SharedModule,
                AngularFireModule.initializeApp(environment.appConfig.firebaseConfig),
                AngularFireMessagingModule,
                HammerModule,
                GoogleMapsModule,
                FormsModule,
                ReactiveFormsModule,
                MatMomentDateModule,
                MatDialogModule,
                TranslateModule.forRoot({
                    loader: {
                        provide: TranslateLoader,
                        useClass: TranslationLoader,
                        deps: [HttpClient]
                    }
                }),
                MarkdownModule.forRoot({
                    markedOptions: {
                        provide: MARKED_OPTIONS,
                        useFactory: markedOptionsFactory,
                    },
                })
            ),
            { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
            { provide: LOCALE_ID, useValue: environment.appConfig['locale'] },
            { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
            { provide: APP_INITIALIZER, useFactory: loadAfterBundle, deps: [AppInitService], multi: true },
            HttpInterceptorProviders,
            DatePipe,
            DecimalPipe,
            CurrencyPipe,
            GoogleMapsLoaderService,
            ToMoneyPipe,
            ToCardPipe,
            ToCurrencyInitialsPipe,
            ToPaymentExpiration,
            ToPhonePipe,
            WebArticle
        ]
    })
    .catch(err => console.debug(err));
};

function setHostForSubDomain() {
    // First we'll check if the string start is similar to the url itself
    const subDomain = window.location.hostname.match(/([^.]+).+/)[1];
    if (isNotSubDomain(subDomain)) {
        // We are in the root domain. So we redirect to https://tabitisrael.co.il (in IL) or https://tabit.us/ (in US)
        // Example: https://mytabit.co.il/ => https://tabitisrael.co.il/
        // Example: https://mytabit.com/ => https://tabit.us/
        window.location.href = protocol + getHostNameByLocale();
    } else {
        // We have sub-domain
        // Example: https://kisu.mytabit.co.il => https://kisu.mytabit.co.il/tabit-order/kisu
        // Example: https://kisu.mytabit.com => https://kisu.mytabit.com/tabit-order/kisu
        window.location.href = protocol + window.location.hostname + '/tabit-order/' + subDomain;
        console.debug('=== Sub domain redirect to: ', window.location.href);
    }
}

function getHostNameByLocale() {
    const url = (/co.il/.test(window.location.hostname)) ? 'tabitisrael.co.il' : 'tabit.us';
    return url;
}

function isNotSubDomain(pattern): boolean {
    // Example: https://mytabit.co.il => sub-domain = mytabit
    // If mytabit includes in the possible hosts array then it's not a sub-domain
    // On the contrary, if https://kisu.mytabit.co.il => sub-domain = kisu
    // Kisu won't be included in the possible hosts, so it's a sub-domain
    const possibleHostsWithousPrefix = ['tabitisrael', 'tabit', 'mytabit'];
    return possibleHostsWithousPrefix.includes(pattern);
}

// check if this instance of app is within an iframe, meaning there was an iframe redirect from an external payment window
if (window.parent?.window) {
    console.debug('=== MAIN.TS | EVENT DETECTED ===', window.location?.href);
    window.parent.window.postMessage({
        iframeEvent: 'sameHostIframeLoaded',
        fullUrl: window.location?.href
    }, '*')
}

function initApmAgent() {
    try {
        initApm({
            // Set custom APM Server URL (default: http://localhost:8200)
            serverUrl: environment.appConfig.apmServer,
            serviceName: 'tabit-app',
            // Set the service version (required for source map feature)
            serviceVersion: environment.appConfig.version,
            // Set the service environment
            environment: environment.env,
            // Needed for managing sample rate
            centralConfig: true,
            // This is the default value; here just for potentially disabling
            distributedTracing: true,
            // Adds trace headers to requests to the following hosts
            distributedTracingOrigins: [/^.*\.(tabit-dev\.com|tabit-int\.com|tabit-stage\.com|tabit\.cloud)$/]
        });
    } catch (err) {
        console.debug('Error in initApmAgent');
    }

    console.debug('initApmAgent Occurred');
}

if (typeof window['cordova'] !== 'undefined') {
    document.addEventListener('deviceready', () => {
        bootstrap();
        // Marking the App as "loaded" for the Cordova Wrapper
        window['appAlreadyLoaded'] = true;
    }, false);
} else {
    // If we are not on "https://tabitisrael.co.il/" we redirect there.
    if (window.location.hostname == 'www.tabitisrael.co.il' || window.location.hostname == 'www.tabitisrael.com' || window.location.hostname == 'tabitisrael.com') {
        window.location.href = protocol + 'tabitisrael.co.il' + window.location.pathname + window.location.search + window.location.hash;
    }

    // Redirect logic for sub-domain
    if (/mytabit/.test(window.location.hostname) && !/tabit-order/.test(window.location.href)) {
        setHostForSubDomain();
    }

    bootstrap();
}