<div class="site-buttons-container" [ngStyle]="{ 'margin-bottom': bookService.orgTimeSlots[site._id]?.length ? '0.5rem' : 'unset'}">

    <!-- upper buttons row -->
    <div class="action-buttons" >
        <ng-container *ngFor="let siteButton of orderedButtonsByIndex | slice:0:4 | orderBy: 'index'; let index=index;">
            <ng-container
                [ngTemplateOutlet]="getButtonTemplateReference(siteButton.name)"
                *ngIf="!siteButton.hidden"
            ></ng-container>
        </ng-container> 
    </div>

    <!-- lower buttons row -->
    <div class="additional-buttons">
        <ng-container *ngFor="let siteButton of orderedButtonsByIndex | slice:4:7 | orderBy: 'index'; let index=index;">            
            <ng-container 
                [ngTemplateOutlet]="getButtonTemplateReference(siteButton.name)"
                *ngIf="!siteButton.hidden"
            ></ng-container>
        </ng-container>
    </div>
</div>

<div class="location-widget-wrapper" *ngIf="!bookService.orgTimeSlots[site._id]?.length">
    <!-- Decided to not use houseRequired, and return premise addresses -->
    <location-widget [houseRequired]="false"></location-widget>
</div>

<div class="booking-container" *ngIf="bookService.orgTimeSlots[site._id]?.length" accessible>
    <h3 class="title">{{ 'SERVICE_BUTTON.book' | translate }}</h3>
    <p>{{ requestedDate | date }}, {{ getDinersString() | translate}}</p>
    <time-slots [siteId]="site._id" [timeSlots]="bookService.orgTimeSlots[site._id]" [areaDescriptions]="areaDescriptions"></time-slots>
</div>

<!-- * START site service buttons templates -->

<ng-template #pay>
    <div
        role="link"
        [attr.aria-disabled]="[buttonOff, buttonSemi].includes(buttonStates.pay) ? true : false"
        ng-star-inserted
        *ngIf="buttonStates.pay && noOrgTimeSlotsLength"
        class="site-button"
        [class.grayed-out]="[buttonOff, buttonSemi].includes(buttonStates.pay)"
        [class.has-label]="buttonStates.pay !== buttonOn"
        (click)="onButtonClick('pay')"
        accessible
    >
        <div class="action-title-container">
            <div class="action-title">
                <mat-icon *ngIf="buttonStates.pay !== buttonOn" svgIcon="brand-site-pay"></mat-icon>
                <mat-icon *ngIf="buttonStates.pay === buttonOn" svgIcon="brand-site-pay-active"></mat-icon>
                <div class="action-label text-truncate" [attr.aria-label]="'SERVICE_BUTTON.pay_generic' | translate">{{ 'SERVICE_BUTTON.pay_generic' | translate }}</div>
                <div *ngIf="buttonStates.pay === buttonOff" class="action-disabled"><span class="wl-app-primary-background-color" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span></div>
                <div *ngIf="buttonStates.pay === buttonSemi" class="action-disabled"><span class="wl-app-primary-background-color" [attr.aria-label]="'not_available_now' | translate">{{ 'not_available_now' | translate }}</span></div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #takeaway>
    <div
        role="link"
        [attr.aria-disabled]="[buttonOff, buttonSemi].includes(buttonStates.takeaway) ? true : false"
        *ngIf="buttonStates.takeaway && noOrgTimeSlotsLength"
        class="site-button"
        [class.grayed-out]="[buttonOff, buttonSemi].includes(buttonStates.takeaway)"
        [class.has-label]="buttonStates.takeaway !== buttonOn || (buttonStates.takeaway === buttonOn && takeawayPreorderBadge)"
        (click)="onButtonClick('takeaway')"
        accessible
    >
        <div class="action-title-container">
            <div class="action-title">
                <!-- icon -->
                <mat-icon *ngIf="buttonStates.takeaway !== buttonOn" svgIcon="brand-site-order-ta"></mat-icon>
                <mat-icon *ngIf="buttonStates.takeaway === buttonOn" svgIcon="brand-site-order-ta-active"></mat-icon>
                <!-- label -->
                <div class="action-label text-truncate" [attr.aria-label]="getButtonCaptions('takeaway') | translate">{{ getButtonCaptions('takeaway') | translate}}</div>
                <div class="action-label future-label text-truncate" *ngIf="activeBadges.takeaway == 'future_order' && !isFutureSeparateButton()" [attr.aria-label]="getButtonCaptions('order_delay') | translate">{{ getButtonCaptions('order_delay') | translate }}</div>

                <!-- badges -->
                <div *ngIf="buttonStates.takeaway === buttonOff" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.takeaway == 'not_available'" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span>
                </div>
                <div *ngIf="buttonStates.takeaway === buttonSemi" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.takeaway == 'not_available_now' || isTakeawayAvailableOnlyOnFuture('takeaway')" [attr.aria-label]="'not_available_now' | translate">{{ 'not_available_now' | translate }}</span>
                    <!-- <span class="wl-app-primary-background-color" *ngIf="isShowTakeawayBadge('available_from')">{{ 'available_from' | translate }} {{ takeawayStartFromBadge | date: 'H:mm' | AMPMConverter }}</span> -->
                </div>
                <div *ngIf="buttonStates.takeaway === buttonOn" class="action-disabled">
                    <span class="wl-app-primary-background-color"  *ngIf="activeBadges.takeaway == 'preorder'" [attr.aria-label]="'not_available_now' | translate">{{ 'not_available_now' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #delivery>
    <div
        role="link"
        [attr.aria-disabled]="[buttonOff, buttonSemi].includes(buttonStates.delivery) ? true : false"
        *ngIf="buttonStates.delivery && noOrgTimeSlotsLength"
        class="site-button"
        [class.grayed-out]="[buttonOff, buttonSemi].includes(buttonStates.delivery)"
        [class.has-label]="buttonStates.delivery !== buttonOn || (buttonStates.delivery === buttonOn && deliveryPreorderBadge)"
        (click)="onButtonClick('delivery')"
        accessible
    >
        <div class="action-title-container">
            <div class="action-title">
                <!-- icon -->
                <mat-icon *ngIf="buttonStates.delivery !== buttonOn" svgIcon="brand-site-order-delivery"></mat-icon>
                <mat-icon *ngIf="buttonStates.delivery === buttonOn" svgIcon="brand-site-order-delivery-active"></mat-icon>
                <!-- label -->
                <div class="action-label text-truncate" [attr.aria-label]="getButtonCaptions('delivery') | translate">{{ getButtonCaptions('delivery') | translate}}</div>
                <div class="action-label future-label text-truncate" *ngIf="activeBadges.delivery == 'future_order' && !isFutureSeparateButton()" [attr.aria-label]="getButtonCaptions('order_delay') | translate">{{ getButtonCaptions('order_delay') | translate }}</div>
                
                <!-- badges -->
                <div *ngIf="buttonStates.delivery === buttonOff" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.delivery == 'not_available'" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span>
                </div>
                <div *ngIf="buttonStates.delivery === buttonSemi" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.delivery == 'not_available_now'  || isTakeawayAvailableOnlyOnFuture('delivery')" [attr.aria-label]="'not_available_now' | translate">{{ 'not_available_now' | translate }}</span>
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.delivery == 'not_available_to_this_address'" [attr.aria-label]="'not_available_to_this_address' | translate">{{ 'not_available_to_this_address' | translate }}</span>
                    <!-- <span class="wl-app-primary-background-color" *ngIf="activeBadges.delivery == 'available_from'">{{ 'available_from' | translate }} {{ deliveryStartFromBadge | date: 'H:mm' | AMPMConverter }}</span> -->
                </div>
                <div *ngIf="buttonStates.delivery === buttonOn" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.delivery == 'preorder'" [attr.aria-label]="'preorder' | translate">{{ 'preorder' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #eatIn>
    <div
        role="link"
        [attr.aria-disabled]="[buttonSemi].includes(buttonStates.eatin) ? true : false"
        class="site-button"
        [class.grayed-out]="[buttonSemi].includes(buttonStates.eatin)"
        (click)="onButtonClick('eatin')"
        *ngIf="![buttonOff].includes(buttonStates.eatin)"
        accessible
    >
        <div class="action-title-container">
            <div class="action-title eatin">
                <!-- icon -->
                <mat-icon class="eatin" svgIcon="brand-additional-site-eatin-active"></mat-icon>
                <!-- label -->
                <div class="action-label text-truncate" [attr.aria-label]="getButtonCaptions('eatin') | translate">{{ getButtonCaptions('eatin') | translate }}</div>
                <div class="action-label future-label text-truncate" *ngIf="activeBadges.eatin == 'future_order'" [attr.aria-label]="getButtonCaptions('order_delay') | translate">{{ getButtonCaptions('order_delay') | translate }}</div>

                <!-- badges -->
                <div *ngIf="buttonStates.eatin === buttonSemi" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.eatin == 'not_available_now'" [attr.aria-label]="'not_available_now' | translate ">{{ 'not_available_now' | translate }}</span>
                </div>
                <div *ngIf="buttonStates.eatin === buttonOn" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.eatin == 'preorder'" [attr.aria-label]="'preorder' | translate">{{ 'preorder' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- future reservation -->
<ng-template #futureReservation>
    <div
        role="link"
        [attr.aria-disabled]="[buttonSemi, buttonOff].includes(buttonStates.future) ? true : false"
        *ngIf="showFutureOrderService()"
        class="site-button"
        [class.grayed-out]="[buttonSemi, buttonOff].includes(buttonStates.future)"
        (click)="futureServiceClick()"
        accessible
    >
        <div class="action-title-container">
            <div class="action-title future" [class.caption-only]="!futureOrderIcon">
                <!-- icon -->
                <mat-icon [ngStyle]="{visibility: futureOrderIcon ? 'visible' : 'hidden'}" [svgIcon]="futureOrderIcon"></mat-icon>
                <!-- label -->
                <div class="action-label" [attr.aria-label]="getButtonCaptions('order_delay') | translate">{{ getButtonCaptions('order_delay') | translate }}</div>
                
                <!-- badges -->
                <div *ngIf="buttonStates.future === buttonOff" class="action-disabled">
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.futureReservation == 'not_available'" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span>
                    <span class="wl-app-primary-background-color" *ngIf="activeBadges.futureReservation == 'not_available_to_this_address'" [attr.aria-label]="'not_available_to_this_address' | translate">{{ 'not_available_to_this_address' | translate }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- future booking -->
<ng-template #book>
    <ng-container *ngIf="appService.isApp">
        <div
            role="link"
            [attr.aria-disabled]="buttonStates.book === buttonSemi ? true : false"
            *ngIf="buttonStates.book != buttonOff && noOrgTimeSlotsLength"
            class="site-button"
            [class.grayed-out]="buttonStates.book === buttonSemi"
            [class.has-label]="buttonStates.book !== buttonOn"
            (click)="onButtonClick('book')"
            accessible
        >
            <div class="action-title-container">
                <div class="action-title">
                    <!-- icon -->
                    <mat-icon [svgIcon]="buttonStates.book == buttonOn ? 'brand-site-book-active' : 'brand-site-book'"></mat-icon>
                    <!-- label -->
                    <div class="action-label text-truncate" [attr.aria-label]="'SERVICE_BUTTON.book' | translate">{{ 'SERVICE_BUTTON.book' | translate }}</div>
                    <!-- badges -->
                    <div *ngIf="buttonStates.book === buttonSemi && activeBadges.book == 'not_available'" class="action-disabled">
                        <span class="wl-app-primary-background-color" [attr.aria-label]="'not_available' | translate ">{{ 'not_available' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-container *ngIf="!appService.isApp">
        <a
            role="link"
            [attr.aria-disabled]="buttonStates.book === buttonSemi ? true : false"
            *ngIf="buttonStates.book != buttonOff && noOrgTimeSlotsLength"
            class="site-button"
            [class.grayed-out]="buttonStates.book === buttonSemi"
            [class.has-label]="buttonStates.book !== buttonOn"
            (click)="navigateToBookingLink($event)"
            accessible
        >
            <div class="action-title-container">
                <div class="action-title">
                    <!-- icon -->
                    <mat-icon [svgIcon]="buttonStates.book == buttonOn ? 'brand-site-book-active' : 'brand-site-book'"></mat-icon>
                    <!-- label -->
                    <div class="action-label text-truncate" [attr.aria-label]="'SERVICE_BUTTON.book' | translate">{{ 'SERVICE_BUTTON.book' | translate }}</div>
                    <!-- badges -->
                    <div *ngIf="buttonStates.book === buttonSemi && activeBadges.book == 'not_available'" class="action-disabled">
                        <span class="wl-app-primary-background-color" [attr.aria-label]="'not_available' | translate ">{{ 'not_available' | translate }}</span>
                    </div>
                </div>
            </div>
        </a>
    </ng-container>
</ng-template>

<!-- waiting list (walked-in) -->
<ng-template #waitingList>
    <ng-container *ngIf="appService.isApp">
        <div
            role="link"
            [attr.aria-disabled]="[buttonSemi].includes(buttonStates.waitingList) ? true : false"
            *ngIf="buttonStates.waitingList != buttonOff"
            class="site-button"
            [class.grayed-out]="[buttonSemi].includes(buttonStates.waitingList)"
            (click)="waitingListServiceClick()"
            accessible
        >
            <div class="action-title-container">
                <div class="action-title future">
                    <!-- icon -->
                    <mat-icon [svgIcon]="buttonStates.waitingList == buttonOn ? 'brand-additional-site-waiting-list-active' : 'brand-additional-site-waiting-list'"></mat-icon>
                    <!-- label -->
                    <div class="action-label" [attr.aria-label]="getButtonCaptions('waiting_list') | translate">{{ getButtonCaptions('waiting_list') | translate }}</div>
                    
                    <!-- badges -->
                    <div *ngIf="activeBadges.waitingList == 'not_available'" class="action-disabled">
                        <span class="wl-app-primary-background-color" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="!appService.isApp">
        <a
            role="link"
            [attr.aria-disabled]="[buttonSemi].includes(buttonStates.waitingList) ? true : false"
            *ngIf="buttonStates.waitingList != buttonOff"
            class="site-button"
            [class.grayed-out]="[buttonSemi].includes(buttonStates.waitingList)"
            (click)="waitingListServiceClick()"
            accessible
        >
            <div class="action-title-container">
                <div class="action-title future">
                    <!-- icon -->
                    <mat-icon [svgIcon]="buttonStates.waitingList == buttonOn ? 'brand-additional-site-waiting-list-active' : 'brand-additional-site-waiting-list'"></mat-icon>
                    <!-- label -->
                    <div class="action-label" [attr.aria-label]="getButtonCaptions('waiting_list') | translate">{{ getButtonCaptions('waiting_list') | translate }}</div>
                    
                    <!-- badges -->
                    <div *ngIf="activeBadges.waitingList == 'not_available'" class="action-disabled">
                        <span class="wl-app-primary-background-color" [attr.aria-label]="'not_available' | translate">{{ 'not_available' | translate }}</span>
                    </div>
                </div>
            </div>
        </a>
    </ng-container>
</ng-template>

<!-- * END site service buttons templates -->