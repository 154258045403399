import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { AppService } from '../app.service';

import mixpanel from 'mixpanel-browser';

export const CLIENT_TYPE = {
    RSV: 'RSV',
    GCS: 'GCS',
    ORD: 'ORD',
    PAY: 'PAY',
    APP: 'APP',
};

export enum MIXPANL_GENERAL_EVENT_TYPE {
    otp_inserted,
    logged_in,
    registration_opened,
    user_registered,
    registration_abandoned,
}

export const mixpanelGeneralEventTypesMap = {
    [MIXPANL_GENERAL_EVENT_TYPE.otp_inserted]: 'Connect to Tabit OTP Inserted',
    [MIXPANL_GENERAL_EVENT_TYPE.logged_in]: 'User Logged In',
    [MIXPANL_GENERAL_EVENT_TYPE.registration_opened]: 'Registration Dialog Opened',
    [MIXPANL_GENERAL_EVENT_TYPE.user_registered]: 'User Registered',
    [MIXPANL_GENERAL_EVENT_TYPE.registration_abandoned]: 'Registration Abandoned',
}

@Injectable({
    providedIn: 'root'
})

export class MixpanelService {

    appConfig: any = environment.appConfig;
    private initialized: boolean = false;

    constructor(
        private appService: AppService
    ) { }

    public initialize() {
        if (this.initialized) return;

        try {
            mixpanel.init(this.appConfig.mixpanel.projectToken, { persistence: 'localStorage' });
            this.initialized = true;
        } catch (err) {
            console.error('Mixpanel init error, not running', err);
        }

    };

    public track(eventName: string, data: any = {}) {
        if (!this.appConfig.mixpanel?.enabled) return;
        if (!this.initialized) this.initialize();

        const additionalData = {
            device_type: this.appService?.detectDevice(),
            version: this.appConfig?.version,
            device_id: this.appService.clientId,
            'Platform Type': window['cordova'] ? 'App' : 'Web'
        }

        try {
            mixpanel.track(eventName, Object.assign(data, additionalData));
        } catch (err) {
            console.error('Mixpanel track error', err);
        }
    }

    
}