import { Component, OnInit, OnDestroy, Input, NgZone, TemplateRef, ViewChild } from '@angular/core';
import { BookService } from '../../_core/book.service';
import { SearchDetails } from '../../_core/OrganizationBucket';
import { EntityUtilsService } from '../../_core/entity.utils.service';
import { DialogsService } from '../../_core/dialogs.service';
import { AppService } from '../../app.service';

import { get, cloneDeep, orderBy } from 'lodash-es';
import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { MatIcon } from '@angular/material/icon';
import { TimeSlotsComponent } from '../time-slots/time-slots.component';
import { AccessibleDirective } from '../../accessability/accessible/accessible.directive';
import { LocationWidgetComponent } from '../location-widget/location-widget.component';
import { NgStyle, NgFor, NgIf, NgTemplateOutlet, SlicePipe, DatePipe } from '@angular/common';

enum BUTTON_STATES { on = 1, semi = 2, off = 3 }; // Starting from 1 to have truthy values
interface ButtonsStates { [key: string]: BUTTON_STATES };

@Component({
    selector: 'site-actions',
    templateUrl: './site-actions.component.html',
    styleUrls: ['./site-actions.component.scss'],
    standalone: true,
    imports: [NgStyle, NgFor, NgIf, NgTemplateOutlet, LocationWidgetComponent, AccessibleDirective, TimeSlotsComponent, MatIcon, SlicePipe, DatePipe, OrderModule, TranslateModule]
})
export class SiteActionsComponent implements OnInit, OnDestroy {

    @Input() disablePay: boolean = false;
    @Input() set site(site: any) {
        this._site = site;
        this.serviceInDetails = get(this.site, 'servicesInDetails');
        this.areaDescriptions = get(this.site, `bookingData.strings`, null);
        if (!this.beforeInit) this.calculateButtonsState(site);
    }
    get site(): any { return this._site; }

    private _site: any = null;
    private domainSubscription: Subscription;
    private beforeInit: boolean = true;

    public domain: any;
    public windowRef: any = window || {};
    public futureOrderIcon: string;
    public areaDescriptions: any = null;

    requestedDate: any;
    serviceInDetails: any;
    buttonStates: ButtonsStates = {};
    buttonOn: BUTTON_STATES = BUTTON_STATES.on;
    buttonOff: BUTTON_STATES = BUTTON_STATES.off;
    buttonSemi: BUTTON_STATES = BUTTON_STATES.semi;

    eatinPreorderBadge: boolean = false;
    deliveryPreorderBadge: boolean = false;
    takeawayPreorderBadge: boolean = false;
    deliveryStartFromBadge: Date | null = null;
    takeawayStartFromBadge: Date | null = null;

    futureDeliveryStartFromBadge: Date;
    futureTakeawayStartFromBadge: Date;
    futureEatinStartFromBadge: Date;

    buttonCaptions: any = {};
    bookingNavigationLink: any;

    @ViewChild('book') book: TemplateRef<any>;
    @ViewChild('pay') pay: TemplateRef<any>;
    @ViewChild('takeaway') takeaway: TemplateRef<any>;
    @ViewChild('delivery') delivery: TemplateRef<any>;
    @ViewChild('eatIn') eatIn: TemplateRef<any>;
    @ViewChild('futureReservation') futureReservation: TemplateRef<any>;
    @ViewChild('waitingList') waitingList: TemplateRef<any>;

    // set an initial buttons order
    siteServiceButtonsOrder: Array<{
        name: string,
        index: number,
        elementRef?: any
    }> = [
        {
            name: 'book',
            index: 0,
        },
        {
            name: 'pay',
            index: 1,
        },
        {
            name: 'takeaway',
            index: 2,
        },
        {
            name: 'delivery',
            index: 3,
        },
        {
            name: 'eatIn',
            index: 4,
        },
        {
            name: 'futureReservation',
            index: 5,
        },
        {
            name: 'waitingList',
            index: 6
        },
    ];

    activeBadges: {
        takeaway?: 'available' | 'not_available' | 'not_available_now' | 'future_order' | 'preorder';
        delivery?: 'available' | 'not_available' | 'not_available_now' | 'not_available_to_this_address' | 'future_order' | 'preorder' | 'available_from';
        eatin?: 'available' | 'not_available' | 'not_available_now' | 'future_order' | 'preorder';
        futureReservation?: 'available' | 'not_available' | 'not_available_to_this_address';
        book?: 'available' | 'not_available';
        waitingList?: 'available' | 'not_available';
    } = {
        takeaway: null,
        delivery: null,
        eatin: null,
        futureReservation: null,
        book: null,
        waitingList: null,
    }

    constructor(
        public appService: AppService,
        public bookService: BookService,
        private ngZone: NgZone,
        private dialogsService: DialogsService,
        private entityUtilsService: EntityUtilsService
    ) {}

    ngOnInit(): void {

        this.beforeInit = false;
        if (this.site) {
            this.domainSubscription = this.appService.domain.subscribe(domain => {
                this.domain = domain;
                if (this.domain) {
                    this.calculateButtonsState(this.site);
                    this.setButtonCaptions(this.site);
                }
            });
        }

        if (this.bookService.orgTimeSlots && this.bookService.orgTimeSlots[this.site._id] && this.bookService.orgTimeSlots[this.site._id][0]) {
            this.requestedDate = this.bookService.orgTimeSlots[this.site._id][0].timestamp;
        }
    }

    ngOnDestroy(): void {
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
    }

    calculateButtonsState(site: any): void {

        this.deliveryStartFromBadge = this.appService.getStartFromTime(site, 'delivery');
        this.deliveryPreorderBadge = this.appService.isPreorderAvailableNow(site, 'delivery');
        this.takeawayPreorderBadge = this.appService.isPreorderAvailableNow(site, 'takeaway');
        this.takeawayStartFromBadge = this.appService.getStartFromTime(site, 'takeaway');
        this.eatinPreorderBadge = this.appService.isPreorderAvailableNow(site, 'eatin');
        this.futureDeliveryStartFromBadge = this.appService.getStartFromTime(site, 'delivery', 'futureAvailability');

        this.calcDeliveryButtonBadge();
        this.calcEatInButtonBadge();
        this.calcTakeawayButtonBadge();
        this.calcFutureButtonBadge();

        this.buttonStates = {};
        this.buttonStates.takeaway = this.calcTakeawayButtonState(site);
        this.buttonStates.delivery = this.calcDeliveryButtonState(site);
        this.buttonStates.eatin = this.calcEatinButtonState(site);
        this.buttonStates.waitingList = this.calcWaitingListButtonState(site);
        this.buttonStates.book = this.calcBookButtonState(site);

        this.calcBookButtonBadge();

        // calculate the future-separate-button state
        this.buttonStates.future = this.calcFutureButtonState(site);
        if (!this.disablePay) this.buttonStates.pay = this.calcPayButtonState(site);

        this.setFutureOrderIcon(this.site);

        // override initial buttons-order with domain custom-buttons-order
        if (this.domain?.defaults?.siteServiceButtonsOrder?.length) this.siteServiceButtonsOrder = cloneDeep(this.domain?.defaults?.siteServiceButtonsOrder);

        this.deliveryPreorderBadge = this.appService.isPreorderAvailableNow(site, 'delivery');
        this.deliveryStartFromBadge = this.appService.getStartFromTime(site, 'delivery');

        this.takeawayPreorderBadge = this.appService.isPreorderAvailableNow(site, 'takeaway');
        this.takeawayStartFromBadge = this.appService.getStartFromTime(site, 'takeaway');

        this.futureDeliveryStartFromBadge = this.appService.getStartFromTime(site, 'delivery', 'futureAvailability');
        this.futureTakeawayStartFromBadge = this.appService.getStartFromTime(site, 'takeaway', 'futureAvailability');
        this.futureEatinStartFromBadge = this.appService.getStartFromTime(site, 'eatin', 'futureAvailability');
    }

    calcPayButtonState(site: any): BUTTON_STATES {
        if (!site.external && site.servicesInDetails && !site.servicesInDetails.tabitpay) console.error('Tabit restaurant is missing data about pay service in services in details');
        if (!get(site, 'servicesInDetails.tabitpay.enabled')) return BUTTON_STATES.off;
        // 2020-05-07: Currently, Tabit Pay doesn't support "semi" - it's only either ON or OFF. So we ignore the "active" flag.
        //if (!get(site, 'servicesInDetails.tabitpay.active')) return BUTTON_STATES.semi;
        return BUTTON_STATES.on;
    }

    calcBookButtonState(site: any): BUTTON_STATES {

        // 15.01.2023 - Disabled for now
        // if (site.trainingMode) return BUTTON_STATES.off;

        // no booking method
        if (this.site?.reservation?.method == 'none') return BUTTON_STATES.semi;

        // booking service disabled
        if (!this.site.bookingData?.enabled) return BUTTON_STATES.on;

        if (this.site?.bookingData?.future_reservation?.hide_button_in_tabit_app) {
            // if booking service enabled but has no sub-services, display a disabled "future" placeholder button
            const waitingListNotDisplayed = this.buttonStates.waitingList == BUTTON_STATES.off;
            return waitingListNotDisplayed ? BUTTON_STATES.semi : BUTTON_STATES.off;

        } else if (site.bookingData?.future_reservation?.enabled && this.entityUtilsService.siteCanBook(site)) {
            return BUTTON_STATES.on;
        }

        return BUTTON_STATES.semi;
    }

    calcTakeawayButtonState(site: any): BUTTON_STATES {

        if (!site.external && !site.servicesInDetails?.takeaway) console.error('Tabit restaurant is missing data about takeaway service in services in details');
        if (site.trainingMode) return BUTTON_STATES.off;

        // If the takeaway service is not active but an external takeaway method exists - we use it, so the button must appear as ON.
        if (this.isServiceExternalMethod(site, 'takeaway')) return BUTTON_STATES.on;
        if (this.isTakeawayAvailableOnlyOnFuture('takeaway')) return BUTTON_STATES.semi;

        switch (this.activeBadges.takeaway) {
            case 'available': case 'preorder': case 'future_order':
                return BUTTON_STATES.on;
            case 'not_available':
                return BUTTON_STATES.off;
            case 'not_available_now': default:
                return BUTTON_STATES.semi;
        }
    }

    calcEatinButtonState(site: any): BUTTON_STATES {

        if (!site.external && !site.servicesInDetails?.eatin) console.error('Tabit restaurant is missing data about takeaway service in services in details');
        if (site.trainingMode) return BUTTON_STATES.off;

        switch (this.activeBadges.eatin) {
            case 'available': case 'preorder': case 'future_order':
                return BUTTON_STATES.on;
            case 'not_available':
                return BUTTON_STATES.off;
            case 'not_available_now': default:
                return BUTTON_STATES.semi;
        }
    }

    calcFutureButtonState(site: any): BUTTON_STATES {
        if (site.trainingMode) return BUTTON_STATES.off;

        if (this.activeBadges.futureReservation == 'available') {
            return BUTTON_STATES.on;
        } else {
            return BUTTON_STATES.off;
        }
    }

    calcWaitingListButtonState(site: any): BUTTON_STATES {
        // booking service disabled
        if (!this.site.bookingData?.enabled) return BUTTON_STATES.off;

        if (this.site?.bookingData?.walked_in?.hide_button_in_tabit_app) {
            return BUTTON_STATES.off;
        } else if (this.site?.bookingData?.walked_in?.enabled) {
            this.activeBadges.waitingList = 'available';
            return BUTTON_STATES.on;
        }

        // booking service disabled
        this.activeBadges.waitingList = 'not_available';
        return BUTTON_STATES.semi;
    }

    calcDeliveryButtonState(site: any): BUTTON_STATES {

        if (!site.external && site.servicesInDetails && !site.servicesInDetails.delivery) console.error('Tabit restaurant is missing data about delivery service in services in details');
        if (site.trainingMode) return BUTTON_STATES.off;

        // If the delivery service is not active but an external delivery method exists - we use it, so the button must appear as ON.
        if (this.isServiceExternalMethod(site, 'delivery')) return BUTTON_STATES.on;
        if (this.isTakeawayAvailableOnlyOnFuture('delivery')) return BUTTON_STATES.semi;

        switch (this.activeBadges.delivery) {
            case 'available': case 'preorder': case 'future_order':
                return BUTTON_STATES.on;
            case 'not_available':
                return BUTTON_STATES.off;
            case 'available_from': case 'not_available_now': case 'not_available_to_this_address': default:
                return BUTTON_STATES.semi;
        }
    }

    calcFutureButtonBadge(): void {
        if (this.site.trainingMode) return;

        // get all other services future-availability
        const siteServices = this.site?.servicesInDetails;
        const deliveryFutureAvailability = siteServices?.delivery?.futureActive && siteServices.delivery?.futureAvailability?.available;
        const takeawayFutureAvailability = siteServices?.takeaway?.futureActive && siteServices.takeaway?.futureAvailability?.available;

        // future-order is not a separate button, badge is disabled
        if (!this.isFutureSeparateButton() || !siteServices) {
            this.activeBadges.futureReservation = 'not_available';
            return;
        }

        // future delivery isn't available to the specific region address AND other future-services are unavailable
        if (!takeawayFutureAvailability && !deliveryFutureAvailability && siteServices?.delivery?.futureAvailability?.reason == 'NOT-HERE') {
            this.activeBadges.futureReservation = 'not_available_to_this_address';
            return;
        }

        // if any future-service is available, so will the future-order
        if (deliveryFutureAvailability || takeawayFutureAvailability) {
            this.activeBadges.futureReservation = 'available';
        } else {
            this.activeBadges.futureReservation = 'not_available';
        }
    }

    calcBookButtonBadge(): void {
        if (this.buttonStates.book == this.buttonSemi && this.site?.reservation?.method != 'none') this.activeBadges.book = 'not_available';
    }

    isDeliveryExternalMethod(site) {
        return !get(site, 'servicesInDetails.delivery.active') && !get(site, 'servicesInDetails.delivery.enabled') && get(site, 'delivery.method') == 'url' && get(site, 'delivery.methodValue');
    }

    calcTakeawayButtonBadge(): void {
        if (this.site.trainingMode) return;

        const takeawayService = this.site?.servicesInDetails?.takeaway;
        const currentAvailability = takeawayService?.availability?.available;
        const futureAvailability = takeawayService?.futureActive && takeawayService.futureAvailability?.available;

        // service isn't enabled
        if (!takeawayService || !takeawayService?.enabled) {
            this.activeBadges.takeaway = 'not_available';
            return;
        }

        // regular service active
        if (takeawayService.active) {

            // pre order available
            if (this.takeawayPreorderBadge) {
                this.activeBadges.takeaway = 'preorder';

            } else {
                // takeaway service available
                if (currentAvailability) {
                    // available, no badge
                    this.activeBadges.takeaway = 'available';
                // takeaway service unavailable
                } else {
                    this.activeBadges.takeaway = 'not_available_now';
                }
            }

        // regular service inactive
        } else {
            //  future availability
            if (futureAvailability) {
                this.activeBadges.takeaway = 'future_order';
            // not available now
            } else {
                this.activeBadges.takeaway = 'not_available_now';
            }
        }
    }

    calcDeliveryButtonBadge(): void {
        if (this.site.trainingMode) return;

        const deliveryService = this.site?.servicesInDetails?.delivery;
        const currentAvailability = deliveryService?.availability?.available;
        const futureAvailability = deliveryService?.futureActive && deliveryService.futureAvailability?.available;

        // site isn't enabled
        if (!deliveryService || !deliveryService?.enabled) {
            this.activeBadges.delivery = 'not_available';
            return;
        }

        // regular service active
        if (deliveryService.active) {
            // regular service is available OR in pre-order OR not in delivery region
            if (currentAvailability || this.deliveryPreorderBadge || deliveryService.availability?.reason == 'NOT-HERE') {

                // delivery available for region
                if (deliveryService.availability?.deliveryRegion) {
                    // only pre order available
                    if (this.deliveryPreorderBadge) {
                        this.activeBadges.delivery = 'preorder';
                    } else {
                        // available, no badge
                        this.activeBadges.delivery = 'available';
                    }

                // delivery unavailable for region
                } else {
                    this.activeBadges.delivery = 'not_available_to_this_address';
                }

            // not available now
            } else {
                this.activeBadges.delivery = 'not_available_now';
            }

        // regular service inactive
        } else {
            // future availability OR not in regular delivery region
            if (!this.isFutureSeparateButton() && (futureAvailability || deliveryService.futureAvailability?.reason == 'NOT-HERE')) {

                // future delivery available for region
                if (deliveryService.futureAvailability?.deliveryRegion) {
                    // futureDeliveryStartFromBadge validates the future-availability hours
                    this.activeBadges.delivery = this.futureDeliveryStartFromBadge ? 'future_order' : 'not_available';

                // future delivery unavailable for region
                } else {
                    this.activeBadges.delivery = 'not_available_to_this_address';
                }

            } else {
                if (futureAvailability) {
                    this.activeBadges.delivery = 'future_order';
                }
                else {
                    this.activeBadges.delivery = 'not_available_now';
                } 
            }
        }
    }

    calcEatInButtonBadge(): void {
        if (this.site.trainingMode) return;

        const eatinService = this.site?.servicesInDetails?.eatin;
        const currentAvailability = eatinService?.availability?.available;

        // service isn't enabled
        if (!eatinService?.enabled) {
            this.activeBadges.eatin = 'not_available';
            return;
        }

        // regular service active
        if (eatinService.active) {
            // pre order available
            if (this.eatinPreorderBadge) {
                this.activeBadges.eatin = 'preorder';
            } else {
                // eatin service available
                if (currentAvailability) {
                    // available, no badge
                    this.activeBadges.eatin = 'available';
                // eatin service unavailable
                } else {
                    this.activeBadges.eatin = 'not_available_now';
                }
            }
        // regular service inactive
        } else {
            this.activeBadges.eatin = 'not_available_now';
        }
    }

    isServiceExternalMethod(site, service): any {
        switch(service) {
            case 'delivery':
                return !get(site, `servicesInDetails.${service}.active`) && !get(site, `servicesInDetails.${service}.enabled`) && get(site, `${service}.method`) == 'url' && get(site, `${service}.methodValue`);
            case 'takeaway':
                return get(site, `${service}.method`) == 'url' && get(site, `${service}.methodValue`) && !this.appService.isApp;
        }
    }

    isFutureSeparateButton(): any {
        return get(this.site, 'servicesInDetails.showServiceButton', false);
    }

    isTakeawayAvailableOnlyOnFuture(service): any {
        return this.activeBadges[service] === 'future_order' && this.isFutureSeparateButton();
    }

    isFutureDeliveryAddressUnavailable(): boolean {
        if (
            // Future TA inactive, and D active
            this.futureOrderIcon == 'brand-additional-site-order-delivery-active' &&
            get(this.site, 'servicesInDetails.delivery.active') &&
            !get(this.site, 'servicesInDetails.delivery.futureAvailability.available') &&
            !get(this.site, 'servicesInDetails.delivery.futureAvailability.deliveryRegion')
        ) return true;
        return false;
    }

    isShowFutureCaptionOnActionButton(service): boolean {
        if (!service || (service && !['takeaway', 'delivery'].includes(service))) return false;
        if (!this.isFutureSeparateButton()) {
            if (
                !get(this.site, `servicesInDetails.${service}.active`) &&
                get(this.site, `servicesInDetails.${service}.futureActive`)
            ) return true;
        };
        return false;
    }

    onButtonClick(buttonClicked: string, isFuture: boolean = false): any {
        // =====================FUTURE ORDER CLICK============================
        if (!isFuture && ['delivery', 'takeaway'].includes(buttonClicked) && this.isShowFutureCaptionOnActionButton(buttonClicked)) {
            isFuture = true;
        }

        // ===================================================================
        // disable future-booking when there's no reservation method
        if (buttonClicked === 'book' && !this.isBookingActive()) return;

        const actualButtonClicked = buttonClicked;
        const actionType = (buttonClicked === 'delivery' || buttonClicked === 'takeaway') ? 'order' : buttonClicked;
        let service: SearchDetails['service'] = (buttonClicked === 'delivery' || buttonClicked === 'takeaway') ? buttonClicked : null;
        if (buttonClicked === 'pay') service = 'tabitpay';

        // Since actions are managed by dialogsService.toggleActionFrame, and that method makes no alert when the action is not available / exists,
        // so the best way we have is to check if the action is a member of appService.serviceActions.
        // Please do NOT use site.services for that check!
        if (buttonClicked !== 'waitingList' && !this.appService.serviceActions.find(action => action.id === actionType)) {
            return this.showServiceUnavailableMessage();
        }

        if (!isFuture && ['delivery', 'takeaway'].includes(buttonClicked) && this.isTakeawayAvailableOnlyOnFuture(buttonClicked)) {
            return this.showServiceUnavailableMessage();
        }

        if (['delivery', 'takeaway', 'eatin'].includes(buttonClicked) && this.activeBadges[buttonClicked] === 'not_available_now') {
            return this.showServiceUnavailableMessage();
        }

        if (isFuture && this.isFutureSeparateButton()) {
            buttonClicked = 'future';

        // semi disabled & non-future
        } else if (buttonClicked === 'delivery' && this.buttonStates?.delivery === BUTTON_STATES.semi && this.activeBadges.delivery !== 'not_available_to_this_address') {
            return this.showServiceUnavailableMessage();
        }

        if (this.buttonStates[buttonClicked] && (this.buttonStates[buttonClicked] !== BUTTON_STATES.off || buttonClicked === 'future')) {
            // Notice we use the "buttonClicked" and not actionType, because we want to distinguish between Delivery and Takeaway
            this.site.isFuture = isFuture;

            if (buttonClicked === 'takeaway' && this.domain?.defaults?.verifySiteConfirmationEnabled) this.showConfirmationDialog();
            else this.dialogsService.toggleActionFrame(actualButtonClicked, this.site, null, this.appService.cordovaPlatform);
        }
    }

    showServiceUnavailableMessage(): any {
        return this.appService.mainMessage({
            dialogType: 'error',
            dialogText: 'MESSAGES.service_not_available_for_site',
            hideSecondaryButton: true
        });
    }

    getDinersString(): string {
        if (!this.bookService.$storage.bookForm.diners || !this.bookService.$storage.bookForm.diners.text) return '';
        return this.bookService.$storage.bookForm.diners.text;
    }

    setButtonCaptions(site): void {
        this.buttonCaptions = get(site, 'buttonCaptions', {});
    }

    getButtonCaptions(service: 'takeaway' | 'delivery' | 'eatin' | 'order_delay' | 'waiting_list'): string {
        let caption: string = '';

        switch (service) {
            case 'takeaway':
                caption = this.buttonCaptions?.takeawayCaption?.length ?
                    this.buttonCaptions.takeawayCaption :
                    ( this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()]?.takeawayCaption ? this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()].takeawayCaption : 'SERVICE_BUTTON.takeaway' )

                break;
            case 'delivery':
                caption = this.buttonCaptions?.deliveryCaption?.length ?
                    this.buttonCaptions.deliveryCaption :
                    ( this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()]?.deliveryCaption ? this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()].deliveryCaption : 'SERVICE_BUTTON.delivery' )

                break;
            case 'order_delay':
                caption = this.buttonCaptions?.orderDelayCaption?.length ?
                    this.buttonCaptions.orderDelayCaption :
                    ( this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()]?.orderDelayCaption ? this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()].orderDelayCaption : 'SERVICE_BUTTON.future_order' )

                break;
            case 'eatin':
                caption = this.buttonCaptions?.eatinCaption?.length ?
                    this.buttonCaptions.eatinCaption :
                    ( this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()]?.eatinCaption ? this.buttonCaptions[this.appService.localeId.toLocaleLowerCase()].eatinCaption : 'SERVICE_BUTTON.eatin' )

                break;
            case 'waiting_list':
                caption = 'SERVICE_BUTTON.waitingList';
                break;
        }

        return caption;
    }

    setFutureOrderIcon(site): "brand-site-order-ta" | "brand-site-order-ta-active" | "brand-site-order-delivery" | "brand-site-order-delivery-active" | "ta_future" {
        const serviceInDetails = get(site, 'servicesInDetails');

        if (serviceInDetails?.takeaway?.futureActive && !serviceInDetails?.delivery?.futureActive) return this.futureOrderIcon = [this.buttonOff].includes(this.buttonStates.future) ? 'brand-site-order-ta' : 'brand-site-order-ta-active';
        if (!serviceInDetails?.takeaway?.futureActive && serviceInDetails?.delivery?.futureActive) return this.futureOrderIcon = [this.buttonOff].includes(this.buttonStates.future) ? 'brand-site-order-delivery' : 'brand-site-order-delivery-active';
        if (serviceInDetails?.takeaway?.futureActive && serviceInDetails?.delivery?.futureActive) {
            // If the user is in the supply region just show the caption, else show TA icon
            return this.futureOrderIcon = serviceInDetails?.delivery?.futureAvailability?.available ? 'ta_future' : [this.buttonOff].includes(this.buttonStates.future) ? 'brand-site-order-ta' : 'brand-site-order-ta-active';
        }
    }

    futureServiceClick(): Promise<any> {
        if (this.buttonStates.future == BUTTON_STATES.off) return;

        if (this.isFutureTakeawayActive() && !this.isFutureDeliveryActive()) return this.onButtonClick('takeaway', true);
        if (!this.isFutureTakeawayActive() && this.isFutureDeliveryActive()) return this.onButtonClick('delivery', true);
        if (this.isFutureTakeawayActive() && this.isFutureDeliveryActive()) {
            // If the user is in the supply region press delivery, else TA
            return this.appService.mainMessage({
                dialogType: 'info',
                dialogTitle: 'service_selection',
                dialogText: 'future_service_desc',
                primaryButtonText: this.getButtonCaptions('delivery'),
                secondaryButtonText: this.getButtonCaptions('takeaway'),
                hideXIcon: false,
            }).then(() => {
                // if (!this.isFutureDeliveryInRegion()) throw new Error('Address is not in the delivery region');
                return this.onButtonClick('delivery', true);
            }).catch(err => {
                if (err?.secondaryButtonClick) return this.onButtonClick('takeaway', true);
                if (err?.message == 'Address is not in the delivery region') {
                    return this.appService.mainMessage({
                                dialogType: 'error',
                                dialogText: 'MESSAGES.DISABLED_REGION_MESSAGE',
                                hideSecondaryButton: true
                            });
                }
            }) ;
        }
    }

    waitingListServiceClick() {
        if (!this.appService.isApp) {
            try {
                // For Web we want native navigation
                const link = this.appService.getRedirectUrl([this.appService.getTranslatedRoute('reservation')], { queryParams: {
                    orgId: this.site._id, source: this.appService.skin ? this.appService.skin : 'tabit',
                    type: 'walked_in'
                } });
                if (link) {
                    window.location.href = link.toString();
                }
            } catch(err) {
                console.error('Error during navigation:', err);
            } finally {
                this.appService.stopBlock();
            }

            return;
        } else return this.onButtonClick('waitingList');
    }

    isBookingActive(): boolean {
        if (this.site?.reservation?.method === 'none') return false;
        if (this.buttonStates.book === this.buttonSemi && this.activeBadges.book === 'not_available') return false;
        return true;
    }
    
    isFutureDeliveryActive(): boolean {
        if (this.serviceInDetails?.delivery?.futureActive) return true;
        return false;
    }

    isFutureDeliveryInRegion(): boolean {
        if (this.isFutureDeliveryActive() && this.serviceInDetails?.delivery?.futureAvailability?.available) return true;
        return false;
    }

    isFutureTakeawayActive(): boolean {
        if (this.serviceInDetails?.takeaway?.futureActive) return true;
        return false;
    }

    showFutureOrderService(): boolean{
        const futureActive = this.site?.servicesInDetails?.takeaway?.futureActive || this.site?.servicesInDetails?.eatin?.futureActive || this.site?.servicesInDetails?.delivery?.futureActive;

        if (this.site?.servicesInDetails?.showServiceButton && futureActive) return true;
    }

    get noOrgTimeSlotsLength() {
        return !(this.bookService.orgTimeSlots[this.site._id] && this.bookService.orgTimeSlots[this.site._id].length)
    }

    get orderedButtonsByIndex() {
        return orderBy(this.siteServiceButtonsOrder, 'index', 'asc');
    }

    getButtonTemplateReference(buttonName): any {
        return this[buttonName];
    }

    showConfirmationDialog(): void {
        this.ngZone.run(() => { // The ngZone is required here, because otherwise the dialog first appears as "empty" (with the word "closed" inside) and only a moment after the true contents of the dialog appear.
            this.appService.mainMessage({
                dialogType: 'info',
                dialogTitle: this.getDialogTitle(),
                dialogText: this.getDialogText(),
                primaryButtonText: 'MAKE_AN_ORDER',
                secondaryButtonText: 'BACK',
            }).then((response: any) => {
                if (response?.primaryButtonClick) this.dialogsService.toggleActionFrame('takeaway', this.site, null, this.appService.cordovaPlatform);
            }).catch(err => {

            });
        });

    }

    navigateToBookingLink (event: Event) {
        if (event) event.stopPropagation();

        if (get(this.site, 'reservation.method') === 'onlineBooking') {
            try {
                // For Web we want native navigation
                const link = this.appService.getRedirectUrl([this.appService.getTranslatedRoute('reservation')], { queryParams: {
                    orgId: this.site._id,
                    source: this.appService.skin ? this.appService.skin : 'tabit',
                    type: 'future_reservation'
                } });
                if (link) {
                    window.location.href = link.toString();
                }
            } catch(err) {
                console.error('Error during navigation:', err);
            } finally {
                this.appService.stopBlock();
            }

            return;
        }

        this.onButtonClick('book');
    }

    getDialogTitle(): string {
        return this.appService.translate('pickup-order-dialog.Branch_pickup_order') + this.site.name;
    }

    getDialogText(): string {
        return this.appService.translate('pickup-order-dialog.address') + ' ' +  this.site.address;
    }

    getAriaLabel(takeawayCaption, activeBadges, orderDelayCaption) {
        return this.appService.translate(takeawayCaption) + ' ' + (activeBadges?.takeaway == 'future_order' ? orderDelayCaption ? this.appService.translate(orderDelayCaption) : '' : activeBadges?.takeaway == 'not_available' ? this.appService.translate('not_available') : activeBadges?.takeaway == 'not_available_now' ? this.appService.translate('not_available_now') : '');
    }
}
