import { Component, OnInit, OnDestroy, AfterViewInit, NgZone, ChangeDetectionStrategy, ChangeDetectorRef, AfterViewChecked } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { map, Subscription } from 'rxjs';
import { trigger, style, animate, transition } from '@angular/animations';
import { ActivatedRoute, Params } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { MatTabChangeEvent, MatTabGroup, MatTab } from '@angular/material/tabs';

import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { EntityService } from '../_core/entity.service';
import { EntityUtilsService } from '../_core/entity.utils.service';
import { DialogsService } from '../_core/dialogs.service';
import { BookService } from '../_core/book.service';
import { LocationService } from '../_core/location.service';
import { TagsService } from '../_core/tags.service';
import { LoyaltyService } from '../_core/loyalty.service';

import { find, get, filter, concat } from 'lodash-es';
import moment from 'moment';
import { OrganizationsService } from '../_core/organizations.service';
import { OrderTrackerService } from '../_core/order-tracker.service';
import { StorageService } from '../_core/storage.service';
import { SafeStylePipe, AMPMConverterPipe } from '../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { OrderModule } from 'ngx-order-pipe';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { HistoryItemComponent } from '../components/history-item/history-item.component';
import { TrackedOrderItemComponent } from '../components/tracked-order-item/tracked-order-item.component';
import { LottieComponent } from 'ngx-lottie';
import { MenuItemComponent } from '../components/menu-item/menu-item.component';
import { FutureReservationsComponent } from '../components/future-reservations/future-reservations.component';
import { BenefitCardComponent } from '../loyalty/benefit-card/benefit-card.component';
import { MatList } from '@angular/material/list';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { NgxCollapseDirective } from '../_core/directives';
import { SiteActionsComponent } from '../components/site-actions/site-actions.component';
import { TimeSlotsComponent } from '../components/time-slots/time-slots.component';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { Dir } from '@angular/cdk/bidi';
import { RatingComponent } from '../components/rating/rating.component';
import { WidgetOpenerComponent } from '../notifications/widget-opener/widget-opener.component';
import { FavoriteFabComponent } from '../components/favorite-fab/favorite-fab.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { NgIf, NgClass, NgFor, NgTemplateOutlet, AsyncPipe, SlicePipe } from '@angular/common';

declare const $: any;

@UntilDestroy()
@Component({
    selector: 'app-site-details',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './app-site-details.component.html',
    styleUrls: ['./app-site-details.component.scss'],
    animations: [
        trigger('siteLogoAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s 1s linear', style({ opacity: 1 })),
            ]),
        ]),
        trigger('siteSpinnerAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s 0s linear', style({ opacity: 1 })),
            ]),
            transition(':leave', [
                // We don't set the "start" opacity to 1 so that in case the :leave start before the :entry has finished - the opacity won't flicker to "1"
                animate('0.2s 0s linear', style({ opacity: 0 })),
            ])
        ]),
        trigger('sitePromoAnimation', [
            transition(':enter', [
                style({ opacity: 0, height: 0 }),
                animate('0.2s 1s linear', style({ opacity: 1, height: '5rem' })),
            ]),
        ]),
        /*
        trigger('sitePhotoGalleryAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.4s 1.2s linear', style({ opacity: 1 })),
            ]),
        ]),
        */
        trigger('siteReservationsAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 0.3s linear', style({ opacity: 1 })), // The 0.2s + 100ms (rendering buffer) delay is required in order to allow the spinner to disappear smoothly first
            ])
        ]),
        trigger('siteBenefitsAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 0.3s linear', style({ opacity: 1 })), // The 0.2s + 100ms (rendering buffer) delay is required in order to allow the spinner to disappear smoothly first
            ])
        ]),
        trigger('siteBookingAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 0.3s linear', style({ opacity: 1 })), // The 0.2s + 100ms (rendering buffer) delay is required in order to allow the spinner to disappear smoothly first
            ])
        ]),
        trigger('siteTabsAnimation', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('0.2s 0.3s linear', style({ opacity: 1 })), // The 0.2s + 100ms (rendering buffer) delay is required in order to allow the spinner to disappear smoothly first
            ])
        ]),
    ],
    standalone: true,
    imports: [NgIf, MatIconButton, MatIcon, FavoriteFabComponent, WidgetOpenerComponent, NgClass, NgFor, RatingComponent, Dir, MatProgressSpinner, TimeSlotsComponent, MatTabGroup, MatTab, SiteActionsComponent, NgxCollapseDirective, MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatList, BenefitCardComponent, FutureReservationsComponent, MenuItemComponent, NgTemplateOutlet, LottieComponent, TrackedOrderItemComponent, HistoryItemComponent, GoogleMap, MapMarker, AsyncPipe, SlicePipe, OrderModule, TranslateModule, SafeStylePipe, AMPMConverterPipe]
})
export class AppSiteDetailsComponent implements OnInit, AfterViewInit, AfterViewChecked, OnDestroy {
	@BlockUI() blockUI: NgBlockUI;
    private historySubscription: Subscription = null;
    private updateOrgSubscription: Subscription = null;
    private loylatySubscription: Subscription = null;
    private coreSubscription: Subscription = null;
    private domainSubscription: Subscription;
    private googlePlaceDetailsSubscription: Subscription;
    private tagsSubscription: Subscription;

    public clubsData: any = [];
    public clubIds: any[] = [];
    public domain: any;

    public tabsIndex: number = 0;
    public appConfig: any = environment.appConfig;

    public loading: boolean = false;
    public loadingHeavy: boolean = true; // used to delay load heavy assets
    public sectionStyle: string = '';
    public orgHandle: string = null;
    public site: any = {
        tags: []
    };
    public siteConfig: any = {};
    public siteStrings: any = {};
    public showTimeslots: boolean = false;
	public recents: any = [];
    public fabOptions: any = {buttons:[]};
    public sitePromo: string = null;
    public imageContainerHeight: string;
    public initialImageContainerHeight: number = 230;
    public imageContainerTranslateY: string = '';
    public shareMessage: string;
    public toolbarHeight: number = 62;
    public headerStyleInverse: boolean = false;
    public showSiteLogo: boolean = false;
    public showReviews: boolean = false;
    public showTabs: boolean = false;
    public place: any;
    public loadingPlace: boolean = false;
    public photos: any = [];
    public photosMaxColumnHeight: number;
    public numberOfPhotosColumns: number = 2;
    public maxHeightofPhoto: number = 240;
    public descriptionExpanded: boolean = false;
    public tabitReviews: any = [];
    public hasMenus: boolean = false;
    public menus: any = [];
    public historyLoaded: boolean = false;
    public historySpinnerEnabled: boolean = false;
    public clubData: any;
    public benefits: any;
    public pointsBenefit: any;
    public actions: any = {
		order: 'middle big',
		book: 'start small',
		pay: 'end small'
	}

    public currentDay: number;
    public unit: string = this.appService.getUnitByLocale();
    public trackedOrders: any = [];
    public ordersFromLocalStorage: any = [];
    public afterMenuLoad: boolean = false;
    public mapIconObj: any;
    public bookinButtonHidden : boolean;
    public scrollElement: HTMLElement;
    public moduleBarElement: HTMLElement;
    public hideTags: boolean = false;
    public siteDetailsPage;
    public trustedIframeUrl: SafeResourceUrl;
    public areaDescriptions: any;
    public accessibilityTags: any;

    constructor(
        private orderTrackerService: OrderTrackerService,
        private storageService: StorageService,
		public appService: AppService,
		public entityService: EntityService,
		public utilsService: EntityUtilsService,
		public dialogsService: DialogsService,
		public bookService: BookService,
		public locationService: LocationService,
        public loyaltyService: LoyaltyService,
        private organizationsService: OrganizationsService,
        private tagsService: TagsService,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
        public ngZone: NgZone,
        private sanitizer: DomSanitizer,
	) {}

    ngOnInit() {
        this.siteIcon();
        // Subscribe to location and get organizations
        this.coreSubscription = this.appService.subscribedToLocationAndGotOrganizations.subscribe(subscribed => {
            if (!subscribed) this.entityService.subscribeToCoreData();
        });
        this.domainSubscription = this.appService.domain.subscribe(domain => {
            this.domain = domain;
            if (this.domain) {
                this.bookinButtonHidden = find(this.domain?.defaults?.siteServiceButtonsOrder, { name: 'book' })?.hidden ? true : false;
                this.hideTags = this.domain?.defaults?.hideTagsFromRestaurantPage ? true : false;
            }
        });

        this.recents = [];
        this.menus = [];
        this.hasMenus = false;
        this.currentDay =  this.appService.getRealDateMoment().day();

        this.appService.setStatusBarStyle('light');
    }

    ngAfterViewChecked() {
        // I did it in order to prevent an error thrown when detecting description height, to put gradient overlay.
        // I didn't think that this gradient is worth the whole learning curve of how Angular change detection works.
        // I think that we MUST learn it, if we want to work with Angular properly, but not for that task only.
        // Currently please blame ALL performance reduce in the site-details on this line.
        // if you don't want it, remove that fade gradient (ifDescriptionLong)
        //  - Nati
        this.changeDetectorRef.detectChanges();
    }

    ngAfterViewInit() {
        this.scrollElement = document.getElementById("site-detail-content");
        this.moduleBarElement = document.getElementById("site-detail-module-bar");
        setTimeout(() => {
            // tabit-app: keep track of the order process referring page for exact back-button functionality
            this.appService.setOrderProcessReferringRoute();

            this.route.params.subscribe((params: Params) => {
                this.orgHandle = params.id;
                this.site = this.organizationsService.getOrganizationWithHandle(this.orgHandle);
                let siteWasOnState = !!this.site;
                this.areaDescriptions = get(this.site, `bookingData.strings`, null);
                // Necessary to prevent any buttons remaining as "active" due to a click from the previous screen
                this.showSiteLogo = (siteWasOnState && this.site.hasLogo) ? true : false;

                this.loadFullOrg(() => {
                    if (!siteWasOnState) {
                        if (this.site.hasLogo) this.showSiteLogo = true;
                    }
                    // Get about datas
                    this.setAboutData();
                    this.loadAccessibilityTags();
                    this.prepMenus(this.site);
                    if (this.appService.isAuthUser() && !this.site.external) {
                        this.loylatySubscription = this.loyaltyService.getCustomerBenefitsArray(this.site._id).subscribe(clubs => {
                            // console.log('=== AppSiteDetailsComponent/this.clubData ===', club);
                            if (clubs) {
                                this.clubsData = clubs.filter(clubData => (clubData?.points?.length) || clubData?.benefits?.length).map(club => {
                                    return {
                                        benefits: club.benefits,
                                        pointsBenefit: club.benefits.find(benefit => benefit.programBenefitTypeAlias === 'PointsBenefit'),
                                        points: club.points,
                                        clubName: club.clubName,
                                        pointsNickname: club.pointsNickname
                                    }
                                });
                                setTimeout(() => {this.tabsIndex = 0; this.afterMenuLoad = true}, 400);
                            }
                        });
                    }

                    this.changeDetectorRef.detectChanges();
                });

                this.updateOrgSubscription = this.organizationsService.fullOrganizationNeedsUpdate.subscribe({
                    next: () => {
                        this.loadFullOrg();
                    },
                    error: (err: any) => {
                        console.error('Error updating site details:', err);
                    }
                });
            });
        }, 400);

        // control the back button functionality on android cordova
        if (this.appService.isApp && this.appService.platformService.ANDROID) {
            this.appService.androidBackButton
                .pipe(untilDestroyed(this))
                .subscribe(() => {
                    this.ngZone.run(() => {
                        this.navigateBack()
                    })
                });
        }
    }

    ngOnDestroy() {
        this.appService.isWhiteLabel.next(false);
        // Disabling Safari WKWebView Back/Forward Gestures
        /*
        if (window['WkWebView']) {
            window['WkWebView'].allowsBackForwardNavigationGestures(false);
            this.appService.allowsBackForwardNavigationGesturesIsEnabled = false;
        }
        */

        this.appService.currentSiteID = null;

        this.coreSubscription.unsubscribe();
        if (this.historySubscription) this.historySubscription.unsubscribe();
        if (this.updateOrgSubscription) this.updateOrgSubscription.unsubscribe();
        if (this.loylatySubscription) this.loylatySubscription.unsubscribe();
        if (this.domainSubscription) this.domainSubscription.unsubscribe();
        if (this.googlePlaceDetailsSubscription) this.googlePlaceDetailsSubscription.unsubscribe();
        if (this.tagsSubscription) this.tagsSubscription.unsubscribe();
        this.appService.setStatusBarStyle('dark');

        if (this.bookService.orgTimeSlots && this.bookService.orgTimeSlots[this.site._id]) {
            this.bookService.orgTimeSlots[this.site._id] = null;
        }
    }

    navigateBack() {
        // [ ! ] Note:
        // The gesture (swipe from left-edge-to-right) is handled in app.component under the '@HostListener('touchmove', ['$event'])...' parts.

        this.appService.lastSiteOpened.next(this.site._id);
        // 2019-10-08 - Due to performance reasons - we need to first animate the details-view BEFORE the app-component starts the :enter animation of the next component
        // If we try to do it "properly" all through the host animations (of app-component), for some reason the :leave animation has poor performance (probably because Angular loads the :enter component during the animation of the :leave)
        $('app-site-details').animate({ left: '100vw' }, 250, () => {
            if (this.isPreviousUrlAbove()) {
                this.appService.redirect(['/home/dashboard']);
            } else {
                this.appService.goBack(); // Will work even when landing on this page, because previous url exists

                // The following is required in order to make sure that the app-site-details DOM element goes back to it's original (left:0) position.
                // Otherwise, if (for example) we're on the site-details, and "landed" with a deep-link on another site-details (of another restaurant), so when the user will click (or swipe) "back" - since we remain on the same component - the new restaurant site-details won't appear, as the host DOM element has already been moved to the left.
                setTimeout(() => {
                    $('app-site-details').animate({ left: '0' }, 250);
                }, 400);
            }
        });
    }

    isPreviousUrlAbove() {
        if (this.appService.previousUrl.match(/^\/(tabit-book|pay|tabit-order\?site|app-site\/)/)) return true;
        return false;
    }

	private loadFullOrg(cb?: () => void) {
        // This is one time subscription, it'll complete when full organization received:
        this.organizationsService.fullOrganization(this.orgHandle).subscribe(org => {
            this.site = org;
            this.appService.currentSiteID = this.site._id;
            if (cb) cb();
            this.showTabs = true;
            this.googlePlaceDetailsSubscription = this.appService.googlePlaceDetailsSubject.subscribe(googlePlaceDetails => {
                this.showReviews = googlePlaceDetails.showReviews &&
                (this.site?.googlePlaceDetails?.reviews?.length || this.site?.tabitReviews?.length);

                if (this.showReviews) {
                    this.tabitReviews = this.site.tabitReviews;
                    if (this.tabitReviews?.length) this.prepareTabitReviews();
                }
            })

            this.photos = this.getPhotosFromSite();

            if (this.photos?.length) {
                this.photosMaxColumnHeight = this.photos.length / this.numberOfPhotosColumns * this.maxHeightofPhoto;
            }

        }, err => {
            this.handleFullOrgError(err);
        });
    }

    public shareSite() {
        let message = '';
        if (this.site?.name) message = this.appService.translate('share_message', { name: this.site.name });
        else message = this.appService.translate('share_message_without_site');
        this.appService.shareWidget(null, message);
    }

    private getPhotosFromSite() {
        let photos = [];
        // Images from googlePlaceDetails
        // if (this.site?.googlePlaceDetails?.photos) {
        //     this.site.googlePlaceDetails.photos.forEach(image => {
        //         const tempImage = {
        //             photo_url: image.photo_url,
        //         }
        //         photos.push(tempImage);
        //     });
        // }

        // Images from sitePageDetails
        const siteDetailsPage = this.site.siteDetailsPage;
        if (siteDetailsPage?.images?.length) {
            siteDetailsPage?.images.forEach((image, index) => {
                // Only if there is an actual URL, and not ''
                if (image.url) {
                    const tempImage = {
                        photo_url: image.url,
                        alt: image?.alt,
                        index,
                    }
                    photos.push(tempImage);
                }
            });
        }

        return photos;
    }

    private prepMenus(site: any) {
        this.menus = site.menus;
        // To enable TO menu view
        if (get(site, 'servicesInDetails.enableMenuView')) this.setOnlineMenu();
        this.hasMenus = this.menus?.length;
    }

    private setOnlineMenu() {
        const onlineMenu = {
            label: {
                'he-IL': 'תפריט אונליין',
                'en-US': 'Online menu',
                'en-AU': 'Online menu',
            },
            isTabitOrderMenu: true,
            method: 'url',
            siteId: this.site._id
        }
        this.menus.unshift(onlineMenu);
    }

    getSiteTags() {
        if (!this.site || !this.site.tags || !this.site.tags.length) return [];
        return filter(this.site.tags.map((tagId: string) => this.tagsService.getTagName(tagId)));
    }

    //No longer available from the html template
    getSiteReservations() {
        if(
            !this.appService.reservations ||
            !this.appService.reservations.length
        ) return [];
        return this.appService.reservations.filter(reservation => {
            if (!reservation.organization || !reservation.reservation_details || !reservation.reservation_details.reserved_from) return false;
            if (!this.appService.isReservationRelevant(reservation)) return false;
            return (
                reservation.organization === this.site._id &&
                moment(reservation.reservation_details.reserved_until).isSameOrAfter(moment())
            );
        });
    }

    tabChanged($event:MatTabChangeEvent) {
        if ($event?.tab?.textLabel == this.appService.translate('site_details.tab_label_history')) {
            if (this.appService.isAuthUser()) {
                this.historySpinnerEnabled = true;
                setTimeout(() => {
                    this.loadHistory();
                }, 1000); // Necessary because we must let the tabs slide animation to finish BEFORE we start the XHR call (otherwise we'll have an unsmooth animation)
            }
        }
    }

    loadHistory() {
        if (this.recents.length > 0 || this.historyLoaded) return;
        this.takeDataFromLocalStorage();
        this.entityService.getUserHistory().then(response => {
            this.historySpinnerEnabled = false;
            this.historyLoaded = true;
            let responseOrders = filter(response, { organization: this.site._id }).slice(0, 100);
            //this.recents = this.getSiteReservations().concat(filter(this.appService.account.history, { organization: this.siteID }));
            // 2019-08-26 - Tabit requested to show only past reservations / orders
            this.recents = responseOrders.length ? this.entityService.checkIfHistoryItemValidForReview(this.site.tabitReviews, responseOrders) : [];
            /*
            this.historySubscription = this.entityService.historyChanged$.subscribe(() => {
                this.generateSiteHistory();
            });
            */
        });
    }

	showPlaceGallery(index) {
		//this.dialogsService.showGalleryDialog({ title: this.site.name, index: index, images: this.site.googlePlaceDetails.photos})
	}

	toggleActionFrame(action, siteDetails, event) {
        this.dialogsService.toggleActionFrame(action, siteDetails, event, window['cordova']);
	}

	toggleFavorite(event) {
		event.stopPropagation();
		this.entityService.toggleFavorites(this.site);
    }

    checkScroll(e: any) {
        let scrollTop = e.srcElement.scrollTop;
        let newImageContainerHeight = this.initialImageContainerHeight - scrollTop;

        this.imageContainerHeight = newImageContainerHeight+'px';

        if (scrollTop < this.initialImageContainerHeight - this.toolbarHeight) {
            this.headerStyleInverse = false;
            this.appService.setStatusBarStyle('light');
        } else {
            this.headerStyleInverse = true;
            this.appService.setStatusBarStyle('dark');
        }
    }

    openGallery(index: number) {
        if (!this.photos?.length) return;

        this.dialogsService.showGalleryDialog({ images: this.photos, index });
    }

    callPhone() {
        if (window['cordova']) {
            window.open('tel:' + (this.site.phone || this.site.reservation.methodValue), '_system');
        } else {
            window.location.href = 'tel:' + (this.site.phone || this.site.reservation.methodValue);
        }
    }

    ifDescriptionLong(descriptionEl: HTMLElement) {
        return descriptionEl.offsetHeight > 90;
    }

    private handleFullOrgError(err: HttpErrorResponse) {

        // TODO: Display some 404?

        // TODO: Better way to check if it's a network error:
        if (!err.headers) return console.error('Error with site details:', err);

        let urlIdentifierRedirect = this.appService.urlIdentifierRedirectFromError(err);
        if (urlIdentifierRedirect) {
            this.appService.redirect(['/app-site/', urlIdentifierRedirect], { replaceUrl: true });
        } else {
            console.error('Error loading site details:', err);
        }
    }

    private prepareTabitReviews() {
        // Prepare animation path
        this.tabitReviews.forEach(tabitReview => {
            switch (tabitReview.review.rating) {
                case 5: tabitReview.review.animationPath = this.appService.getAnimationPath('perfect-review'); break;
                case 4: tabitReview.review.animationPath = this.appService.getAnimationPath('ok-review'); break;
                case 3: tabitReview.review.animationPath = this.appService.getAnimationPath('plausible-review'); break;
                case 2: tabitReview.review.animationPath = this.appService.getAnimationPath('expected-more-review'); break;
                case 1: tabitReview.review.animationPath = this.appService.getAnimationPath('sad-review'); break;
                default: tabitReview.review.animationPath = '';
            }
            tabitReview.created = moment(tabitReview.created).locale(this.appService.localeId).fromNow();
        })
    }

    private takeDataFromLocalStorage() {

        // console.log('=== DASHBOARD === taking orgs from local storage (if any)');
        this.ordersFromLocalStorage = JSON.parse(`${this.appService.validateLocalStorageData('state__customer_orders')}`);
        if (this.ordersFromLocalStorage?.length) this.getOrRemoveOrdersFromLocalStorage(this.ordersFromLocalStorage);

    }

    private getOrRemoveOrdersFromLocalStorage(orders: any[]) {
        const filterdOrders = orders.filter(order => order.organization == this.site._id);
        filterdOrders.forEach(order => {
            this.orderTrackerService.getOrderById(order.id, order.organization).subscribe(updatedOrder => {
                if ((
                    !updatedOrder ||
                    updatedOrder && updatedOrder.deliveryInfo && updatedOrder.deliveryInfo.ETA && moment().diff(updatedOrder.deliveryInfo.ETA, 'hours') > 2) ||
                    updatedOrder && updatedOrder.created && moment().diff(updatedOrder.created, 'hours') > 4
                ) {
                    this.removeOrder(order);
                } else {
                    this.trackedOrders.push(order);
                }

            }, error => {
                console.error('Error in getting your order:', error, order);
                if (error && error.status == 404) {
                    this.removeOrder(order);
                }
            });
        }, err => {
            console.error('Error getting orders', err);
        });
    }

    private removeOrder(order: any) {
        let ordersToSave = this.ordersFromLocalStorage.filter(orderFromStorage => orderFromStorage.id !== order.id);
        this.storageService.setItem('state__customer_orders', JSON.stringify(ordersToSave || []));
    }

    siteIcon() {
        if (this.appService.skin) {
            this.mapIconObj = {url: this.appService.images.marker_current, scaledSize: {height: 50, width: 50}}
        } else {
            this.mapIconObj = this.appService.images.marker_simple
        }
    }

    showFutureReservationsTab() {
        if (!this.bookService.orgTimeSlots[this.site._id] && this.getSiteReservations()?.length && !this.bookinButtonHidden) return true;
        else return false;
    }

    setAboutData() {
        this.siteDetailsPage = this.site.siteDetailsPage;
        if (this.siteDetailsPage?.videoLink) {
            try {
                this.trustedIframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.siteDetailsPage.videoLink);
            } catch (err) {
                // Not a proper url probably
                console.debug(err);
            }
        }
    }

    private loadAccessibilityTags() {
        this.tagsSubscription = this.tagsService.tagsData$.pipe(map(tags => {
            return tags?.filter(tag => tag.type === 'accessibility' && this.site.tags.includes(tag.id));
        })).subscribe(tags => {
            this.accessibilityTags = tags;
        });
    }

    get googleAnimation() {
        return google.maps.Animation.DROP
    }
}
