export const environment = {
    production: false,
    env: 'il-dev',
    appConfig: {
        "version": "4.11.1",
        "last_update": "2024-08-14 15:35",
        "title": "TA IL-DEV",
        "direction": "rtl",
        "locale": "he-IL",
        "countryCode": "IL",
        "currencyCode": "ILS",
        "defaultArea": "tel_aviv",
        "host": "https://tabit-dev-app.tabit-dev-online.com",
        "tabitAppURL": "https://tabit-dev-app.tabit-dev-online.com",
        "tabitWebURL": "https://tabit-dev-web.tabit-dev-online.com",
        "useHash": true,
        "tabitAPI": "https://il-int-ros.tabit-stage.com",
        "tabitLoyaltyAPI": "https://tabitloyaltyapi-dev.azurewebsites.net/api",
        "tabitBridge": "https://il-dev-bridge.tabit-int.com",
        "deliveryServiceURL": "https://il-delivery-service.tabit-int.com",
		"bookingAPI": "https://il-int-tgm-api.tabit-stage.com",
		"bookingAPIBeta": "https://il-int-tgmbeta-api.tabit-stage.com",
        "tabitOrderURL": "https://il-int-tabitorder.tabit-stage.com/",
        // "tabitOrder_tabitAPI": "https://il-ros.tabit-stage.com",
        "tabitOrder_tabitAPI": "https://il-int-ros.tabit-stage.com",
		"tabitGuestURL": "https://il-int-tgmbeta-rsv.tabit-stage.com",
        "tabitGuestGUID": "01599FFA-6E6C-40A9-B2FB-E4EB6740041C",
		"tabitDocumentsURL": "https://documents.tabit-dev.com",
		"s3BucketURL": "https://inpact-int.s3.amazonaws.com",
        "threeDImagesAddress": "https://inpact-int.s3.eu-west-1.amazonaws.com/3d",
		"googleKey": "AIzaSyAcHoyegZoiiQhvgxLotJrvmgoQJFy6hGI",
        "recaptchaSiteKey": "6LczXmknAAAAAI7Rdx5MPb5kSmHqprDxcWtL3Src",
		"tabitDefaultOrganizationId": "53eb1ee2e6c77111203d8501",
        "tabitClientID":"urfoxj8ymmz2bbfolofq4xsjr9psc3q7ii76b2n3",
        "loyaltyJoinChannelGUID": "BF10B302-8152-4E78-B496-7AE7A7513983",
        // Deprecated because of Deep links use
        // "appleID": "1463575038", "1463575038",
        "appURLScheme": "tabitapp",
        "googleTagManagerId": "GTM-NRSRVPD",
        "googleAnalyticsMeasurmentId": "G-Y4KX0GXS87",
        "blockGoogleImages": true,
        "isDev": true,
        "allowedPathsWhileUnderConstruction": [],
        "firebaseConfig": {
            "apiKey": "AIzaSyBPCFgEZRDlqTeqx3IeXfNxV5suLsrMMck",
            "authDomain": "tabit-app.firebaseapp.com",
            "databaseURL": "https://tabit-app.firebaseio.com",
            "projectId": "tabit-app",
            "storageBucket": "tabit-app.appspot.com",
            "messagingSenderId": "101429635305",
            "appId": "1:101429635305:web:1b9ddf15542c8b91996878",
            "measurementId": "G-ES76J6HFM0",
            "vapidKey": "BF4Dk-aO2iwgAIZZlQMlwfyb30vpAgNYTN4KQvUiIuz9tn-4HISOJZdGkXBhZ5KXa_YKncW7ZS5h1tsWUSJjo1w"
        },
        "leumiPaySdkURL": "https://paycheckoutuat.creditguard.co.il/sdk/web/pay-checkout/pay-checkout.js",
        "leumiPayCssURL": "https://paycheckoutuat.creditguard.co.il/sdk/web/pay-checkout/bundle.css",
        "applePaySdkURL": "",
        "layout":{
            "dashboard":{
                "events": {
                    "enabled": true
                }
            },
            "showAppBanner": {
                "enabled": true
            },
            "articles": {
                "enabled": true
            }
        },
        "tabitPayURL": "https://il-pay.tabit-int.com",
        "apmServer": "https://c8783b894a2c41668ca2d7233e7bd375.apm.eu-west-1.aws.cloud.es.io:443",
        "localization": {
            "path": "https://localization.tabit-int.com",
            "region": "il"
        }
    }
};
