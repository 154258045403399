import { AfterViewInit, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignInComponent } from '../sign-in.component';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { CdkTrapFocus } from '@angular/cdk/a11y';

@Component({
    selector: 'sign-in-dialog',
    templateUrl: './sign-in-dialog.component.html',
    styleUrls: ['./sign-in-dialog.component.scss'],
    standalone: true,
    imports: [CdkTrapFocus, MatIconButton, MatIcon, SignInComponent]
})
export class SignInDialogComponent implements AfterViewInit {

    constructor(
        public dialogRef: MatDialogRef<SignInDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    ) { }

    ngAfterViewInit() {
        // this.appService.updatePrivateStore('TOSideMenuOpen', false);
    }

    closeDialog() {
        this.dialogRef.close();
    }

}