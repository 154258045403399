import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';
import { Location } from '@angular/common';

import { AppService } from '../../app.service';
import { EntityService } from '../../_core/entity.service';
import { TabitpayUtilsService } from './tabit-pay.utils.service';
import { TabitpayExpayService } from './tabit-pay.expay.service';
import { OrganizationsService } from '../../_core/organizations.service';
import { StorageService } from '../../_core/storage.service';
import { DialogsService } from '../../_core/dialogs.service';
import { DefaultCurrencyPipe } from '../../_core/pipes';

import { assignIn, get, each, cloneDeep, find, isArray, map, remove, filter, round, forEach, sumBy } from 'lodash-es';
import 'socket.io-client';

import { environment } from '../../../environments/environment';

const ccPaymentOverflowAmount = 1500;

// allowed payment methods
export enum PaymentType {
    cash = 'cash',
    '10bis' = '10bis',
    Cibus = 'Cibus',
    creditCard = 'creditCard',
    Meshulam = 'Meshulam',
    LeumiPay = 'LeumiPay',
}

@Injectable({
	providedIn: 'root',
})
export class TabitpayService {
    private orderChangedSource = new Subject<void>();
    public orderChanged$ = this.orderChangedSource.asObservable();

    public enablePay: BehaviorSubject<boolean> = new BehaviorSubject(false);

    public tdOrderTypeMap: any = {
        "Seated": "eatin",
        "Delivery": "delivery",
        "TA": "takeaway"
    };

    public $storage: any = {}
    public socket: any = null;

    constructor(
        public appService: AppService,
        public entityService: EntityService,
        public orderUtils: TabitpayUtilsService,
        public expayService: TabitpayExpayService,
        private organizationsService: OrganizationsService,
        private dialogsService: DialogsService,
        private storageService: StorageService,
        public currencyPipe: DefaultCurrencyPipe,
        private location: Location,
    ) { }

    start(site, params:any = {}) {
        this.$storage = {
            rosConfig: assignIn({
                payments: {},
                messaging: {
                    hub: {}
                }
            }, site),
            siteLogoURL: get(this.organizationsService.getOrganization(site.organization), 'logo'),
            organization: site.organization,
            site: site.config,
            enableAddItems: false,
            forceShowPrices: params.price != undefined
        };
    }

    unloadOrder() {
        if (this.socket) {
            this.socket.disconnect();
            this.socket = null;
        }

        // comment out the following line to keep the order in memory
        // this.$storage = {};
    }

    loadOrder(siteId, orderId) {
        return this.entityService.getOrderById(siteId, orderId)
        .then(response => {
            this.$storage.orderId = orderId;
            let order = this.billToOrderView(response);
            this.prepareFixedOrder(order);
            this.linkOrder(order.order_id, order.site_id);
            return order;
        });
    }

    interceptOrderFromSocket(orderOnMessage, options: any = {}) {
        if (!this.$storage.showPrices && orderOnMessage.billed) {
            this.$storage.enablePay = this.$storage.order.balanceDue > 0;
            this.enablePay.next(this.$storage.enablePay);
            this.$storage.showPrices = true;
        }
        if (
            !this.$storage.freezeAutoOrderUpdate &&
            this.$storage.order &&
            orderOnMessage &&
            this.$storage.order.order_id === orderOnMessage._id
        ) {
            const closed = !!orderOnMessage.closed;
            const actualBalance = orderOnMessage.balance;
            const knownBalance = this.$storage.order.printData.variables.BAL_DUE * 100;
            const balanceChanged = (actualBalance != knownBalance || this.$storage.order?.totals?.totalTips != orderOnMessage.totals?.totalTips || this.$storage.order?.totals?.totalAmount != orderOnMessage.totals.totalAmount)
            if (balanceChanged || closed != this.$storage.order.closed || ['billed', 'restricted'].includes(options.op)) {
                if (closed) {
                    const newOrder = cloneDeep(this.$storage.order);
                    newOrder.closed = true;
                    this.refreshPayOrder(newOrder, options.op)
                } else {
                    this.loadOrder(this.$storage.organization, this.$storage.order.order_id)
                    .then(newOrder => {
                        this.refreshPayOrder(newOrder, options.op);
                    });
                }
            }
        }
    }

    refreshPayOrder(newOrder, operation: 'billed' | 'restricted' = 'billed' ) {
        let that = this;
        window.setTimeout(() => {
            delete that.$storage.freezeAutoOrderUpdate;
            var needMoveState = false;
            var needRefreshState = false;
            var currentOrder = that.$storage.order;
            if (that.$storage.isInCheckout) {

                if (newOrder.balanceDue == 0) {
                    //nothing to pay for
                    needMoveState = true;
                } else if (newOrder.itemsTotal != currentOrder.itemsTotal) {
                    //order items changed
                    needMoveState = true;
                } else {
                    needRefreshState = newOrder.balanceDue != currentOrder.balanceDue;
                    //check partial payment
                    if (currentOrder.isPartialPayment) {
                        var partialPayment = currentOrder.grandTotal;
                        if (partialPayment > newOrder.balanceDue) {
                            needMoveState = true;
                        } else {
                            //copy partial payments details into new order
                            newOrder.isPartialPayment = true;
                            newOrder.grandTotal = currentOrder.grandTotal;

                        }
                    }
                    if (!needMoveState) {
                        newOrder.gratuity = currentOrder.gratuity;
                        newOrder.gratuityAmount = currentOrder.gratuityAmount;
                        newOrder.grandTotalPlusTip = currentOrder.grandTotalPlusTip;
                    }
                }
            }

            this.prepareFixedOrder(newOrder, operation);

        }, 0);
    }

    canAddItems() {
        const $storage = this.$storage;
        const tpConfig = get($storage.site, 'tabitpay');
        if (!tpConfig.enableAddItems) return false;
        let timeSlot;
        if (tpConfig.addItems_schedule) timeSlot = find($storage.site.timeslots, { _id: tpConfig.addItems_schedule });
        if (!timeSlot) timeSlot = $storage.site.workHours;
        if (!this.orderUtils.isTimeslotsActive(timeSlot)) return false;
        return true;
    }

    prepareFixedOrder(newOrder, operation?) {
        const $storage = this.$storage;
        $storage.orderMode = 'fixedorder';
        $storage.orderValid = true;
        if ($storage.order) {
            newOrder.address = $storage.order.address;
            newOrder.contact = $storage.order.contact;
            newOrder.$ccinfo = $storage.order.$ccinfo;
        }

        $storage.order = newOrder;
        const printData = $storage.order.printData || { variables: {} };

        if (!$storage.wasPrepared || operation == 'restricted') {
            $storage.wasPrepared = true;

            $storage.enablePartialPayment = true;
            $storage.enableGratuity = true;
            $storage.enablePassOrder = true;
            $storage.orderSource = newOrder.source;

            let site = $storage.site || {};
            const settings = site.settings || {};

            switch (newOrder.orderType) {
                case "Seated": //eatin
                    $storage.enableGratuity = true;
                    $storage.gratuities = get(settings.gratuities, [0]) ? settings.gratuities : this.$storage.rosConfig?.region == 'US' ? [18, 20, 22] : [12, 15, 18, 20];
                    $storage.disableCashPayment = true;
                    $storage.showPrices = newOrder.isBilled || !settings.hideTabitPayPricesBeforeBill;
                    $storage.enableAddItems = this.canAddItems();
                    break;
                case "TA": //takeaway
                    $storage.enableGratuity =  site?.takeaway?.enableGratuity ? true : false;
                    $storage.gratuities = [10, 15, 20, 25],
                    $storage.showPrices = true;
                    $storage.isOTC = true;
                    $storage.$orderer = printData.variables.ORDERER_NAME;
                    break;
                case "OTC":
                    const lifeCycleName = get(newOrder.lifeCycle, 'name');
                    if (lifeCycleName == 'opened' || lifeCycleName == 'fired') {
                        $storage.enableGratuity = true;
                        $storage.gratuities = this.$storage.rosConfig?.region == 'US' ? [18, 20, 22] : isArray(settings.gratuities) && settings.gratuities?.length ? settings.gratuities : [12, 15, 18, 20];
                        $storage.showPrices = true;
                        $storage.isOTC = true;
                        $storage.$orderer = printData.variables.ORDERER_NAME;
                    }
                    break;
                case "Delivery": //delivery
                    $storage.enableGratuity = settings?.enableDeliveryGratuity ? true : site?.delivery?.enableGratuity ? true : false;
                    $storage.gratuities = isArray(settings.courierGratuities) && settings.courierGratuities.length ? settings.courierGratuities : [10, 15, 20, 25];
                    $storage.showPrices = true;
                    $storage.isDelivery = true;

                    $storage.isOTC = true;
                    var arrAddress = [];
                    each(['ORDERER_ADDRESS_STREET', 'ORDERER_ADDRESS_HOUSE', 'ORDERER_ADDRESS_CITY'], function (att) {
                        var v = printData.variables[att];
                        if (v) {
                            if (att == 'ORDERER_ADDRESS_CITY' && arrAddress.length) v = ", " + v;
                            arrAddress.push(v);
                        }
                    });
                    $storage.$deliveryAddress = arrAddress.join(" ");
                    $storage.$orderer = printData.variables.ORDERER_NAME;
                    $storage.order.address = {
                        formatted_address: printData.$deliveryAddress,
                        entrance: printData.variables.ORDERER_ADDRESS_ENTRANCE,
                        floor: printData.variables.ORDERER_ADDRESS_FLOOR,
                        apartment: printData.variables.ORDERER_ADDRESS_APARTMENT
                    }
                    break
            }

            if ($storage.forceShowPrices) $storage.showPrices = true;

            // fix gratuities
            remove($storage.gratuities, val => { return isNaN(val as any) || Number(val) <= 0 });
            $storage.gratuities = map($storage.gratuities, val => { return Math.ceil(Number(val)) });
            if ($storage.gratuities.length > 4) $storage.gratuities = $storage.gratuities.sort().slice(0, 4);

            $storage.paymentAccounts = filter($storage.site.paymentAccounts, (pa) => {
                if (pa.paymentType == 'cash' || pa.paymentType == "LeumiPay" || !pa.tabitPayActive) return false;
                if (pa.paymentType == "10bis" && !settings.enableTenbis) return false;
                if (pa.sch && !$storage.activeTimeslots[pa.sch]) return false;
                pa.enableGratuity = pa.enableTabitpayTip === true;

                if (pa.paymentType == 'CreditGuard') {
                    if (!settings.showCreditGuardWindow) {
                        pa.paymentType = "creditCard";
                    }
                }

                if (pa.paymentType == 'Meshulam') {
                    pa.cssClass = 'bit-pay-btn';
                }

                return true;
            });

            if ($storage.enableGratuity) {
                const gratuityPMS = filter($storage.paymentAccounts, { enableGratuity: true });
                if (gratuityPMS.length) {
                    if (gratuityPMS.length < $storage.paymentAccounts.length) {
                        let paText = map(gratuityPMS, (pm, index) => {
                            let ret = pm["name"];
                            if (index === 0) return ret;
                            return this.appService.translate('_and') + ret;
                        });
                        $storage.gratuityPAWarning = this.appService.translate("MESSAGES.GRATUITY_PA_WARNING", { pms: paText.join(" ") });
                    }
                } else {
                    $storage.enableGratuity = false;
                }
            }

            // ------------------------------------------------------------------------------------------------->
            // wallet
            // ------------------------------------------------------------------------------------------------->

            this.createUserWalletPms();
        } else {
            if (newOrder.isBilled) $storage.showPrices = true;
        }

        $storage.enablePay = $storage.showPrices && $storage.order.balanceDue > 0;
        this.enablePay.next(this.$storage.enablePay);

        // Check if order restricted
        $storage.orderRestricted = get($storage.order, 'restrictions.bill.restricted', false);
        if ($storage.orderRestricted) $storage.showPrices = false;

        //prepare fixed order print data
        var printOffers = get(printData, 'collections.ORDERED_OFFERS_LIST', []);
        $storage.hasOffers = printOffers.length > 0;

        each(printOffers, (pOffer) => {
            let items = pOffer.ALL_ORDERED_ITEMS;
            for (let i = 0; i < items.length; i++) {
                let pItem = items[i];
                var pMods = pItem.MODIFIERS_LIST;
                var hasMods = false;
                var hasModsExclude = false;
                each(pMods, function (pMod) {
                    pMod._show = false;
                    if (pMod.EXCLUDED) {
                        hasModsExclude = true;
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                    } else if (!pMod.IS_DEFAULT_SELECTED && !pMod.FORMATION) {
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                        hasMods = true;
                    } else if (pMod.MODIFIER_PRICE && pMod.MODIFIER_PRICE > 0) {
                        pMod._name = pMod.MODIFIER_NAME;
                        pMod._show = true;
                        hasMods = true;
                    }
                });
                pItem._showItem = false;
                if (hasMods) {
                    pItem._hasMods = true;
                };
                if (hasModsExclude) {
                    pItem._hasModsExclude = true;
                };
                if (hasMods || hasModsExclude) {
                    pItem._showItem = true;
                };
            };
        });
        this.orderChangedSource.next();
    }

    createUserWalletPms() {
        // don't create wallet details for unauthenticated users
        if (!this.appService.isAuthUser()) return;
        let $storage = this.$storage;
        let userWallet = get(this.appService, 'user.wallet');
        if (userWallet) {
            let userPms = get(this.appService, 'user.wallet.payments', []);
            let defPM;
            var walletPms = [];
            each(userPms, (pm) => {
                $storage.paymentAccounts.find((spm) => {
                    if (spm.paymentType === pm.paymentType || (spm.paymentType == 'CreditGuard' && pm.paymentType == 'creditCard')) {
                        let newPM = cloneDeep(pm);
                        newPM.walletPayment = newPM._id;
                        newPM.account = spm._id;
                        newPM.merchantNumber = spm.merchantNumber;
                        newPM.enableGratuity = spm.enableGratuity;
                        newPM.name = spm.name;
                        walletPms.push(newPM);
                    }
                });
            });
            if (walletPms.length) {
                $storage.walletPms = walletPms;
                defPM = find(walletPms, o => { return o.isDefault });
                if (!defPM) defPM = walletPms[0];
                if (defPM) {
                    $storage.wallet = {
                        pms: walletPms,
                        isSinglePM: walletPms.length == 1,
                        ccinfo: defPM
                    };
                }
            }
        }
    }

    // prepare bull for view
    billToOrderView(bill) {
        const tipBehavior = get(bill, 'order.settings.tipBehavior');
        const isTipCalculatedInTotal = tipBehavior == "declared" || tipBehavior == "declaredWithoutVat";

        let balanceDue = bill.printData.variables.BAL_DUE;
        let totalSales = bill.printData.variables.TOTAL_AMOUNT;

        if (isTipCalculatedInTotal) {
            const tipAmount = get(bill.printData.variables, 'TOTAL_TIPS', 0);
            totalSales -= tipAmount;
            bill.printData.variables.TOTAL_AMOUNT = totalSales;
        }

        let order = bill.order;
        let appService = this.appService;

        if (!order.diners?.length) {

        } else {
            each(order.diners, function (diner, index) {
                diner.name = diner.tags && diner.tags.name || (appService.translate("DINER") + " " + (index + 1));
                diner.offers = []
            });
        };

        let defaultDinerId;
        if (bill.order.orderType == "Seated") {
            var generalDiner = {
                _id: -9999,
                name: appService.translate("GENERAL_DINER"),
                tags: {
                    name: order.orderer.name
                },
                offers: []
            }
            order.diners.push(generalDiner);
            defaultDinerId = generalDiner._id;
        } else {
            defaultDinerId = get(order, "diners[0]._id");
        }

        each(order.orderedOffers, function (offer) {

            if (!offer.diner && !offer.adHocOffer) offer.diner = defaultDinerId;
            var diner = find(order.diners, { _id: offer.diner }) || order.diners[0];

            offer._isRefund = offer.amount < 0;
            offer.$$amount = Math.abs(offer.amount);
            var items = [];

            each(offer.orderedItems, function (itemId) {
                var item: any = find(order.orderedItems, { _id: itemId });
                var hasSummary;
                var summary: any = {};
                if (item.selectedModifiers && item.selectedModifiers.length) {
                    var _arr = [];
                    each(item.selectedModifiers, function (mod) {
                        if (!mod.isDefault) {
                            hasSummary = true;
                            _arr.push(mod.name);
                        }
                    });
                    summary.modWith = _arr.join(', ');
                };
                if (item.removedModifiers && item.removedModifiers.length) {
                    hasSummary = true;
                    var _arr = [];
                    each(item.removedModifiers, function (o) { if (o.name) _arr.push(o.name) });
                    summary.modWithout = _arr.join(', ');
                };
                if (hasSummary || item.note) item._summary = summary;

                items.push(item);
            });
            if (items.length == 1 && !items[0]._summary) offer._hideItems = true;
            offer._orderedItems = items;

            diner.offers.push(offer);
        });

        order.diners = filter(order.diners, function (diner) {
            return diner.offers.length > 0;
        });

        let billPayments = filter(bill.order.payments, pa => {
            if (pa.isPayout) {
                let payment: any = find(bill.order.payments, { _id: pa.refundedPaymentId });
                if (payment) {
                    payment.amount -= pa.amount;
                    if (pa.auxAmount) {
                        if (!payment.auxAmount) payment.auxAmount = 0;
                        payment.auxAmount -= pa.auxAmount;
                    }
                }
                return false;
            }
            return true;
        });

        let payments = [];
        each(billPayments, payment => {
            if (!payment.paid) return;

            let customerName = get(payment, 'customerDetails.name', 'תשלום מלצר');
            let pm = find(payments, { name: customerName });
            if (!pm) {
                pm = { name: customerName, total: 0, tip: 0 }
                payments.push(pm);
            }
            pm.total += (payment.amount / 100);
            if (payment.auxAmount) pm.tip += (payment.auxAmount / 100);

        });

        const orderView = {
            order_id: bill.order._id,
            site_id: bill.organizationId,
            balanceDue: balanceDue,
            currentBalance: bill.printData.variables.TOTAL_PAYMENTS,
            itemsTotal: totalSales,
            __v: bill.order.__v,
            total: totalSales,
            grandTotal: balanceDue,
            printData: bill.printData,
            closed: !!bill.order.closed,
            orderType: bill.order.orderType,
            mode: this.tdOrderTypeMap[bill.order.orderType],
            isBilled: get(bill.order, "billed.at") != null,
            diners: order.diners,
            source: order.source,
            restrictions: order.restrictions,
            payments,
            lifeCycle: bill.order.lifeCycle,
            _order: bill.order
        };

        return orderView;
    }

    // --------------------------------------------------------------------------------------------------------------->
    // SOCKET UTILS
    // --------------------------------------------------------------------------------------------------------------->

    linkOrder(orderId, organization) {
        if (!this.socket && this.$storage.rosConfig.messaging.hub.url) {
            const wsUrl = this.$storage.rosConfig.messaging.hub.url + "/events";
            this.socket = io(wsUrl, {
                transports: [ 'websocket' ]
            });
            this.socket.on('connect', () => {
                // console.log('socket io connected');
                this.getPublicToken(organization).then(token => {
                    this.socket.emit('order', { accessToken: token, orderId: orderId });
                });
            });
            this.socket.on('order', data => {
                this.interceptOrderFromSocket(data.content, data.headers);
                //console.log('Got event:', JSON.stringify(data));
            });
            this.socket.on('disconnect', function () {
                //console.log('socket io dis-connected');
            });
        }
    };

    getPublicTokenData(organization) {
        const data = {
            grant_type: 'client_credentials',
            client_id: this.appService.appConfig.tabitClientID,
            scope: organization ? `online-account organization/${organization}` : 'online-account'
        };

        return this.servicePost({ url: '/oauth2/token', data }).then(function (res) {
            return res;
        })
    }

    getPublicToken(organization) {
        return this.getPublicTokenData(organization).then(function (ret) {
            return ret && ret.access_token;
        })
    }

    // --------------------------------------------------------------------------------------------------------------->
    // MODAL UTILS
    // --------------------------------------------------------------------------------------------------------------->

    checkForSignature(ccinfo) {
        if (!this.$storage.requiereCreditSign || ccinfo.paymentType != 'creditCard') {
            return Promise.resolve();
        } else {
            return this.appService.getSignature().then((signature) => {
                ccinfo.signature = signature;
                return;
            });
        }
    }

    checkForIdCard(ccinfo) {
        let site = this.$storage?.site ? this.$storage?.site : this.$storage?.config;
        if (ccinfo.account || ccinfo.accountId) {
            let paymentAccounts = find(site.paymentAccounts, { _id: ccinfo.account || ccinfo.accountId });
            if (ccinfo.idCard || !paymentAccounts.requireIdPhoneTrans || ccinfo.paymentType != 'creditCard') return Promise.resolve();
        }
        else {
            if (ccinfo.idCard || !this.$storage.site.settings.requireIdPhoneTrans || ccinfo.paymentType != 'creditCard') return Promise.resolve();
        }
        let paymentId = ccinfo.walletPayment;
        if (paymentId) {
            let idCard = this.orderUtils.restoreKEY('wpic_' + paymentId);
            if (idCard) {
                ccinfo.idCard = idCard;
                return Promise.resolve();
            }
        }
        return this.appService.prompt({
            content: this.appService.translate("ENTER_IDCARD_MESSAGE", { card: ccinfo.pan || ccinfo.displayPan }),
            label: 'ENTER_IDCARD',
            template: 'idCard',
            contentClass: 'text-base font-bold pb-2'
        }).then(res => {
            this.orderUtils.cacheKEY('wpic_' + paymentId, res);
            ccinfo.idCard = res;

            // Encrypting the ID Number in the Bridge
            this.entityService.addMetasToPaymentMethod(ccinfo);

            return;
        });

    }

    showMessageWithPhone(message, phone) {
        return Promise.resolve();
    }

    // --------------------------------------------------------------------------------------------------------------->
    // GRATUITY UTILS
    // --------------------------------------------------------------------------------------------------------------->
    fixGratuity() {
        var gratuity = this.$storage.order.gratuity;
        var total = this.$storage.order.grandTotal;
        var grandTotalPlusTip = total;
        var gratuityAmount = 0;
        if (gratuity) {
            if (gratuity.type == 'p') {
                const pVal = gratuity.percent || 0;
                if (pVal && gratuity.amount) {
                    gratuity.amount = this.roundGratuity(gratuity.amount);
                } else {
                    gratuity.amount = this.calculateGratuityFromPercentage(pVal);// _.round(total * (pVal / 100), MetaService.local.decimals);
                }
                grandTotalPlusTip += gratuity.amount;
                gratuityAmount = gratuity.amount;
            } else if (gratuity.type == 'm' || gratuity.type == 'mm') {
                if (!gratuity.amount || isNaN(gratuity.amount)) {
                    gratuity.amount = 0;
                    gratuity.percent = 0;
                } else {
                    gratuityAmount = round(gratuity.amount, 2);
                    grandTotalPlusTip += gratuity.amount;
                    gratuity.percent = gratuity.amount / total * 100
                }
            }
        };
        this.$storage.order.gratuityAmount = gratuityAmount;
        this.$storage.order.grandTotalPlusTip = grandTotalPlusTip;
    };


    calculateGratuityFromPercentage(pVal, totalAmount?) {
        var total = totalAmount || this.$storage.order.grandTotal;
        var unroundedGratuity = total * (pVal / 100);
        unroundedGratuity = Math.max(unroundedGratuity, 1);
        return this.roundGratuity(unroundedGratuity);
    };

    roundGratuity(unroundedGratuity) {
        var minDenom = (this.$storage.rosConfig.currencySettings && this.$storage.rosConfig.currencySettings.minimalTipDenomination) || 100;
        minDenom = minDenom / 100;

        var roundedGratuity = round(unroundedGratuity / minDenom) / (1 / minDenom);
        return round(roundedGratuity, 2);
    };

    // --------------------------------------------------------------------------------------------------------------->
    // PAYMENT UTILS
    // --------------------------------------------------------------------------------------------------------------->

    processPayment(args) {
        args = args || {};
        const phone = this.$storage.site.phone;

        if (this.$storage.orderClosed) {
            return Promise.reject({
                type: 'SERVER_ERROR',
                message: this.appService.translate("MESSAGES.SERVER_ERROR", { phone })
            });
        }
        const havePayments = args.ccinfo || args?.splitPayments?.length;
        if (!havePayments) {
            return Promise.reject({
                type: 'SERVER_ERROR',
                message: this.appService.translate("MESSAGES.PROBLEM_IN_PAYMENT_INPUT", { phone })
            });
        }

        if (!args.ccinfo && args?.splitPayments?.length === 1) args.ccinfo = args.splitPayments[0];

        // contains the site/organization/branch
        const siteId = this.$storage.site._id;
        console.debug("Start payment for site: ", siteId);

        const parsedOrder = this.orderUtils.getPreparedOrder(this.$storage, false, args);
        console.debug("payment order: ", JSON.stringify(parsedOrder));

        this.$storage.order.haveErrors = false;

        const addPaymentToWallet = args.updatePaymentInfo;
        const paymentsAddedOnThisCall = [];
        this.$storage.freezeAutoOrderUpdate = true;

        return new Promise<void>((resolve, reject) => {
            this.createOrLoadOrder(parsedOrder)
                .then(resultOrder => {
                    let ptp = cloneDeep(args.ccinfo);
                    if (!ptp.amount) ptp.amount = parsedOrder.balanceDue + args.gratuityAmount;
                    ptp.customerDetails = parsedOrder.orderer;
                    ptp.tip = args.gratuityAmount;
                    ptp.addPaymentToWallet = addPaymentToWallet;
                    if (this.ccPaymentsExceedAmount(args, resultOrder)) {
                        return Promise.reject({
                            type: 'ERROR',
                            message: this.appService.translate("MESSAGES.CC_PAYMENT_OVERFLOW", { amount: ccPaymentOverflowAmount })
                        });
                    }
                    return this.processSinglePayment(ptp, resultOrder, paymentsAddedOnThisCall).then((processedOrder) => {
                        return resultOrder;
                    });
                }).then((resultOrder) => {
                    const handoffData: any = { status: 'ready' };
                    if (this.$storage.orderMode === "fixedorder") {
                        handoffData.includedPayments = paymentsAddedOnThisCall.map(function (ap) {
                            return ap._id
                        });
                    }
                    if (this.$storage.orderSource == "webCallCenter") {
                        return this.servicePost({
                            url: '/online-shopper/orders/' + resultOrder._id + '/handoff',
                            data: handoffData,
                            loadingCaption: 'PAYMENT_IN_PROGRESS'
                        }).then(() => {
                            return resultOrder
                        });
                    } else {
                        return resultOrder;
                    }
                }).then((resultOrder) => {
                    if (this.$storage.orderMode != "fixedorder") {
                        this.$storage.orderClosed = true;
                    }

                    this.$storage.order.reference = resultOrder.number;

                    // itai you should set order delay/prep time here, make sure args is still defined you are using chained promises which may cause context loss
                    if (args?.orderDelay) {
                        this.$storage.order.orderDelay = args.orderDelay.date;
                    } else {
                        this.$storage.order.prepTime = this.$storage.minOrderDelay;
                    }
                }).then(() => {
                    resolve();
                }).catch((err) => {
                    console.debug('err', err);
                    if (err?.type) {
                        if (err.type == 'CANCELED') {
                            reject(err);
                        } else if (err.order && (err.type === 'ORDER_RELOAD_NEEDED')) {
                            //TODO: reconnect socket + update subject event
                            this.loadOrder(this.$storage.organization, err.order._id).then(newOrder => {
                                newOrder["grandTotalPlusTip"] = newOrder.balanceDue;
                                this.refreshPayOrder(newOrder);
                                reject(err);
                            });
                        } else if (err.type === 'ERROR') {
                            reject({
                                type: err.type,
                                message: err.message,
                            });
                        } else {
                            this.handlePartialPaymentsError(err)
                                .then(function (revisedErr) {
                                    reject(revisedErr);
                                });
                        }
                    } else {
                        const formattedError = this.formatError(err);
                        reject(formattedError);
                    }
                }).then(res => {
                    delete this.$storage.freezeAutoOrderUpdate;
                });
        });
    }

    ccPaymentsExceedAmount(args, order): Boolean {
        let isPaymentOverflowing = false;
        // The logic works only for IL and not for Seated orders
        if (
            this.$storage.rosConfig.region !== 'IL' &&
            this.$storage.order.orderType !== 'Seated' &&
            !['creditCard', 'creditGuard'].includes(args.ccinfo.paymentMethod || args.ccinfo.paymentType)
        ) return isPaymentOverflowing;

        // If there are already payments on the order
        let sameCreditCardPayments = [];
            forEach(order.payments, payment => {
            if (
                ['creditCard', 'creditGuard'].includes(payment.paymentMethod || payment.paymentType || payment.tenderType) &&
                payment.last4 == args.ccinfo.displayPan
            ) sameCreditCardPayments.push(payment);
        });
        const creditCardsSum = sumBy(sameCreditCardPayments, payment => {
            if (payment._type !== 'CreditCardRefund') return (payment.amount / 100 || 0);
        });
        // If there's args.ccinfo.amount it's a partial payment (not covering the whole total amount)
        const sumToCalculate = args.ccinfo.amount ?
            creditCardsSum + args.ccinfo.amount
            :
            creditCardsSum + this.$storage.order.grandTotalPlusTip
        ;

        isPaymentOverflowing = sumToCalculate > ccPaymentOverflowAmount;

        return isPaymentOverflowing;
    }

    processSinglePayment(paymentInfo, resultOrder, paymentsAddedOnThisCall) {
        let savePayment = null;
        let parsedPayment;
        let that = this;
        return Promise.resolve().then(() => {
            let releaseOrder = paymentInfo.releaseOrder;
            parsedPayment = that.orderUtils.getPreparedPayment(paymentInfo);

            if (this.$storage.order.total) {
                parsedPayment.orderTotal = this.$storage.order.total * 100;
            }

            if (!parsedPayment.$$isExternal && paymentInfo.addPaymentToWallet) {
                savePayment = that.orderUtils.ccInfoToWalletPayment(paymentInfo);
                savePayment.cvv = paymentInfo.cvv;
            }
            delete paymentInfo.addPaymentToWallet;
            console.debug("payment payment info: ", JSON.stringify(parsedPayment));

            // if (parsedPayment.cvv === 'MISSING') {
            //     return that.orderUtils.getCVV(parsedPayment.number).then(cvv => {
            //         // If the cvv is missing, we must add it to the payment
            //         parsedPayment.cvv = cvv.toString();
            //         return that.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall)
            //             .then((resultOrder) => {
            //                 if (paymentInfo.walletPayment) {
            //                     // To protect from the possibilty that a payment don't have an idCard and
            //                     // we're trying to save the cvv from the parsedPayment
            //                     if (parsedPayment.cvv && !paymentInfo.cvv) paymentInfo.cvv = parsedPayment.cvv;
            //                     that.entityService.addMetasToPaymentMethod(paymentInfo, parsedPayment.cvv, paymentInfo.idCard, true);
            //                 }
            //                 return resultOrder;
            //             });
            //     });
            // }
            return that.processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall);
        }).then((resultOrder:any) => {
            if (savePayment) {
                let cvvToSave = savePayment.cvv;
                delete savePayment.cvv;
                savePayment.verificationCode = resultOrder.verificationCode;
                savePayment.deviceId = that.appService.clientId;
                return that.entityService.addPaymentMethodToWallet(savePayment)
                .then(() => {
                    that.createUserWalletPms();
                    return resultOrder;
                });
            }
            return resultOrder;
        })
    }

    createOrLoadOrder(parsedOrder) {
        let that = this;
        if (parsedOrder.order_id) {
            return that.serviceGet({
                url: "/online-shopper/orders/" + parsedOrder.order_id,
                loadingCaption: 'LOADING_ORDER'
            }).then(function (loadedOrder) {
                if (loadedOrder.balance !== parsedOrder.balance) {
                    throw {
                        type: 'ORDER_RELOAD_NEEDED',
                        message: that.appService.translate("MESSAGES.ORDER_RELOAD_NEEDED"),
                        order: loadedOrder
                    };
                }
                loadedOrder.source = 'tabitPay';
                return loadedOrder;
            });
        }
    }

    handlePartialPaymentsError(err) {
        let that = this;
        return new Promise((resolve, reject) => {
            if (err.type === 'PARTIAL_PAYMENT_ERROR') {
                that.servicePost({
                    url: '/online-shopper/orders/' + err.order._id + '/handoff',
                    data: { status: 'attendanceRequired' },
                    loadingCaption: 'PAYMENT_IN_PROGRESS'
                })
                    .then(function () {
                        err.orderClosed = true;
                        if (that.$storage.orderMode != "fixedorder")
                            that.$storage.orderClosed = true;
                        that.$storage.order.reference = err.order.number;
                        that.$storage.order.haveErrors = true;
                        resolve(err);
                    })
                    .catch(function (tmpErr) {
                        console.debug("Error on release bad order", tmpErr);
                        err.orderClosed = true;
                        if (that.$storage.orderMode != "fixedorder")
                            that.$storage.orderClosed = true;
                        that.$storage.order.reference = err.order.number;
                        that.$storage.order.haveErrors = true;
                        resolve(err);
                    });
            } else resolve(err);
        });
    }

    formatError(err) {
        let code = get(err, 'error.code'), errDesc;
        switch (code) {
            case 404001: errDesc = "SERVER_MESSAGES.ORDER_CLOSED_PAYED"; break;
            default: errDesc = "MESSAGES.SERVER_ERROR";
        }
        return {
            type: 'SERVER_ERROR',
            code: err.code,
            message: this.appService.translate(errDesc)
        }
    }

    formatInventoryOutOfStockError(outOfStockItems, _storage) {
        let minStock = _storage.rosConfig.outOfStockWhenUnder;
        let items = outOfStockItems.map(o => {
            let name = (_storage.catalog.items.find(i => i._id === o.item) || {}).name;
            let qty = o.required - Math.max(o.stockCount - minStock, 0);
            return `[ ${qty} x ${name} ]`
        }).join('\n');
        return { type: 'OUT_OF_STOCK', message: this.appService.translate('MESSAGES.OUT_OF_STOCK', { items }) };
    }

    verifyClientPayment(args, isRetry) {
        return this.orderUtils.getSMSverificationCode({
            phone: args.customerDetails.phone,
            walletPayment: args.walletPayment
        }, isRetry, this.$storage.organization);
    }

    processNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall) {
        let that = this;
        const baseLocation = environment.appConfig.useHash ? window.location.hash : this.location.path();
        let baseURL = this.appService.base(baseLocation, false);
        if (!baseURL.includes(`oid=${resultOrder._id}`)) baseURL += `&oid=${resultOrder._id}`;
        baseURL += "&r=";

        switch (parsedPayment.paymentType) {
            case "CreditGuard":
                const direction = this.appService.direction;
                const uiLang = this.appService.localeId == 'he-IL' ? 'heb' : 'eng';
                const textAlign = direction == 'rtl' ? 'right' : 'left';

                //this.appService.logTemplate("creditGuardBefore", {}, "Credit Guard Before");
                return that.servicePost({
                    url: `/online-shopper/finance/CreditGuardAccounts/${parsedPayment.account}/setup`,
                    data: {
                        amount: parsedPayment.amount,
                        redirectUrlOnSuccess: `${baseURL}success`,
                        redirectUrlOnError: `${baseURL}error`,
                        redirectUrlOnCancel: `${baseURL}cancel`,
                        iframeConfigurations:'{'
                        +'   "uiCustomData":{'
                        +'                   "uiLang": "' + uiLang + '", '
                        +'                   "customStyle":"body {direction:' + direction + '; text-align:' + textAlign + '} #card-number, #cvv, .cg-message-input {direction:ltr; text-align:' + textAlign + '} #cg-ghost-btns {text-align: center !important;}",'
                        +'                   "customText": {'
                        +'                                  "cg-popup-p":"' + this.appService.translate('EX_PAYMENT.popup-description') + '",'
                        +'                                  "cg-popup-title": "' + this.appService.translate('EX_PAYMENT.popup-title') + '",'
                        +'                                  "cg-sp-desc":"' + this.appService.translate('EX_PAYMENT.description') + '",'
                        +'                                  "cg-submit-btn":"' + this.appService.translate('EX_PAYMENT.submit-btn') + '",'
                        +'                                  "cg-form-title":"' + this.appService.translate('EX_PAYMENT.form-title') + '",'
                        +'                                  "cg-clear":"' + this.appService.translate('EX_PAYMENT.clear') + '",'
                        +'                                  "label-card-number":"' + this.appService.translate('EX_PAYMENT.credit_card_number') + '",'
                        +'                                  "label-expYear": "' + this.appService.translate('EX_PAYMENT.expiration') + '",'
                        +'                                  "cg-cancel":"' + this.appService.translate('EX_PAYMENT.cancel') + '",'
                        +'                                  "cg-key-currency":"' + this.appService.translate('EX_PAYMENT.currency') + '",'
                        +'                                  "cg-pd-title":"' + this.appService.translate('EX_PAYMENT.transaction_details') + '",'
                        +'                                  "cg-amount-title":"' + this.appService.translate('EX_PAYMENT.total_amount') + '",'
                        +'                                  "cg-popup-button":"' + this.appService.translate('EX_PAYMENT.click_here') + '"'
                        +'                                 },'
                        +'                  "paymentMethods": [{ "hidden": true, "type": "applepay" },{ "hidden": true, "type": "googlepay" }]'
                        +'                  }'
                        +'}',
                    }
                }).then(res => {
                    return that.expayService.showCreditGuardDialog(res).then(args => {
                        parsedPayment.providerInfo = {
                            "providerTransactionId": args["transactionId"]
                        }
                        parsedPayment.disableValidate = true;
                        //this.appService.logTemplate("creditGuardAfter", {}, "Credit Guard After");
                        return this.executeNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall)
                    });
                });
            case "Meshulam":
                return that.servicePost({
                    url: `/online-shopper/finance/MeshulamAccounts/${parsedPayment.account}/setup`,
                    data: {
						amount: parsedPayment.amount,
						tipAmount: get(parsedPayment, 'tip.amount', undefined),
                        redirectUrlOnSuccess: `${baseURL}&tr=success`,
                        redirectUrlOnError: `${baseURL}&tr=error`,
                        redirectUrlOnCancel: `${baseURL}&tr=cancel`,
						source: 'tabitPay',
						description: get(that.$storage.order, 'printData.variables.ORDER_NO'),
						orderId: resultOrder._id
                    }
                }).then(res => {
                    return that.expayService.showMeshulamDialog(res).then(args => {
                        paymentsAddedOnThisCall.push(parsedPayment);
                        return resultOrder;
                    });
                });

        }
        return this.executeNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall)
    }

    executeNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, retryCount?) {
        if (!retryCount) retryCount = 0;

        const unlock = releaseOrder ? '1' : '0';
        parsedPayment.source = resultOrder.source || 'online';

        if (parsedPayment.idCard === 'MISSING') {
            delete parsedPayment.idCard;
        }
        const logTip = get(parsedPayment, 'tip.amount', 0), logData: any = {
			data: {
				totalPayment: parsedPayment.amount,
				tipAmount: logTip,
				amountBeforeTip: parsedPayment.amount - logTip,
				account: parsedPayment.account,
				paymentType: parsedPayment.paymentType,
                paymentMode: this.$storage.paymentMode
			}
		}

        return new Promise((resolve, reject) => {
            const sURL = "/ros/online-shopper/orders/" + resultOrder._id + "/payments/v2?unlock=" + unlock;
            this.servicePost({
                url: sURL,
                data: parsedPayment,
                loadingCaption: 'PAYMENT_IN_PROGRESS'
            }, true)
            .then(addedPayment => {
                if (addedPayment.redirectUrl) {
                    console.warn('Need to implement external payment');
                } else {
                    this.logTemplate("paymentSuccess", logData, "Payment Success");
                    paymentsAddedOnThisCall.push(addedPayment);
                    resolve(resultOrder);
                }
            }).catch((srvErr) => {
                const err = srvErr.error || {}
                logData.data.error = JSON.stringify(err);

                if (srvErr.type == 'PAYMENT_VALIDATION_ERROR') {
                    reject(srvErr);
                    this.logTemplate("paymentFail", logData, "Payment Fail");
                    return;
                }

                if (err.code) err.code += "";
                if (err.code == '409144' || err.code == '409152') {
                    this.verifyClientPayment(parsedPayment, err.code == '409152').then(verificationCode => {
                        parsedPayment.verificationCode = verificationCode;
                        this.executeNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall).then(response => {
                            response["verificationCode"] = verificationCode;
                            resolve(response);
                        }).catch(err => {
                            this.logTemplate("paymentFail", logData, "Payment Fail");
                            reject(err)
                        });
                    }).catch(err => {
                        reject(err);
                    });
                    return;
                } else if (retryCount < 3 && (err.code == '409100' || err.code == '409036')) {
                    window.setTimeout(() => {
                        this.executeNotCashPayment(resultOrder, parsedPayment, releaseOrder, paymentsAddedOnThisCall, ++retryCount).then(response => {
                            resolve(response);
                        }).catch(err => {
                            this.logTemplate("paymentFail", logData, "Payment Fail");
                            reject(err)
                        });
                    }, 5000);
                    return;
                } else {
                    let errDesc;
                    switch (err.code) {
                        case "409100": case "409036": errDesc = "MESSAGES.PAYMENT_LOCKED"; break;
                        case "20106": errDesc = "MESSAGES.ORDER_NEWPAYMENT_NEEDED"; break;
                        case "409047": errDesc = "MESSAGES.ORDER_REQUIERES_IDCARD"; break;
                        case "409098": errDesc = "SERVER_MESSAGES.SHVA_OFFLINE"; break;
                        case "404001": errDesc = "SERVER_MESSAGES.ORDER_CLOSED_PAYED"; break;
                        case "409031":
                            let balance = get(err, 'data.providerResponse.budget');
                            if (balance) {
                                if (!isNaN(balance)) balance = this.currencyPipe.transform(balance);
                                errDesc = this.appService.translate("SERVER_MESSAGES.PAYMENT_QUOTA_EXCEEDED", { v: balance });
                            }
                    }

                    const appError = this.orderUtils.serverErrorToAppError(srvErr) ||
                        {
                            type: 'PAYMENT_VALIDATION_ERROR',
                            wasErrorDesc: errDesc != null,
                            message: this.appService.translate(errDesc || "MESSAGES.PAYMENT_ERROR"),
                            serverError: srvErr,
                            code: err.code
                        };

                    this.logTemplate("paymentFail", logData, "Payment Fail");
                    reject(appError);
                }

            });
        });
    }

    passOrder() {
        this.orderUtils.passOrder(this.$storage)
    }

    serviceGet(params, dontBlock?, errorInfo?) {
        return this.entityService.get(params.url, params.data, { 'ros-organization': this.$storage.organization }, null, null, 1);
    };

    servicePost(params, enableRecaptcha?: boolean) {
        const overrideUrl = enableRecaptcha ? this.appService.appConfig.tabitBridge + params.url : null;
        return this.entityService.post(params.url, params.data, { 'ros-organization': this.$storage.organization }, overrideUrl, null, 1, enableRecaptcha);
    };

    redirect(siteId, args?) {
        // If we add more variables:
        // please add them at dialogsService logic also
        const siteDetails = {
            id: siteId,
            oid: args?.oid,
            price: args?.price,
            token: this.storageService.getItem('token'),
            refreshToken: this.storageService.getItem('refreshToken'),
            loyaltyToken: this.storageService.getItem('loyaltyToken'),
            loyaltyRefreshToken: this.storageService.getItem('loyaltyRefreshToken'),
        }
        this.dialogsService.toggleActionFrame('pay', siteDetails)
    }

    logTemplate(actionType, data, message?) {
		let timeStamp = new Date();
		if (!message) {
			message = actionType;
			switch (actionType) {
				case "orderFetched":
					message = "Order Fetched";
					break;
				case "errorInterceptor":
					message = "Error Interceptor";
					break;
				case "maxCreditPayment":
					message = "Max credit payment attempt";
					break;
			}
		}
		const order = get(this.$storage, 'order', {});
		const base = {
			actionType,
			organization: get(this, 'urlParams.site', get(this.$storage, 'organization')),
			organizationName: get(this.$storage, 'site.name', null),
			orderId: get(this, 'urlParams.oid'),
			serverUrl: this.appService.appConfig.tabitAPI,
			userAgent: navigator.userAgent,
			platform: navigator.platform,
			route: document.location.href,
			version: this.appService.appConfig.version,
			deviceId: this.appService.clientId,
			localDate: timeStamp,
			timestamp: timeStamp,
			order: order._id,
		}

        const tabitPay = assignIn(base, data);

        this.appService.sendLogger({ message, tabitPay, module: 'tabitPay'});
	}


}
