import { debounce } from 'lodash-es';
import { Component, OnInit, Input, EventEmitter, Output, AfterViewInit, ViewChild } from '@angular/core';

import { AppService } from '../../app.service';
import { LocationService, LocationLabeled } from '../../_core/location.service';
import { BookService } from '../../_core/book.service';
import { SearchDetails } from '../../_core/OrganizationBucket';
import { GoogleMap, MapMarker } from '@angular/google-maps';
import { style } from '@angular/animations';
import { BehaviorSubject, Subscription } from 'rxjs';
import { GoogleMapsLoaderService } from '../../_core/google-maps-loader.service';
import { FilterPipe } from '../../_core/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SiteItemComponent } from '../site-item/site-item.component';
import { MatList } from '@angular/material/list';
import { NgIf, NgFor } from '@angular/common';
import { NgxDetectScrollDirective } from '../../_core/directives';

declare const $: any;

@Component({
    selector: 'site-list-map',
    templateUrl: './site-list-map.component.html',
    styleUrls: ['./site-list-map.component.scss'],
    standalone: true,
    imports: [NgxDetectScrollDirective, NgIf, MatList, NgFor, SiteItemComponent, MatProgressSpinner, GoogleMap, MapMarker, TranslateModule, FilterPipe]
})
export class SiteListMapComponent implements OnInit {
    @ViewChild(GoogleMap) googleMap: GoogleMap;
    @Input() type: string;
    @Input()
    set sites(value: any[]) {
        this._sites.next(value);
    }
    get sites(): any[] {
        return this._sites.getValue();
    }
    @Input() initialSearch: boolean = true;
    @Input() preventSearchMore: boolean = false;
    @Input() showTimeSlots: boolean = false;
    @Input() showBadges: boolean = false;
    @Input() service: SearchDetails['service'];
    @Input() extendedResultsButtonVisible: boolean = false;

    @Output() scrollReachedBottom = new EventEmitter<void>();
    @Output() siteClick = new EventEmitter<string>();
    @Output() extendedResultsClick = new EventEmitter<string>();

    private _sites = new BehaviorSubject<any[]>([]);
    private sitesSubscription: Subscription;

    initialZoomLevel: number = 11;

    darkMode: boolean = false;
    darkModeMapBackgroundColor: string = '#242F3E';
    lightModeMapBackgroundColor: string = '#EDEBE8';
    mapStyleDark: google.maps.MapTypeStyle[] = [
        {
            "elementType": "geometry",
            "stylers": [
                {
                    "color": this.darkModeMapBackgroundColor
                }
            ]
        },
        {
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#746855"
                }
            ]
        },
        {
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": this.darkModeMapBackgroundColor
                }
            ]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#d59563"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#d59563"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#263c3f"
                }
            ]
        },
        {
            "featureType": "poi.park",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#6b9a76"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#38414e"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#212a37"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#9ca5b3"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#746855"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#1f2835"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#f3d19c"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#2f3948"
                }
            ]
        },
        {
            "featureType": "transit.station",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#d59563"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#17263c"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#515c6d"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#17263c"
                }
            ]
        }
    ];

    siteIdInFocus: string = null;
    scrollingToSite: boolean = false;

    currentLocation: any = {};

    mapOptions: google.maps.MapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        gestureHandling: 'greedy',
        streetViewControl: true,
        scaleControl: true,
        panControl: true,
        mapTypeControl: true,
        scrollwheel: true,
        backgroundColor: this.darkMode ? this.darkModeMapBackgroundColor : this.lightModeMapBackgroundColor,
        styles: this.darkMode ? this.mapStyleDark : []
    };

    constructor(
        public appService: AppService,
        public locationService: LocationService,
        private bookService: BookService,
        public googleMapsLoaderService: GoogleMapsLoaderService
    ) { }

    ngOnInit() {
        this.locationService.location
        .subscribe((newLocation: LocationLabeled) => {
            this.currentLocation = newLocation;
        });
        this.sitesSubscription = this._sites.subscribe(() => {
            this.fitBounds();
        });
        // tabit-app: keep track of the order process referring page for exact back-button functionality
        this.appService.setOrderProcessReferringRoute();
    }

    handleScroll(event: any) {
        // We use this component's handle-scroll to load more sites ONLY on Desktops (because on Mobile - the scroll is handled by the web-container)
        if (!this.appService.isDesktop()) return;

        // When we scroll the list-map - we make sure the component is "in-view"
        //if (!this.scrollingToSite && event.direction === 'down') this.debounceScrollToWebContainer(event);
        //console.log('=== Site List Map - handleScroll', event.isReachingBottom, event.direction);
        if (event.isReachingBottom && event.direction === 'down') this.debounceScrollBottom(event);
    }

    debounceScrollToWebContainer = debounce((event) => {
        //document.querySelector('site-list-map').scrollIntoView({ behavior: 'smooth', block: 'start' });
        // The above scrollIntoView works nicely on Desktops, but doesn't work well at all on iOS... So had to switch to more "classic" way of handling the scroll behaviour here.
        //let scrollTopValue = this.appService.isDesktop() ? (600 - 90 - 9) : (600 - 80 - 9); // Header Height - Tabbar Height (19px font-size x 5rem on Desktop) - the green separator at the bottom of the header
        //$('app-web-container').animate({ scrollTop: scrollTopValue }, 400);
    }, 500);

    debounceScrollBottom = debounce((event) => {
        this.scrollReachedBottom.emit();
    }, 500);

    clickedMarker(site) {
        this.siteIdInFocus = site;

        // We use the scrollingToSite flag in order to prevent the scroll-into-view of the handleScroll (which positions the whole component at the top of the screen)
        this.scrollingToSite = true;
        setTimeout(() => {
            this.scrollingToSite = false;
        }, 600);

        // Scrolling the list to the relevant site
        document.querySelector(`#site-id-${site}`).scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    onMapClick() {
        this.siteIdInFocus = null;
    }

    getSiteIcon(site) {
        return this.siteIdInFocus === site._id ? this.appService.images.marker_dark : this.appService.images.marker_light;
    }

    siteItemClick(siteId) {
        // 2020-01-21: If we're not in the Booking Results page - we need to make sure that the time slots are cleared (otherwise the site details will show the actions with time slots)
        // This will be changed once the booking service is refactored.
        if (this.type != 'booking') this.bookService.orgTimeSlots[siteId] = null;
        this.siteClick.emit(siteId);
    }

    extendedResults() {
        this.extendedResultsClick.emit();
    }

    fitBounds() {
        if (this.sites?.length > 1 && this.googleMap?.googleMap) {
            const bounds = new google.maps.LatLngBounds();
            this.sites.forEach(site => {
                bounds.extend(site.location);
            });
            this.googleMap?.googleMap?.fitBounds(bounds);
        }
    }

    siteIcon(site): google.maps.Icon {
        return {
            url: this.getSiteIcon(site), 
            scaledSize: new google.maps.Size(32, 32)
        };
    }

    getMapIconObj(): google.maps.Icon {
        return {
            url: this.appService.images.marker_simple,
            scaledSize: new google.maps.Size(32, 32)
        };
    }
    
}
