import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LiveAnnouncer } from '@angular/cdk/a11y';

import { AppService } from '../../app.service';

import { Subscription } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatCheckbox } from '@angular/material/checkbox';
import { ConsentTextComponent } from '../../components/consent-text/consent-text.component';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-sign-up-form',
    templateUrl: './sign-up-form.component.html',
    styleUrls: ['./sign-up-form.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        MatFormField,
        MatLabel,
        MatInput,
        MatIcon,
        MatSuffix,
        NgFor,
        ConsentTextComponent,
        MatCheckbox,
        MatProgressSpinner,
        TranslateModule,
    ],
})

export class SignUpFormComponent implements OnInit, OnDestroy {

    formGroup = new UntypedFormGroup({
        firstName: new UntypedFormControl('', [Validators.required, Validators.pattern("^[- a-zA-Zא-ת]{2,}$"),Validators.maxLength(50)]), // For some reason \D doesn't work
        lastName: new UntypedFormControl('', [Validators.required, Validators.pattern("^[- a-zA-Zא-ת]{2,}$"),Validators.maxLength(50)]),  // For some reason \D doesn't work
        email: new UntypedFormControl('', [Validators.required, Validators.pattern("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"),Validators.maxLength(50)]),
        // custom validators for WL apps
        IsFilledJoinForm: new UntypedFormControl(false, []),
        IsConfirmSms: new UntypedFormControl(false, []),
        IsDisclaimerAccepted: new UntypedFormControl(false, Validators.requiredTrue),
        // custom fields for WL domain
        birthDate: new UntypedFormControl('', []),
        birthDateYear: new UntypedFormControl('', []),
        birthDateMonth: new UntypedFormControl('', []),
        birthDateDay: new UntypedFormControl('', []),
        anniversary: new UntypedFormControl(''),
        anniversaryYear: new UntypedFormControl('', []),
        anniversaryMonth: new UntypedFormControl('', []),
        anniversaryDay: new UntypedFormControl('', []),
    });
    birthDateErrorMessage: string = '';
    anniversaryErrorMessage: string = '';
    birthDateRequired: boolean = false;
    anniversaryRequired: boolean = false;

    @Input() disabled: boolean = false;
    @Input() dialogMode: boolean = false;

	@Output() onSubmit = new EventEmitter<{}>();
    @Output() onBack = new EventEmitter<{}>();

    private loadingCoreDataSubscription: Subscription;
    private domainSubscription: Subscription;

    public birthDateMin: Date;
    public birthDateMax: Date;

    public anniversaryMin: Date;
    public anniversaryMax: Date;

    public hide = true;
    public domain: any;
    public clubName: string;
    public dateType:string;

    public mailingConfirmRequired = false;
    public mailingConfirmEditable = false;
    public mailingConfirmShow = false;

    constructor(
        public appService: AppService,
        protected liveAnnouncer: LiveAnnouncer
    ) { }

    ngOnInit() {
        const currentYear = new Date().getFullYear();
        this.birthDateMin = new Date(currentYear - 100, 0, 1);
        this.birthDateMax = new Date(currentYear - 18, 0, 1);
        this.anniversaryMin = new Date(currentYear - 100, 0, 1);
        this.anniversaryMax = new Date();

        if (!this.appService.skin) {
            this.formGroup.get('IsDisclaimerAccepted').setValidators([Validators.requiredTrue]);
        } else {
            this.formGroup.get('IsDisclaimerAccepted').setValidators([]);
            this.clubName = this.appService.skin.toUpperCase();
        }

        this.formGroup.get('IsDisclaimerAccepted').updateValueAndValidity();

        this.loadingCoreDataSubscription = this.appService.loadingCoreData
        .subscribe(loadingCoreData => {
            if (!loadingCoreData) {
                this.domainSubscription = this.appService.domain.subscribe(domain => {
                    this.domain = domain;

                    // extra signup fields
                    if (this.domain?.defaults?.signupFormSettings?.extraFormFields) {
                        const extraFields = this.domain.defaults?.signupFormSettings?.extraFormFields;
                        // dynamically set required fields
                        extraFields.forEach(extraField => {
                            if (extraField.required && extraField.editable) this.formGroup.get(extraField.name).setValidators(Validators.required);
                            if (extraField.name == 'birthDate') this.birthDateRequired = extraField.required;
                            if (extraField.name == 'anniversary') this.anniversaryRequired = extraField.required;
                            if (extraField.name == 'IsConfirmSms') {
                                this.mailingConfirmShow = true;
                                this.mailingConfirmRequired = extraField.required;
                                this.mailingConfirmEditable = extraField.editable;
                            }
                        });
                    }
                });
            }
        });
    }

    _signUp() {
        this.formGroup.get('IsDisclaimerAccepted')?.markAsTouched();
        this.onDateChange('anniversary', true);
        this.onDateChange('birthDate', true);
        if (this.formGroup.valid) {
            this.onSubmit.emit(this.formGroup.value);
        } else {
            this.liveAnnouncer.announce(this.appService.translate('ACCESS.invalid-form'));
        }
    }

	_back() {
		this.onBack.emit({});
    }

    _onFocus($event) {
        // In certain mobile devices - the screen is too small - and the last (email) field is not seen on screen.
        // In some devices - the screen doesn't automatically scroll up when focusing on an element - so the user sometimes doesn't understand that he needs to close the keyboard in order to see the next field.
        // So, we force a scroll into view so that the currently in-focus field will be positioned in the middle of the screen, and so theh next field will become visible for the user.
        $event.target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    _onFocusDeleteContent($event, fieldName, mainDateField) {
        this._onFocus($event);
        this.formGroup.controls[fieldName].setValue('');
        this.formGroup.controls[fieldName].updateValueAndValidity();
        this.onDateChange(mainDateField, false);
    }

    isRequiredField(fieldName) {
        return this.formGroup.get(fieldName).errors && this.formGroup.get(fieldName).errors.required;
    }

    clearField(fieldName) {
        return this.formGroup.get(fieldName).reset();
    }

    ngOnDestroy() {
        this.loadingCoreDataSubscription.unsubscribe();
        this.domainSubscription.unsubscribe();
    }

    focusNext(element) {
        if (element.keyCode !== 13) return;
        const focusIndex = element.target?.attributes?.focusindex?.value ? Number(element.target?.attributes?.focusindex?.value) : 0 ;
        let focusedElement = document.querySelectorAll('[focusIndex="' + (focusIndex + 1) + '"]')[0];
        if (!focusedElement) focusedElement = document.querySelectorAll('[tabIndex="1"]')[0];
        document.querySelectorAll('input').forEach(input => {
            if (input == focusedElement) {
                input.focus();
            }
        });
    }

    focusNextNumberOfCharacter(element, character) {
        if (element.target.value.length == character) {
            const focusIndex = element.target?.attributes?.focusindex?.value ? Number(element.target?.attributes?.focusindex?.value) : 0 ;
            const focusedElement = document.querySelectorAll('[focusIndex="' + (focusIndex + 1) + '"]')[0];
            document.querySelectorAll('input').forEach(input => {
                if (input == focusedElement) {
                    if (!input.value) input.focus();
                }
            });
        }
        if (element.target.value.length > character) {
            const formControlName = element.target?.attributes?.formControlName?.value || '';
            if (formControlName) {
                this.formGroup.controls[formControlName].setValue(element.target.value.slice(0, character));
                this.formGroup.controls[formControlName].updateValueAndValidity();
            }
        }
        
    }

    onDateChange(fieldName, onSubmitForm) {
        this[fieldName + 'ErrorMessage'] = '';
        let year = this.getStringDate(fieldName + 'Year', null);
        let month = this.getStringDate(fieldName + 'Month', null);
        let day = this.getStringDate(fieldName + 'Day', null);
        if (year && month && day) {
            let fullDate = new Date(year + '-' + (month) + '-' + day);
            if (this.validateDates(fieldName, fullDate, year, month, day)) this.setDateSuccess(fieldName, fullDate);
            else this.setDateError(fieldName);
        }
        else if (onSubmitForm && (year || month || day)) this.setDateError(fieldName);
    }

    setDateSuccess(fieldName, fullDate) {
        this.formGroup.controls[fieldName + 'Year'].setErrors({ 'incorrect': null });
        this.formGroup.controls[fieldName + 'Month'].setErrors({ 'incorrect': null });
        this.formGroup.controls[fieldName + 'Day'].setErrors({ 'incorrect': null });
        this.formGroup.controls[fieldName + 'Year'].updateValueAndValidity();
        this.formGroup.controls[fieldName + 'Month'].updateValueAndValidity();
        this.formGroup.controls[fieldName + 'Day'].updateValueAndValidity();
        this.formGroup.controls[fieldName].setValue(fullDate);
        this.formGroup.controls[fieldName].updateValueAndValidity();
    }

    setDateError(fieldName) {
        this.formGroup.controls[fieldName + 'Year'].setErrors({ 'incorrect': true });
        this.formGroup.controls[fieldName + 'Month'].setErrors({ 'incorrect': true });
        this.formGroup.controls[fieldName + 'Day'].setErrors({ 'incorrect': true });
        this[fieldName + 'ErrorMessage'] = 'SIGN-UP-DATE-ERROR';
    }

    getStringDate(fieldName, numberToString) {
        let zeroString = '0';
        if (fieldName) {
            let dateField = this.formGroup.get(fieldName).value ? this.formGroup.get(fieldName).value.toString() : '';
            return dateField && dateField.length < 2 ? zeroString.concat(dateField) : dateField;
        }
        else {
            let dateField = numberToString.toString();
            return dateField && dateField.length < 2 ? zeroString.concat(dateField) : dateField;
        }
    }

    validateDates(fieldName, fullDate, year, month, day) {
        return (fullDate.getTime() && this.getStringDate(null, fullDate.getFullYear()) === year && this.getStringDate(null, fullDate.getMonth() + 1) === month && this.getStringDate(null, fullDate.getDate()) === day && fullDate > this[fieldName + 'Min'] && fullDate < this[fieldName + 'Max'])
    }

    isFormValidForMailingConfirmRequiredField() {
        const isValid = ((this.mailingConfirmRequired && !this.formGroup.value.IsConfirmSms) ||
        !this.formGroup.value.IsFilledJoinForm || !this.formGroup.valid);
        if (!isValid) {
            this.liveAnnouncer.announce(this.appService.translate('ACCESS.invalid-form'));
        }
        return isValid;
    }

}
